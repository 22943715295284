<div [style]="'text-align: center'">
  <div [innerHTML]="bs.params.noResultsText"></div>
  Call
  <a href="tel:{{bs.params.phoneNumber}}">
    {{bs.params.phoneNumber}}
  </a>
  or
  <a (click)="bs.setComponentState('buttonClicked', this.bs.ComponentState.FORM, $event)">
    start a new battery search.
  </a>
</div>
