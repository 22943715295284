import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { BatteryQuoterComponent } from "./battery-quoter.component"
import { ResultsComponent } from "./components/results/results.component"
import { NoResultsComponent } from "./components/no-results/no-results.component"
import { BatteryQuoterService } from "./battery-quoter.service"
import { FormsModule } from "@angular/forms"
import { FormComponent } from "./components/form/form.component"
import { NzButtonModule } from "ng-zorro-antd/button"
import { NzIconModule } from "ng-zorro-antd/icon"
import { NzSelectModule } from "ng-zorro-antd/select"
import { NzFormModule } from "ng-zorro-antd/form"

@NgModule({
  declarations: [
    ResultsComponent,
    NoResultsComponent,
    BatteryQuoterComponent,
    FormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
    NzIconModule,
    NzSelectModule,
    NzFormModule,
  ],
  providers: [
    BatteryQuoterService,
  ]
})
export class BatteryQuoterModule {
}
