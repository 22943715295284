import { Injectable } from "@angular/core"
import { FormArray, FormGroup } from "@angular/forms"
import { Text, TextOptions, TextService } from "@aaa-web/app/modules/multiblock/elements/text/text.service"
import { FormArrayOptions } from "@aaa-web/app/modules/multiblock/services/form.service"

export interface Membership {
  flags: Text[]
  subtitles: Text[]
  titles: Text[]
}

export interface MembershipOption {
  flags: TextOptions
  subtitles: TextOptions
  titles: TextOptions
}

export interface MembershipOptions {
  newForm: FormGroup
  options: MembershipOption[]
  blockOptions?: Record<string, unknown> | unknown
  combinedForm: boolean
  formArray: FormArrayOptions
  label: {
    plural: string,
    singular: string
  }
  placeholder: string
}

export interface MembershipStyles {
}

@Injectable({
  providedIn: "root"
})
export class MembershipService {
  counter: number = 1
  price: number[] = [ 0, 1, 2 ]

  constructor(
    private textService: TextService,
  ) {
    this.initializePrices()
  }

  newText = (): Text => {
    return this.textService.newText
  }

  get newField(): Membership {
    return {
      flags: [],
      subtitles: [this.newText()],
      titles: [this.newText()],
    }
  }

  newTextForm = (item: Text): FormGroup => {
    return this.textService.newForm(item)
  }

  newForm(field: Membership): FormGroup {
    return new FormGroup({
      flags: new FormArray(field.flags.map(item => this.newTextForm(item))),
      subtitles: new FormArray(field.subtitles.map(item => this.newTextForm(item))),
      titles: new FormArray(field.titles.map(item => this.newTextForm(item))),
    })
  }

  /**
   * join counter methods
   */

  decrementCounter(): void{
    this.counter = this.counter - 1
    this.calculatePrices()
  }

  incrementCounter(): void {
    this.counter = this.counter + 1
    this.calculatePrices()
  }

  ngModelChangeCounter(event: number): void {
    if (event > 9) {
      this.counter = 9
    }
    if (!event) {
      this.counter = 1
    }
    this.calculatePrices()
  }

  /**
   * price calculator methods
   */

  initializePrices(): void {
    this.price = [
      37.5,
      86,
      128
    ]
  }

  calculatePrices(): void {
    this.price = [
      37.5 - 40 + this.counter * 40,
      86 - 70 + this.counter * 70,
      128 - 100 + this.counter * 100,
    ]
  }

}
