<nz-card
  [nzTitle]="bannerTitle"
  class="blue-banner"
  [style]="'width: 300px'"
  [nzBodyStyle]="{'display': 'grid', 'grid-gap': '20px', 'grid-template-columns': '100%', 'padding-top': '0', 'padding-bottom': '0'}"
>
  <ng-template #bannerTitle>
    Your Appointment
  </ng-template>
  <div [style]="'display: flex'">
    <div [style]="'padding-right: 5px'">
      Subject:
    </div>
    <div>
      <span *ngFor="let topicId of formValues.selectedTopicIds; last as last">
        {{ topicText(topicId) }}{{ !last ? ',' : '' }}
      </span>
    </div>
  </div>
  <div
    *ngIf="regionText()"
    [style]="'display: flex'"
  >
    <div [style]="'padding-right: 5px'">
      {{ formValues.appMode === "TRAVEL" ? "Place:" : "" }}
      {{ formValues.appMode === "INSURANCE" ? "Policy:" : "" }}
    </div>
    <div>
      {{ regionText() }}
    </div>
  </div>
  <div [style]="'display: flex'">
    <div [style]="'padding-right: 5px'">
      Date:
    </div>
    <div>
      {{ selectedDateTime | date:'M/d/y' }}
    </div>
  </div>
  <div [style]="'display: flex'">
    <div [style]="'padding-right: 5px'">
      Time:
    </div>
    <div *ngIf="selectedDateTime && selectedDateTimeEnd">
      {{ selectedDateTime | date:'h:mm aaaaa\'m\'' }}
      -
      {{ selectedDateTimeEnd | date:'h:mm aaaaa\'m\'' }}
    </div>
  </div>
  <div
    *ngIf="meetingText()"
    [style]="'display: flex'"
  >
    <div [style]="'padding-right: 5px'">
      Types:
    </div>
    <div>
      {{ meetingText() }}
    </div>
  </div>

  <ng-content></ng-content>

</nz-card>
