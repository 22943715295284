import { Injectable } from "@angular/core"
import { FormOptionsBase, BlockBase } from "../../services/block.service"
import { BlockStateBase } from "@aaa-web/app/modules/multiblock/services/state.service"
import { FormControl, FormGroup } from "@angular/forms"
import {
  MarginOption, PaddingOption
} from "@aaa-web/app/modules/multiblock/services/style.service"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { PublishedOption } from "@aaa-web/app/modules/multiblock/blocks/settings.service"
import { ColorOption } from "@aaa-web/app/modules/multiblock/services/color.service"

export interface Block extends BlockBase {
  fields: {
    password: {
      value: string
    }
  }
}

export interface FormOptions extends FormOptionsBase {
  fields?: {
    password: {
      value: string
    }
  }
}

export interface BlockState extends BlockStateBase {
  formOptions?: FormOptions
}

@Injectable({
  providedIn: "root"
})
export class PasswordService {
  window: MetaWindow

  constructor(
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  get publishedOptions(): PublishedOption[] {
    return ["published", "unpublished"]
  }

  get newBlock(): Block {
    return {
      blockType: "password",
      id: "password",
      fields: {
        password: {
          value: ""
        }
      },
      options: {
        alignment: "center",
        marginBottom: "none",
        marginTop: "none",
        paddingBottom: "thin",
        paddingTop: "thin",
        theme: "white",
      },
      pathnames: [this.window.location.pathname],
      status: {
        created: Timestamp.now(),
        published: "published",
        revised: Timestamp.now()
      }
    }
  }

  newForm(block: Block): BlockState {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          password: new FormGroup({
            value: new FormControl(block.fields.password.value)
          }),
        }),
        pathnames: new FormControl(block.pathnames),
        status: new FormGroup({
          created: new FormControl(block.status.created),
          published: new FormControl(block.status.published),
          revised: new FormControl(block.status.revised)
        })
      })
    }
  }

  get newFormOptions(): FormOptions {
    return {
      fields: {
        password: {
          value: ""
        },
      },
      status: {
        published: this.publishedOptions
      }
    }
  }


}
