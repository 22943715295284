import { Injectable } from "@angular/core"
import { MetaWindow } from "../../../core/interfaces/window.interface"
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from "@angular/fire/firestore"
import { filter, map, switchMap } from "rxjs/operators"
import { Multiblock } from "./block.service"
import firebase from "firebase/app"
import { Block as ContainerBlock } from "@aaa-web/app/modules/multiblock/container-blocks/container/container.service"
import { BehaviorSubject, Observable, ObservableInput, of, pairwise, startWith } from "rxjs"
import { NavigationEnd, Router } from "@angular/router"
import { environment } from "@aaa-web/environments/environment"

@Injectable({
  providedIn: "root"
})
export class DataService {
  window: MetaWindow
  pathSegments: string[]
  draggable: boolean
  pageBlock: ContainerBlock
  pathnamesFilter$: BehaviorSubject<string[] | null>
  multiblockRef: AngularFirestoreCollection<Multiblock>
  blocks$: Observable<{ prev: Multiblock[], active: Multiblock[] }>
  blocks: Multiblock[] = []
  domain: string
  clubId: string

  constructor(
    private domWindow: Window,
    private afs: AngularFirestore,
    private router: Router,
  ) {
    this.window = domWindow as unknown as MetaWindow
    this.pathnamesFilter$ = new BehaviorSubject(null)
    this.blocks$ = this.pathnamesFilter$
      .pipe(
        switchMap(pathnames => {
          if (pathnames?.length) {
            return this.getContentCollection(pathnames)
          }
          return of(null)
        })
      )
      .pipe(
        startWith(null),
        pairwise(),
        map(([prev, active]) => ({ prev: prev, active: active }))
      )
    this.blocks$
      .subscribe()


    if (environment.firebaseConfig.projectId === "avagate-wss-1") {
      this.domain = "https://www.wssfiles.com"
    } else {
      this.domain = "https://storage.googleapis.com/" + environment.firebaseConfig.storageBucket
    }
    this.clubId = this.window.metaData.clubId


    this.pathSegments = this.window.location.pathname.replace(/^\//, "").split("/")

    // this.blocksBehaviorSubject = new BehaviorSubject<Multiblock[]>([])
    // this.blocks$ = this.blocksBehaviorSubject.asObservable()

    this.multiblockRef = this.afs
      .collection("wss-aaa-web")
      .doc(this.window.metaData.clubId)
      .collection("multiblock")

    if (environment.emulator || environment.ngServe || this.window.metaData.webEnv === "appTest") {
      /**
       * whenever a route change occurs, rebuild the contentCollection with page docs for the new route.
       */
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe((event: NavigationEnd) => {
          this.pathnamesFilter$.next([this.window.location.pathname])
          // console.log(event)
          // this.contentCollectionSubscription?.unsubscribe()
          // this.getContentCollection()
        })
    } else {
      this.pathnamesFilter$.next([this.window.location.pathname])
      // this.getContentCollection()
    }

    /**
     * Query for docs matching current page pathname,
     */
    // this.getContentCollection()

  }

  get createId(): string {
    return this.afs.createId()
  }

  getContentCollection(pathnames: string[]): ObservableInput<Multiblock[]> {
    return this.afs
      .collection("wss-aaa-web")
      .doc(this.window.metaData.clubId)
      .collection("multiblock", ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref
        query = query.where("pathnames", "array-contains-any", pathnames)
        return query
      })
      .snapshotChanges()
      .pipe(
        map((documents: (DocumentChangeAction<Multiblock>)[]) => {
          const blocks: Multiblock[] = []
          documents.forEach(document => {
            const data = document.payload.doc.data()
            const id = document.payload.doc.id
            delete data["parentBlockId"] // deprecated, removing here to catch any old data
            /**
             * data model changes:
             */

            /**
             * javascript map into javascripts array of javascript maps
             * Only Logiform multiblock is affected.
             */
            if (data.blockType === "logiform" && data["fields"].javascript) {
              data["fields"].javascripts = data["fields"].javascripts || []
              if (data["fields"].javascripts.length === 0) {
                data["fields"].javascripts.push(data["fields"].javascript)
                delete data["fields"].javascript
              }
            }

            /**
             * new javascript data fields
             * background and position data objects
             */
            if (data.blockType === "banner" && data["fields"].javascripts.length) {
              data["fields"].javascripts.forEach((javascript, index) => {
                data["fields"].javascripts[index].position = data["fields"].javascripts[index].position || {}
                data["fields"].javascripts[index].width = data["fields"].javascripts[index].width || {}
                data["fields"].javascripts[index].background = data["fields"].javascripts[index].background || {}
              })
            }

            /**
             * change elementType.file to elementType.element
             * Banner and OneColText and Title are affected.
             */
            blocks.push({ id, ...data })
            if (data.blockType === "banner" || data.blockType === "oneColText" || data.blockType === "title") {
              data["fields"].titles?.forEach((title, index) => {
                if (title.file) {
                  data["fields"].titles[index].element = title.file
                  delete data["fields"].titles[index].file
                }
              })
              data["fields"].subtitles?.forEach((subtitle, index) => {
                if (subtitle.file) {
                  data["fields"].subtitles[index].element = subtitle.file
                  delete data["fields"].subtitles[index].file
                }
              })
            }
            /**
             * change width.px: number to width: number
             * Banner is affected.
             */
            blocks.push({ id, ...data })
            if (data.blockType === "banner") {
              data["fields"].javascripts?.forEach((element, index) => {
                if (element.width.px) {
                  data["fields"].javascripts[index].width = element.width.px
                }
              })
            }
          })
          this.blocks = blocks
          return blocks
        })
      )
  }

}

/*
          let globalDocStatus: boolean
          const filteredBlocks: Multiblock[] = documents
            .reduce((accumulator, result) => {
              if (result) {
                const data = result.payload.doc.data()
                /!**
                 * Set the global flag, indicating that the query has completed successfully.
                 * Then remove the global doc from memory by returning without adding it to the accumulator.
                 *!/
                if (data["pathnames"][0] === "global") {
                  globalDocStatus = true
                  return accumulator
                }
                /!**
                 * Add the firestore docId to the doc.
                 *!/
                const id = result.payload.doc.id
                return [{ id, ...data }, ...accumulator]
              }
            }, [])
          this.global = globalDocStatus

          console.log(blocks)
          console.log(filteredBlocks)

          return filteredBlocks
*/
