import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core'

@Component({
  selector: 'aaa-infinite-scroll',
  templateUrl: './infinite-scroll.component.html'
})
export class InfiniteScrollComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() options = {};
  @Output() scrolled = new EventEmitter()
  @ViewChild('anchor') anchor: ElementRef<HTMLElement>;

  private observer: IntersectionObserver

  constructor(
    // private host: ElementRef,
  ) {
    //
  }

  // get element() {
  //   return this.host.nativeElement
  // }

  ngOnInit(): void {
    const options = {
      root: null,
      ...this.options
    }

    this.observer = new IntersectionObserver(([entry]) => {
      return entry.isIntersecting && this.scrolled.emit()
    }, options)

    if (this.anchor?.nativeElement) {
      this.observer.observe(this.anchor.nativeElement)
    }

  }

  ngAfterViewInit(): void {
    this.observer.observe(this.anchor.nativeElement)
  }

  ngOnDestroy(): void {
    this.observer.disconnect()
  }

}
