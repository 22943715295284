import { Component, Input, OnInit } from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { FormService } from "../../../services/form.service"
import { FormArray } from "@angular/forms"
import { environment } from "@aaa-web/environments/environment"
import { FormValues } from "@aaa/interface/agent-scheduler"

@Component({
  selector: "aaa-topic",
  templateUrl: "./topic.component.html"
})
export class TopicComponent implements OnInit{
  @Input() formId: string
  formValues: FormValues
  noTopicSelected: boolean = true

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {
  }

  ngOnInit(): void {
    this.formValues = this.formService.formValues[this.formId]
    this.noTopicSelected = !this.topics.controls.find(topic => topic.value)

    if (environment.ngServe) {
      // this.toggleTopic(0)
      // this.sms.sendEvent('NEXT')
    }
  }

  get topics(): FormArray {
    return this.formService.form[this.formId].get("topics") as FormArray
  }

  toggleTopic(index: number): void {
    this.topics.controls[index].setValue(!this.topics.controls[index].value)
    /**
     * The form values for topics are simple true or false.
     * We map from formService.topics to formService.selectedTopics with each topic selection change.
     */
    this.formValues.selectedTopicIds = []
    this.topics.controls.forEach((topicControl, topicControlIndex) => {
      if (topicControl.value) {
        this.formValues.selectedTopicIds.push(this.formValues.topics[topicControlIndex].id)
      }
    })
    this.noTopicSelected = !this.topics.controls.find(topic => topic.value)
  }

}
