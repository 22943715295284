import { Component, Input } from "@angular/core"
import { JwtAuthService } from "@aaa-web/app/core/services/jwt-auth.service"

@Component({
  selector: 'aaa-file-manager',
  templateUrl: './file-manager.component.html'
})
export class FileManagerComponent {
  @Input() directory: string
  showImages: boolean

  constructor(
    public jwtAuthService: JwtAuthService
  ) {
  }

}
