<div [style]="'text-align: center; margin: 10px; font-size: 30px; font-weight: 400'">
  What type of appointment?
</div>
<div [style]="'display: grid; justify-content: center'">
  <nz-form-label
    nzFor="meeting"
    [style]="'visibility: hidden'"
  >
    Select Meeting
  </nz-form-label>
  <nz-select
    nzId="meeting"
    [(ngModel)]="formValues.selectedMeetingId"
    [style]="'min-width: 250px'"
  >
    <nz-option
      *ngFor="let meeting of meetings.controls; let index = index"
      [nzValue]="formValues.meetings[index].id"
      [nzLabel]="formValues.meetings[index].text"
    ></nz-option>
  </nz-select>
</div>
<div [style]="'display: flex; justify-content: center; margin: 10px'">
  <button
    nz-button [nzType]="'primary'"
    (click)="sms.sendEvent('NEXT'); $event.preventDefault()"
  >
    {{ formValues.overrides.agentEmail ? "Next" : buttonLabel }}
  </button>
</div>
