import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AccountEmailSyncComponent } from "./account-email-sync.component"
import { HttpClientModule } from "@angular/common/http"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { NzButtonModule } from "ng-zorro-antd/button"

@NgModule({
  exports: [
    AccountEmailSyncComponent,
  ],
  declarations: [
    AccountEmailSyncComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
  ],
  providers: [
    HttpClientModule,
  ]
})
export class AccountEmailSyncModule {
}
