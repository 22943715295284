import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core"
import { RoleService } from "@aaa-web/app/core/services/role.service"
import { DataService } from "./services/data.service"
import { BuildService } from "./services/build.service"
import { count, Subscription, take } from "rxjs"
import { Block as ContainerBlock } from "@aaa-web/app/modules/multiblock/container-blocks/container/container.service"
import { StateService } from "@aaa-web/app/modules/multiblock/services/state.service"

@Component({
  selector: "aaa-multiblock",
  templateUrl: "./multiblock.component.html"
})
export class MultiblockComponent implements OnInit, OnDestroy {
  @ViewChild("blockContainer", { read: ViewContainerRef }) blockContainerRef
  @ViewChild("adminContainer", { read: ViewContainerRef }) adminContainerRef
  block: ContainerBlock
  adminModeSubscription: Subscription
  rolesSubscription: Subscription
  blocksSubscription: Subscription

  constructor(
    private roleService: RoleService,
    private dataService: DataService,
    private buildService: BuildService,
    private stateService: StateService,
  ) {
  }

  ngOnInit(): void {
    this.blocksSubscription = this.dataService.blocks$
      .pipe(
        take(1)
      )
      .subscribe(blocks => {
        const pageContainerBlock: ContainerBlock = blocks.active.find(block => block.blockType === "container" && (block as ContainerBlock).containerType === "page") as ContainerBlock
        if (pageContainerBlock) {
          this.block = pageContainerBlock
          this.dataService.pageBlock = pageContainerBlock
          this.buildPageContainerBlock()
        }
      })
    this.rolesSubscription = this.roleService.roles$
      .subscribe(roles => {
        if (roles.administrator) {
          this.loadAdminModules()
        }
      })
    this.adminModeSubscription = this.stateService.adminMode$
      .subscribe(() => {
        // this.buildPageContainerBlock()
      })
  }

  ngOnDestroy(): void {
    this.adminModeSubscription?.unsubscribe()
    this.blocksSubscription?.unsubscribe()
    this.rolesSubscription?.unsubscribe()
  }

  buildPageContainerBlock(): void {
    this.buildService.createBlockView(this.blockContainerRef, this.block)
  }

  loadAdminModules(): void {
    import("./multiblock-form.module").then(m => m.MultiblockFormModule)
    import("./admin/admin.module").then(m => m.AdminModule)
    this.buildService.loadAdminComponent(this.adminContainerRef)
  }

}
