<div class="tooltip-wrapper">
  <div>
    {{ index }}
  </div>
  <div
    *ngFor="let record of data; let recordIndex = index"
    [style]="'display: flex; padding: 12px;'"
  >
    <div>
      {{ recordIndex + 1 }}.
    </div>
    <pre>
        {{ record.data | json }}
      </pre>
  </div>
</div>
