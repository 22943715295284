<div class="tooltip-wrapper">
  <div>
    {{ index }}
  </div>
  <div *ngFor="let record of data; let recordIndex = index">
    <div class="records">
      <div>
        {{ recordIndex + 1 }}.
      </div>
      <pre>
          {{ record.data | json }}
        </pre>
    </div>
  </div>
</div>
