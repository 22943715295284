import { Injectable } from "@angular/core"
import { BlockBase } from "../../services/block.service"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp

export interface Block extends BlockBase {
  childBlockIds: string[]
  containerType: Option
}

export type Option = "page" | "cards"
export type Types = Record<Option, Type>

interface Type {
  file: string
    label: {
    plural: string,
    singular: string
  }
}


@Injectable({
  providedIn: "root"
})
export class ContainerService {
  window: MetaWindow

  constructor(
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  newContainerBlock(containerOption: Option): Block {
    // this.formService.addStateBlockId("container")
    return {
      id: containerOption + "-container",
      options: {
        alignment: "center",
        marginBottom: "none",
        marginTop: "none",
        paddingBottom: "none",
        paddingTop: "none",
        theme: "white",
      },
      blockType: "container",
      childBlockIds: [],
      containerType: containerOption,
      pathnames: [this.window.location.pathname],
      status: {
        created: Timestamp.now(),
        published: "published",
        revised: Timestamp.now()
      }
    }
  }

  get types(): Types {
    return {
      cards: {
        file: "",
        label: {
          plural: "Cards",
          singular: "Card"
        },
      },
      page: {
        file: "",
        label: {
          plural: "Pages",
          singular: "Page"
        },
      }
    }
  }

  get blockTypes(): Types {
    return {
      cards: {
        file: "",
        label: {
          plural: "cards",
          singular: "card"
        },
      },
      page: {
        file: "",
        label: {
          plural: "pages",
          singular: "page"
        },
      }
    }
  }


}
