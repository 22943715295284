import { Component, Input } from "@angular/core"

@Component({
  selector: "aaa-office-agents-custom-element",
  template: `
    <aaa-office-agents [officeId]="officeId" [params]="params"></aaa-office-agents>
  `
})
export class OfficeAgentsCustomElementComponent {
  @Input() officeId: string
  @Input() params: string
}
