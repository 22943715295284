export const environment = {
  production: true,
  emulator: false,
  ngServe: false,
  cloudFunctionsURL: "https://us-central1-avagate-wss-4.cloudfunctions.net",
  firebaseConfig: {
    apiKey: "AIzaSyAfk6WG2aHaf3vx8Ii3mqRt8e7HD0VKlwE",
    authDomain: "avagate-wss-4.firebaseapp.com",
    databaseURL: "https://avagate-wss-4.firebaseio.com",
    projectId: "avagate-wss-4",
    storageBucket: "avagate-wss-4.appspot.com",
    messagingSenderId: "646413560405",
    appId: "1:646413560405:web:8b0fa6409a1169c99142dc"
  },
  geocodingKey: 'AIzaSyAyg-zLzEOn3yK0YygKYqZUB73qhjJlcjI'
}
