import { Injectable } from "@angular/core"
import {
  FormOptionsBase, BlockBase, BlockOption, OptionsBase
} from "@aaa-web/app/modules/multiblock/services/block.service"
import { BlockStateBase } from "@aaa-web/app/modules/multiblock/services/state.service"
import { FormArray, FormControl, FormGroup } from "@angular/forms"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { PublishedOption } from "@aaa-web/app/modules/multiblock/blocks/settings.service"
import { Text, TextOptions, TextService } from "@aaa-web/app/modules/multiblock/elements/text/text.service"
import { Button, ButtonOptions, ButtonService } from "@aaa-web/app/modules/multiblock/elements/button/button.service"

export interface Block extends BlockBase {
  fields: {
    columns: Column[]
    comparisons: Comparison[]
    moreLabels: Text[]
    titles: Text[]
  }
}

export interface Column {
  ctas: Button[]
  titles: Text[]
}

export interface ComparisonColumn {
  details: Text[]
  eligibles: boolean[]
}

export interface Comparison {
  columns: ComparisonColumn[]
  descriptions: Text[]
  infos: Text[]
  titles: Text[]
}

export interface Options {
  titleOptions: TextOptions
  moreLabelOptions: TextOptions
  columnOptions: ColumnOptions
  comparisonColumnOptions: ComparisonColumnOptions
  columnCtaOptions: ButtonOptions
  columnTitleOptions: TextOptions
  comparisonOptions: ComparisonOptions
  comparisonDescriptionOptions: TextOptions
  comparisonInfoOptions: TextOptions
  comparisonTitleOptions: TextOptions
  comparisonDetailOptions: TextOptions
  comparisonEligibleOptions: BooleanOptions
}

interface ColumnOptions extends OptionsBase {
  newFormFunction: (item: Column) => FormGroup
  newFormItemFunction: () => Column
  options: []
}

interface ComparisonColumnOptions extends OptionsBase {
  newFormFunction: (item: ComparisonColumn) => FormGroup
  newFormItemFunction: () => ComparisonColumn
  options: []
}

interface ComparisonOptions extends OptionsBase {
  newFormFunction: (item: Comparison) => FormGroup
  newFormItemFunction: () => Comparison
  options: {
    descriptions: TextOptions
    infos: TextOptions
    titles: TextOptions
    columns: ComparisonColumnOptions
  }[]
}

interface BooleanOptions extends OptionsBase {
  newFormFunction: (item: boolean) => FormControl
  newFormItemFunction: () => boolean
  options: []
}


export interface FormOptions extends FormOptionsBase {
  fields?: {
    titles: TextOptions
    moreLabels: TextOptions
    comparisons: ComparisonOptions
    columns: ColumnOptions
  }
}

export interface BlockState extends BlockStateBase {
  formOptions?: FormOptions
}

export interface FormDisplayOptions {
  fields?: {
    titles?: boolean | "form"
    moreLabels?: boolean | "form"
    comparisons?: boolean | "form"
    columns?: boolean | "form"
  }
}

@Injectable({
  providedIn: "root"
})
export class ComparisonChartService {
  window: MetaWindow
  newBlockId: BlockOption = "comparisonChart"

  constructor(
    private buttonService: ButtonService,
    private textService: TextService,
    private domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  get options(): Options {
    return {
      columnCtaOptions: this.columnCtaOptions,
      columnOptions: this.columnOptions,
      columnTitleOptions: this.columnTitleOptions,
      comparisonColumnOptions: this.comparisonColumnOptions,
      comparisonDescriptionOptions: this.comparisonDescriptionOptions,
      comparisonDetailOptions: this.comparisonDetailOptions,
      comparisonEligibleOptions: this.comparisonEligibleOptions,
      comparisonInfoOptions: this.comparisonInfoOptions,
      comparisonOptions: this.comparisonOptions,
      comparisonTitleOptions: this.comparisonTitleOptions,
      moreLabelOptions: this.moreLabelOptions,
      titleOptions: this.titleOptions
    }
  }

  get titleOptions(): TextOptions {
    const options: TextOptions = {
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newText,
      options: [],
      combinedForm: true,
      label: {
        plural: "Titles",
        singular: "Title"
      },
      placeholder: "title",
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: ["h2"],
        value: true
      })
    }
    return options
  }

  get moreLabelOptions(): TextOptions {
    const options: TextOptions = {
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newText,
      options: [],
      combinedForm: true,
      label: {
        plural: "more Labels",
        singular: "more Label"
      },
      placeholder: "more label",
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get columnOptions(): ColumnOptions {
    const options: ColumnOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 3
      },
      options: [],
      label: {
        plural: "Columns",
        singular: "Column"
      },
      newFormFunction: this.newColumnForm,
      newFormItemFunction: this.newColumn,
      placeholder: "column"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push()
    }
    return options
  }

  get comparisonColumnOptions(): ComparisonColumnOptions {
    const options: ComparisonColumnOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 3
      },
      options: [],
      label: {
        plural: "Columns",
        singular: "Column"
      },
      newFormFunction: this.newComparisonColumnForm,
      newFormItemFunction: this.newComparisonColumn,
      placeholder: "column"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push()
    }
    return options
  }

  get columnCtaOptions(): ButtonOptions {
    const options: ButtonOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        plural: "CTAs",
        singular: "CTA"
      },
      newForm: this.newButtonForm(this.newButton),
      placeholder: "cta"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        height: { px: [] },
        width: { px: [] },
        color: ["darkblue"],
        target: ["same"],
        value: true
      })
    }
    return options
  }

  get columnTitleOptions(): TextOptions {
    const options: TextOptions = {
      combinedForm: true,
      formArray: {
        min: 1,
        max: 1
      },
      options: [],
      label: {
        plural: "Titles",
        singular: "Title"
      },
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newText,
      placeholder: "title text"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: ["h3"],
        value: true
      })
    }
    return options
  }

  get comparisonOptions(): ComparisonOptions {
    const options: ComparisonOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 99
      },
      options: [],
      label: {
        plural: "Comparisons",
        singular: "Comparison"
      },
      newFormFunction: this.newComparisonForm,
      newFormItemFunction: this.newComparison,
      placeholder: "comparison"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        columns: this.comparisonColumnOptions,
        descriptions: this.comparisonDescriptionOptions,
        infos: this.comparisonInfoOptions,
        titles: this.comparisonTitleOptions,
      })
    }
    return options
  }

  get comparisonDescriptionOptions(): TextOptions {
    const options: TextOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        plural: "Descriptions",
        singular: "Description"
      },
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newText,
      placeholder: "description"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get comparisonInfoOptions(): TextOptions {
    const options: TextOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        plural: "Information Tooltips",
        singular: "Information Tooltip"
      },
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newText,
      placeholder: "information text"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get comparisonTitleOptions(): TextOptions {
    const options: TextOptions = {
      combinedForm: true,
      formArray: {
        min: 1,
        max: 1
      },
      options: [],
      label: {
        plural: "Titles",
        singular: "Title"
      },
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newText,
      placeholder: "title text"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get comparisonDetailOptions(): TextOptions {
    const options: TextOptions = {
      combinedForm: true,
      formArray: {
        min: 1,
        max: 1
      },
      options: [],
      label: {
        plural: "Details",
        singular: "Detail"
      },
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newText,
      placeholder: "title text"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get comparisonEligibleOptions(): BooleanOptions {
    const options: BooleanOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        plural: "Eligibles",
        singular: "Eligible"
      },
      newFormFunction: this.newBooleanForm,
      newFormItemFunction: this.newBoolean,
      placeholder: ""
    }
    for (let i = 0; i < options.formArray.max; i++) {
      //   options.options.push({})
    }
    return options
  }

  get formDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        moreLabels: "form",
        comparisons: "form",
        columns: "form",
      },
    }
  }

  get publishedOptions(): PublishedOption[] {
    return ["published", "unpublished"]
  }

  newText = (): Text => {
    return this.textService.newText
  }

  get newButton(): Button {
    return this.buttonService.newButton
  }

  newColumn = (): Column => {
    return {
      ctas: [],
      titles: []
    }
  }

  newComparison = (): Comparison => {
    return {
      descriptions: [],
      infos: [],
      titles: [],
      columns: []
    }
  }

  newComparisonColumn = (): ComparisonColumn => {
    return {
      details: [],
      eligibles: []
    }
  }

  newBoolean = (): boolean => {
    return true
}

  get newBlock(): Block {
    return {
      blockType: this.newBlockId,
      id: this.newBlockId,
      fields: {
        titles: [this.newText()],
        moreLabels: [],
        comparisons: [],
        columns: [],
      },
      options: {
        alignment: "left",
        marginBottom: "none",
        marginTop: "none",
        paddingBottom: "thin",
        paddingTop: "thin",
        theme: "black",
      },
      pathnames: [this.window.location.pathname],
      status: {
        created: Timestamp.now(),
        published: "published",
        revised: Timestamp.now()
      }
    }
  }

  newTextForm = (item: Text): FormGroup => {
    return new FormGroup({
      element: new FormControl(item.element),
      value: new FormControl(item.value),
    })
  }

  newButtonForm(item: Button): FormGroup {
    return this.buttonService.newForm(item)
  }

  newBooleanForm = (item: boolean): FormControl => {
    return new FormControl(item)
  }

  newColumnForm = (item: Column): FormGroup => {
    return new FormGroup({
      ctas: new FormArray(item.ctas.map(item => this.newButtonForm(item))),
      titles: new FormArray(item.titles.map(item => this.newTextForm(item))),
    })
  }

  newComparisonColumnForm = (item: ComparisonColumn): FormGroup => {
    return new FormGroup({
      details: new FormArray(item.details.map(item => this.newTextForm(item))),
      eligibles: new FormArray(item.eligibles.map(item => this.newBooleanForm(item))),
    })
  }

  newComparisonForm = (item: Comparison): FormGroup => {
    return new FormGroup({
      columns: new FormArray(item.columns.map(item => this.newComparisonColumnForm(item))),
      descriptions: new FormArray(item.descriptions.map(item => this.newTextForm(item))),
      infos: new FormArray(item.infos.map(item => this.newTextForm(item))),
      titles: new FormArray(item.titles.map(item => this.newTextForm(item))),
    })
  }

  newForm(block: Block): BlockState {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          columns: new FormArray(block.fields.columns.map(item => this.newColumnForm(item))),
          comparisons: new FormArray(block.fields.comparisons.map(item => this.newComparisonForm(item))),
          moreLabels: new FormArray(block.fields.moreLabels.map(item => this.newTextForm(item))),
          titles: new FormArray(block.fields.titles.map(item => this.newTextForm(item))),
        }),
        pathnames: new FormControl(block.pathnames),
        status: new FormGroup({
          created: new FormControl(block.status.created),
          published: new FormControl(block.status.published),
          revised: new FormControl(block.status.revised)
        })
      })
    }
  }

  get newFormOptions(): FormOptions {
    return {
      fields: {
        titles: this.titleOptions,
        moreLabels: this.moreLabelOptions,
        comparisons: this.comparisonOptions,
        columns: this.columnOptions,
      },
      status: {
        published: this.publishedOptions,
      }
    }
  }

}
