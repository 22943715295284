import { Component, OnDestroy, OnInit } from "@angular/core"
import { Observable, Subscription } from "rxjs"
import { Message, SimpleLoggerGroupDoc, SimpleLoggerMessages, SimpleLoggerService } from "../simple-logger.service"
import { map } from "rxjs/operators"
import { DocumentSnapshot } from "@angular/fire/firestore"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

@Component({
  selector: "aaa-v3-batch",
  templateUrl: "./v3-batch.component.html"
})
export class V3BatchComponent implements OnInit, OnDestroy {
  docs$: Observable<SimpleLoggerGroupDoc[]>
  docsSubscription: Subscription
  messages: SimpleLoggerMessages = {}
  messagesDocs: SimpleLoggerGroupDoc[]
  messagesArray: Message[] = []
  items = 10
  itemsIncrement = 10
  window: MetaWindow
  showMessage: { [key: string]: boolean } = {}

  constructor(
    private simpleLoggerService: SimpleLoggerService,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  ngOnInit(): void {
    this.docs$ = this.simpleLoggerService.v3BatchCollectionRef
      .snapshotChanges()
      .pipe(
        map((messagesDocs: any[]) => {
          return messagesDocs.map(result => {
            if (result) {
              const data = result["payload"].doc.data()
              const id = result["payload"].doc.id
              return { id, ...data }
            }
          })
        })
      )

    this.docsSubscription = this.docs$
      .subscribe(messagesDocs => {
        /**
         * Set this.messagesDocs so we can delete them in the reset() function.
         */
        this.messagesDocs = messagesDocs

        /**
         * It is possible to have the same messageId across multiple docs.
         * This happens when the message is too large for a single doc and gets split across several docs.
         * We also want to convert the messages objects into an array for the template.
         */
        const processedMessagesDocs = this.simpleLoggerService.processMessagesDocs(messagesDocs, this.messages, this.messagesArray)
        this.messagesArray = processedMessagesDocs.messagesArray

        /**
         * We are done using this.messages for comparison.
         * Now we replace it with new messages for comparison on next .subscribe emit
         */
        this.messages = processedMessagesDocs.messages

        /**
         * set the minimum number of items to display while limiting to a maximum
         */
        if (this.items < 10) {
          this.items = 10
        }
        if (this.items > this.messagesArray.length) {
          this.items = this.messagesArray.length
        }
      })
  }

  reset(): void {
    if (this.messagesArray.length) {
      if (confirm("Are you sure you want to delete all the data on this page?")) {
        this.messagesDocs.forEach(messagesDoc => {
          const docRef = this.simpleLoggerService.v3BatchCollectionRef.doc(messagesDoc.id)
          docRef
            .delete()
            .catch(() => {
              //
            })
        })
      }
    } else {
      alert("This page has no data to delete, it is already reset.")
    }
  }


  ngOnDestroy(): void {
    this.docsSubscription?.unsubscribe()
  }

  onScroll(): void {
    // console.log('onScroll()')
    this.items = this.items + this.itemsIncrement
    if (this.items > this.messagesArray.length) {
      this.items = this.messagesArray.length
    }
  }

}
