import { Component, Input, OnInit } from "@angular/core"
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http"
import { AzureConnectorService } from "../../services/azure-connector.service"
import { JwtAuthService } from "@aaa-web/app/core/services/jwt-auth.service"
import { AngularFirestore } from "@angular/fire/firestore"
import { differenceInCalendarDays } from "date-fns"

import { environment } from "@aaa-web/environments/environment"
import { FormService } from "@aaa-web/app/modules/agent-scheduler/services/form.service"
import { FormValues } from "@aaa/interface/agent-scheduler"

@Component({
  selector: "aaa-tools",
  templateUrl: "./tools.component.html"
})
export class ToolsComponent implements OnInit {
  @Input() formId: string
  formValues: FormValues
  today = new Date()
  date: string
  showTools: boolean = false
  showAccordion: boolean = false
  tools = {
    info: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      projectId: environment.firebaseConfig?.projectId,
      endpointUrl: environment.cloudFunctionsURL,
    },
    reports: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      today: new Date(),
      range: { start: undefined, end: undefined },
    },
    connection: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      loading: false,
      results: undefined,
      appId: undefined
    },
    hours: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      loading: false,
      results: undefined,
    },
    fnConfig: {
      roles: ["administrator", "webmaster"],
      enabled: false,
      setup: this.setupFnConfig.bind(this),
      doc: undefined
    }
  }

  constructor(
    private http: HttpClient,
    public acs: AzureConnectorService,
    public jwt: JwtAuthService,
    public afs: AngularFirestore,
    private formService: FormService,
  ) {
  }

  ngOnInit(): void {
    this.formValues = this.formService.formValues[this.formId]
    const toolsSections = [this.tools.info, this.tools.reports, this.tools.connection, this.tools.hours, this.tools.fnConfig]
    this.jwt.roles$.subscribe(userRoles => {
      for (const section of toolsSections) {
        section.enabled = section.roles.some(sr => userRoles?.some(ur => ur.toLowerCase() === sr.toLowerCase()))
        if (section.enabled && ("setup" in section)) {
          section.setup()
        }
        this.showTools = this.showTools || section.enabled
      }
    })
  }

  async connectionAgentsRefresh(): Promise<void> {
    if (this.tools.connection.loading) return
    this.tools.connection.loading = true
    this.tools.connection.results = "refreshing agent availability. this can take a while for larger clubs..."
    this.tools.connection.appId = "getting azure app ID..."
    const availabilityRefresh = await this.acs.availabilityRefresh(
      this.formValues.meta.clubId,
      "tools component",
      this.formValues.meta.tenantId,
      this.formValues.agents.map(agent => agent.email),
      true,
    )
    this.tools.connection.results = availabilityRefresh.map(document => {
      return {
        email: document.agentEmail,
        status: document.status,
        message: document.message,
      }
    })
    this.tools.connection.appId = await this.acs.statusAzureApp(
      this.formValues.meta.clubId
    )
    this.tools.connection.loading = false
  }

  async hoursGetAgentHours(): Promise<void> {
    if (this.tools.hours.loading) return
    this.tools.hours.loading = true
    this.tools.hours.results = "getting agent work hours..."
    const workHoursRef = this.afs.firestore
      .collection("wss-aaa-web")
      .doc(this.formValues.meta.clubId)
      .collection("apps")
      .doc("agent-scheduler")
      .collection("data")
      .doc("gcf")
      .collection("work-hours")

    const workHoursDocs = await workHoursRef.get()
    if (!workHoursDocs.empty) {
      this.tools.hours.results = []
      workHoursDocs.forEach(doc => {
        const data = doc.data()
        if (doc.id === "000index") return
        this.tools.hours.results.push({
          email: data.agentEmail,
          source: data.source,
          timezone: data.timezone,
          hours: data.hours
        })
      })
    } else {
      this.tools.hours.results = "failed"
    }
    this.tools.hours.loading = false
  }

  async reportsDownload(): Promise<void> {
    if (!this.tools.reports.range.start || !this.tools.reports.range.start) return

    const url = environment.cloudFunctionsURL + "/agentSchedulerReport?fmt=csv&type=travel&clubId=" + this.formValues.meta.clubId +
      "&start=" + this.tools.reports.range.start + "&end=" + this.tools.reports.range.end
    const headers = new HttpHeaders({ "Authorization": "Bearer " + this.jwt.authToken })
    const resp: HttpResponse<Blob> = await this.http.get<Blob>(url, {
      headers: headers,
      observe: "response",
      responseType: "blob" as "json"
    }).toPromise()
    const filename = resp.headers.get("content-disposition").split("\"")[1]

    this.downloadFile(resp.body, filename, "application/ms-excel")
  }

  downloadFile(data: Blob, filename: string, type: string): void {
    const blob = new Blob([data], { type: type })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", filename)
    document.body.appendChild(link)
    link.click() //unreal
    window.URL.revokeObjectURL(url)
  }

  datePickerRange(event: Date[]): void {
    const dateRange = [null, null]
    if (event.length === 2) {
      dateRange[0] = event[0].toISOString()?.split("T")[0]
      dateRange[1] = event[1].toISOString()?.split("T")[0]
    }
    this.tools.reports.range["start"] = dateRange[0]
    this.tools.reports.range["end"] = dateRange[1]
  }

  async setupFnConfig(): Promise<void> {

    const clubId = this.formValues.meta.clubId

    this.afs
      .collection("wss-aaa-web")
      .doc(clubId)
      .collection("apps")
      .doc("agent-scheduler")
      .valueChanges()
      .subscribe(item => {
        this.tools.fnConfig.doc = JSON.stringify(item, null, 2)
        // console.log(item);
      })
  }

  datePickerDisabledDates = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.today) > 0
  }

}
