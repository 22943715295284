import {
  Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, HostListener, AfterViewInit, ChangeDetectorRef
} from "@angular/core"
import { Subscription } from "rxjs"
import { AgentTimeSlot, AgentAvailability, FormValues, Office, AgentType } from "@aaa/interface/agent-scheduler"
import { AzureConnectorService } from "../../../services/azure-connector.service"
import { FirestoreConnectorService } from "../../../services/firestore-connector.service"
import { StateMachineService } from "../../../services/state-machine.service"
import { environment } from "@aaa-web/environments/environment"
import { differenceInCalendarDays } from "date-fns"
import { FormService } from "../../../services/form.service"
import { FormControl } from "@angular/forms"

@Component({
  selector: "aaa-agent",
  templateUrl: "./agent.component.html"
})
export class AgentComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() formId: string
  formValues: FormValues
  @ViewChild("container") container: ElementRef
  containerWidth: number
  gridTemplateColumns: { [key: string]: string } = {}
  appointmentCardStyles: { [key: string]: string } = {}
  dayTimeSlots: AgentTimeSlot[] = []
  availabilitySubscription: Subscription
  timeSlots: AgentTimeSlot[] = []
  agentOffice: Office
  agentTypes: AgentType[]

  minDate: Date
  maxDate: Date

  constructor(
    public sms: StateMachineService,
    public fcs: FirestoreConnectorService,
    public acs: AzureConnectorService,
    public formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.formValues = this.formService.formValues[this.formId]
    this.agentOffice = this.formValues.offices.find(office => office.id === this.agent.value.officeIds[0])

    const agentTypes = {}
    this.agent.value.typeIds.forEach(typeId => {
      agentTypes[typeId] = this.formValues.types.find(type => type.id === typeId)
    })
    this.agentTypes = Object.values(agentTypes)

    // this.agentTypes = this.formValues.types.find(type => !!this.agent.value.typeIds.find(typeId => typeId === type.id))
    this.date.reset()
    this.timeSlot.reset()
    this.minDate = new Date()
    this.minDate.setHours(this.minDate.getHours() + 2)
    // console.log(this.minDate);
    this.agentTimezone.setValue("")

    this.availabilitySubscription = this.fcs.availability$.subscribe((agents) => {
        const agentAvailability: AgentAvailability = agents
          .filter(agent => agent.agentEmail && (agent.agentEmail.toLowerCase() === this.agent.value.email.toLowerCase()))[0]
        //console.log('agentAvailability: ' + JSON.stringify(agentAvailability));
        this.timeSlots = agentAvailability?.timeSlots
        this.agentTimezone.setValue(agentAvailability?.timezone)

        if (this.formService[this.formId]?.selectedDate && this.timeSlots) {
          // const timeSlots = this.getAvailabilityForDate(this.timeSlots, this.formService.formValues[this.formId].selectedDate)
          // this.dayTimeSlots = timeSlots.sort((a, b) => a.time.hour - b.time.hour)
          this.maxDate = this.timeSlots.map(t => this.acs.agentTimeSlotToDate(t)).sort((a, b) => b.getTime() - a.getTime())[0]
        } else {
          // this.dayTimeSlots = []
          this.maxDate = undefined
        }
      }
    )

    if (environment.ngServe) {
    }
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  ngOnDestroy(): void {
    this.availabilitySubscription?.unsubscribe()
  }

  get agent(): FormControl {
    return this.formService.form[this.formId].get("agent") as FormControl
  }

  get agentTimezone(): FormControl {
    return this.formService.form[this.formId].get("agentTimezone") as FormControl
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container.nativeElement.offsetWidth

    this.gridTemplateColumns = { "grid-template-columns": "auto" }
    this.appointmentCardStyles = {}
    if (this.containerWidth >= 700) {
      this.gridTemplateColumns = { "grid-template-columns": "210px auto" }
      this.appointmentCardStyles = { "grid-column": "1 / 3" }
    }
    if (this.containerWidth >= 1080) {
      this.gridTemplateColumns = { "grid-template-columns": "210px auto 300px" }
      this.appointmentCardStyles = {}
    }
    this.changeDetectorRef.detectChanges()
  }

  dateChanged(datePickerDate: Date): void {
    this.timeSlot.reset()
    if (datePickerDate) {
      const today = new Date()
      const selectedDateTime = new Date(
        datePickerDate.getFullYear(),
        datePickerDate.getMonth(),
        datePickerDate.getDate(),
        today.getHours(),
        today.getMinutes(),
        today.getSeconds(),
        0
      )
      const timeSlots = this.getAvailabilityForDate(this.timeSlots, selectedDateTime)
      this.dayTimeSlots = timeSlots.sort((a, b) => a.time.hour - b.time.hour)
    } else {
      this.dayTimeSlots = []
    }
  }

  get date(): FormControl {
    return this.formService.form[this.formId].get("date") as FormControl
  }

  get timeSlot(): FormControl {
    return this.formService.form[this.formId].get("timeSlot") as FormControl
  }

  bookWith(): void {
    if (this.timeSlot.value) {
      this.sms.sendEvent("NEXT")
      // console.log("emitting bookingDetails")

      /*
            const details: AppointmentDetails = {
              timeSlot: this.formValues.selectedTimeSlot,
              timezone: this.agentTimezone,
              duration: 59, //one hour - static for now
              agentType: this.formValues.appMode,
              agentInfo: {
                firstName: this.agent.value.fName,
                lastName: this.agent.value.lName,
                phone: this.agent.value.phone,
              }
            }

            this.bookingDetails.emit(details)
      */
    }
  }

  private getAvailabilityForDate(timeSlots: AgentTimeSlot[], pickerDate: Date): AgentTimeSlot[] {
    const now = new Date()
    now.setHours(now.getHours() + 2)
    return timeSlots.filter(timeSlot => {
      return (
        timeSlot.date.year === pickerDate.getFullYear() &&
        timeSlot.date.month - 1 === pickerDate.getMonth() &&
        timeSlot.date.day === pickerDate.getDate() &&
        this.acs.agentTimeSlotToDate(timeSlot) > now
      )
    })
  }

  // agentDateFilter = (calDay: Date | null): boolean => {
  //   const pickerDate = calDay || new Date()
  //   return this.anyAvailabilityForDate(this.timeSlots, pickerDate)
  // }

  datePickerDisabledDates = (pickerDate: Date): boolean => {
    return !this.timeSlots?.some(timeSlot => {
      return (
        timeSlot.date.year === pickerDate.getFullYear() &&
        timeSlot.date.month - 1 === pickerDate.getMonth() &&
        timeSlot.date.day === pickerDate.getDate() &&
        differenceInCalendarDays(pickerDate, new Date()) >= 0
      )
    })
  }

}
