import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { StringReplacePipe } from "@aaa-web/app/core/pipes/string-replace.pipe";

@NgModule({
  declarations: [
    StringReplacePipe,
  ],
  exports: [
    StringReplacePipe,
  ],
  imports: [
    CommonModule,
  ]
})
export class CoreModule {
  constructor() {
  }

}
