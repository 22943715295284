import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinRenewComponent } from './join-renew.component';
import { CybersourceModule } from "./cybersource/cybersource.module"

@NgModule({
  declarations: [
    JoinRenewComponent,
  ],
  exports: [
    JoinRenewComponent
  ],
  imports: [
    CommonModule,
    CybersourceModule,
  ],
  providers: [
    CybersourceModule,
  ]
})
export class JoinRenewModule { }
