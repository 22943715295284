import { Component, ComponentFactoryResolver, ComponentRef, Input, ViewChildren, ViewContainerRef } from "@angular/core"
import { animate, state, style, transition, trigger } from "@angular/animations"

@Component({
  selector: "aaa-accordion",
  templateUrl: "./accordion.component.html",
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({
          left: "1000px"
        }),
        animate("1s ease-in-out",
          style({
            left: 0
          })
        )
      ]),
      transition(":leave", [
        animate("1s ease-in-out",
          style({
            left: "1000px"
          })
        )
      ])
    ]),
    trigger("showHideRow", [
        state("showRow",
          style({
            height: "*",
            opacity: 1,
            left: 0
          })
        ),
        state("hideRow",
          style({
            height: 0,
            opacity: 0,
            left: "1000px"
          })
        ),
        transition("showRow => hideRow", [
          animate("1s ease-in-out")
        ]),
        transition("hideRow => showRow", [
          animate("1s ease-in-out")
        ])
      ]
    ),
    trigger("inOutRotate", [
        state("opened",
          style({
            transform: "rotate(90deg)",
          })
        ),
        state("closed",
          style({
            transform: "rotate(0)",
          })
        ),
        transition("opened => closed", [
          animate("1s ease-in-out")
        ]),
        transition("closed => opened", [
          animate("1s ease-in-out")
        ]),
      ]
    )
  ]
})
export class AccordionComponent {
  @Input() title: string
  @Input() rows: any[] = []
  @Input() component: any
  @ViewChildren("accordionRowContainer", { read: ViewContainerRef }) accordionRowContainer
  @ViewChildren("accordionRow") accordionRow
  showRows: boolean
  showRow: {
    [key: number]: boolean
  } = {}

  constructor(
    private cfr: ComponentFactoryResolver,
  ) {
  }

  toggleComponent(row, rowIndex: number): void {
    /*
        if (this.showRow[rowIndex]) {
          // this.container.get(rowIndex).clear()
        } else {
          console.log(this.container.get(rowIndex))
          this.container.get(rowIndex).createEmbeddedView(this.accordionRow.get(rowIndex))
          this.simpleLoggerService.loadComponent(this.container.get(rowIndex), this.type, row).catch((error) => {
            console.log(error)
          })
        }
    */
    this.showRow[rowIndex] = !this.showRow[rowIndex]
  }

  loadComponents(rows): void {
    this.accordionRowContainer.forEach((container: ViewContainerRef, index: number) => {
      container.createEmbeddedView(this.accordionRow.get(index))
      this.loadComponent(container, rows[index], index).catch(() => {
        //
      })
    })
  }

  async loadComponent(viewContainerRef: ViewContainerRef, row: Record<string, unknown>, index: number): Promise<void> {
    /**
     * TODO: don't load Components if they are already loaded.
     * Don't create duplicates.
     */
    viewContainerRef.clear()
    /**
     * Create the new component and pass data into it.
     * Interesting note: with MatDatepickerModule removed from app.module.ts imports, this createComponent() fails silently.
     */
    const componentRef: ComponentRef<any> = viewContainerRef.createComponent(this.cfr.resolveComponentFactory(this.component))
    componentRef.instance["index"] = index
    componentRef.instance["row"] = row
  }

}
