import { Injectable } from "@angular/core"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { AlignmentOption, MarginOption, PaddingOption, ThemeOption } from "./style.service"
import { Block as TitleBlock, TitleService } from "../blocks/title/title.service"
import {
  Block as ContainerBlock, ContainerService, Option as ContainerOption, Types as ContainerTypes
} from "../container-blocks/container/container.service"
import { Block as OneColTextBlock, OneColTextService } from "../blocks/one-col-text/one-col-text.service"
import {
  Block as UnrestrictedBlock, UnrestrictedService
} from "@aaa-web/app/modules/multiblock/blocks/unrestricted/unrestricted.service"
import {
  Block as PasswordBlock, PasswordService
} from "@aaa-web/app/modules/multiblock/blocks/password/password.service"
import { PublishedOption } from "@aaa-web/app/modules/multiblock/blocks/settings.service"
import {
  Block as LogiformBlock, LogiformService
} from "@aaa-web/app/modules/multiblock/blocks/logiform/logiform.service"
import { Block as HTMLBlock, HtmlService } from "@aaa-web/app/modules/multiblock/blocks/html/html.service"
import { BlockStateBase as BlockState } from "@aaa-web/app/modules/multiblock/services/state.service"
import {
  Block as OfficeAgentsBlock, OfficeAgentsService
} from "@aaa-web/app/modules/multiblock/blocks/office-agents/office-agents.service"
import { BannerService, Block as BannerBlock } from "@aaa-web/app/modules/multiblock/blocks/banner/banner.service"
import { Block as JoinBlock, JoinService } from "@aaa-web/app/modules/multiblock/blocks/join/join.service"
import { Block as ComparisonChartBlock, ComparisonChartService } from "@aaa-web/app/modules/multiblock/blocks/comparison-chart/comparison-chart.service"
import { FormGroup } from "@angular/forms"
import { FormArrayOptions } from "@aaa-web/app/modules/multiblock/services/form.service"

export type BlockOption =
  "banner"
  | "card"
  | "cta"
  | "comparisonChart"
  | "container"
  | "html"
  | "feature"
  | "logiform"
  | "officeAgents"
  | "oneColText"
  | "password"
  | "title"
  | "unrestricted"
  | "join"

export type BlockTypes = Record<BlockOption, BlockType>

export interface BlockType {
  component: {
    edit: string
    view: string
  }
  label: {
    plural: string,
    singular: string
  }
}

export type Multiblock =
  | BannerBlock
  | ComparisonChartBlock
  | ContainerBlock
  | HTMLBlock
  | LogiformBlock
  | OfficeAgentsBlock
  | OneColTextBlock
  | PasswordBlock
  | TitleBlock
  | UnrestrictedBlock
  | JoinBlock

export interface BlockBase {
  blockType: BlockOption
  id?: string
  pathnames: string[]
  status: {
    created: Timestamp
    published: PublishedOption
    revised: Timestamp
  }
  options: {
    alignment: AlignmentOption
    marginTop: MarginOption
    marginBottom: MarginOption
    paddingTop: PaddingOption
    paddingBottom: PaddingOption
    theme: ThemeOption
  }
}

export interface FormOptionsBase {
  options?: {
    alignment: AlignmentOption[]
    theme: ThemeOption[]
    marginTop: MarginOption[]
    marginBottom: MarginOption[]
    paddingTop: PaddingOption[]
    paddingBottom: PaddingOption[]
  }
  status: {
    published: PublishedOption[]
  }
}

export interface OptionsBase {
  newForm?: FormGroup
  options?: Record<string, unknown> | unknown
  blockOptions?: Record<string, unknown> | unknown
  combinedForm: boolean
  formArray: FormArrayOptions
  label: {
    plural: string,
    singular: string
  }
  placeholder: string
}

/*
export interface CtaBlock extends BlockBase {
  blockType: "cta"
  fields: {
    cta: {
      value: string
      element: "div"
    }
    link: false | {
      value: string
      path: string
      target: "same" | "new" | "modal"
      option: {
        bold: boolean
        underline: boolean
      }
    }
    button: false | {
      value: string
      path: string
      target: "same" | "new" | "modal"
    }
    icon: false | string
  }
  options: {
    backgroundColor: "blue" | "gray" | "darkgray"
    marginTop: MarginOption
    marginBottom: MarginOption
    paddingTop: PaddingOption
    paddingBottom: PaddingOption
  }
}
*/

/*
export interface FeatureBlock extends BlockBase {
  blockType: "feature"
  fields: {
    title: {
      value: string
      element: "div" | "h1" | "h2" | "h3"
    }
    subtitle: {
      value: string
      element: "div"
    }
  }
}
*/

/*
export interface CardBlock extends BlockBase {
  blockType: "card"
  fields: {
    title: {
      value: string
      element: "div" | "h1" | "h2" | "h3"
    }
    subtitle: {
      value: string
      element: "div"
    }
  }
}
*/

@Injectable({
  providedIn: "root"
})
export class BlockService {

  constructor(
    private bannerService: BannerService,
    private comparisonChartService: ComparisonChartService,
    private containerService: ContainerService,
    private htmlService: HtmlService,
    private logiformService: LogiformService,
    private officeAgentsService: OfficeAgentsService,
    private oneColTextService: OneColTextService,
    private passwordService: PasswordService,
    private titleService: TitleService,
    private unrestrictedService: UnrestrictedService,
    private joinService: JoinService,
  ) {
  }

  get blockOptions(): BlockOption[] {
    // return Object.keys(this.blockTypes) as BlockOption[]
    // return ["banner", "title", "password", "html", "logiform", "officeAgents", "oneColText"]
    return [
      "banner",
      "comparisonChart",
      "logiform",
      "officeAgents",
      "oneColText",
      "password",
      "title",
      "join",
    ]
  }

  get blockTypes(): BlockTypes {
    return {
      banner: {
        component: {
          edit: "multiblock/blocks/banner/banner-view",
          view: "multiblock/blocks/banner/banner-view"
        },
        label: {
          plural: "Banners",
          singular: "Banner"
        },
      },
      card: {
        component: {
          edit: "multiblock/blocks/card/card-edit",
          view: "multiblock/blocks/card/card-view"
        },
        label: {
          plural: "Cards",
          singular: "Card"
        },
      },
      comparisonChart: {
        component: {
          edit: "multiblock/blocks/comparison-chart/comparison-chart-view",
          view: "multiblock/blocks/comparison-chart/comparison-chart-view"
        },
        label: {
          plural: "Comparison Charts",
          singular: "Comparison Chart"
        },
      },
      container: {
        component: {
          edit: "multiblock/container-blocks/container/container-edit",
          view: "multiblock/container-blocks/container/container-view"
        },
        label: {
          plural: "Cards as vars",
          singular: "Cards as var"
        },
      },
      cta: {
        component: {
          edit: "multiblock/blocks/cta/cta-edit",
          view: "multiblock/blocks/cta/cta-view"
        },
        label: {
          plural: "CTAs",
          singular: "CTA"
        },
      },
      feature: {
        component: {
          edit: "multiblock/blocks/feature/feature-edit",
          view: "multiblock/blocks/feature/feature-view"
        },
        label: {
          plural: "Features",
          singular: "Feature"
        },
      },
      html: {
        component: {
          edit: "multiblock/blocks/html/html-edit",
          view: "multiblock/blocks/html/html-view"
        },
        label: {
          plural: "HTML",
          singular: "HTML"
        },
      },
      logiform: {
        component: {
          edit: "multiblock/blocks/logiform/logiform-edit",
          view: "multiblock/blocks/logiform/logiform-view"
        },
        label: {
          plural: "Logiforms",
          singular: "Logiform"
        },
      },
      officeAgents: {
        component: {
          edit: "multiblock/blocks/office-agents/office-agents-view",
          view: "multiblock/blocks/office-agents/office-agents-view"
        },
        label: {
          plural: "Office Agents",
          singular: "Office Agents"
        },
      },
      oneColText: {
        component: {
          edit: "multiblock/blocks/one-col-text/one-col-text-view",
          view: "multiblock/blocks/one-col-text/one-col-text-view"
        },
        label: {
          plural: "One Column Text",
          singular: "One Column Text"
        },
      },
      password: {
        component: {
          edit: "multiblock/blocks/password/password-edit",
          view: "multiblock/blocks/password/password-view"
        },
        label: {
          plural: "Passwords",
          singular: "Password"
        },
      },
      title: {
        component: {
          edit: "multiblock/blocks/title/title-view",
          view: "multiblock/blocks/title/title-view"
        },
        label: {
          plural: "Title",
          singular: "Titles"
        },
      },
      unrestricted: {
        component: {
          edit: "multiblock/blocks/unrestricted/unrestricted-edit",
          view: "multiblock/blocks/unrestricted/unrestricted-view"
        },
        label: {
          plural: "Unrestricted",
          singular: "Unrestricted"
        },
      },
      join: {
        component: {
          edit: "multiblock/blocks/join/join-view",
          view: "multiblock/blocks/join/join-view"
        },
        label: {
          plural: "Join Pages",
          singular: "Join Page"
        },
      },
    }
  }

  get containerTypes(): ContainerTypes {
    return this.containerService.types
  }

  get containerBlockTypes(): ContainerTypes {
    return this.containerService.blockTypes
  }

  newBlock(blockOption: BlockOption, containerOption?: ContainerOption): Multiblock {
    if (blockOption === "banner") {
      return this.bannerService.newBlock
    }
    if (blockOption === "comparisonChart") {
      return this.comparisonChartService.newBlock
    }
    if (blockOption === "container" && containerOption) {
      return this.containerService.newContainerBlock(containerOption)
    }
    if (blockOption === "html") {
      return this.htmlService.newBlock
    }
    if (blockOption === "logiform") {
      return this.logiformService.newBlock
    }
    if (blockOption === "officeAgents") {
      return this.officeAgentsService.newBlock
    }
    if (blockOption === "oneColText") {
      return this.oneColTextService.newBlock
    }
    if (blockOption === "password") {
      return this.passwordService.newBlock
    }
    if (blockOption === "title") {
      return this.titleService.newBlock
    }
    if (blockOption === "unrestricted") {
      return this.unrestrictedService.newBlock
    }
    if (blockOption === "join") {
      return this.joinService.newBlock
    }
  }

  /**
   * TODO: Before trying to build the "newForm" the block input should be validated to match the blockType data requirements
   */
  newBlockForm(block: Multiblock): BlockState {
    if (block.blockType === "banner") {
      return this.bannerService.newForm(block as BannerBlock)
    }
    if (block.blockType === "comparisonChart") {
      return this.comparisonChartService.newForm(block as ComparisonChartBlock)
    }
    if (block.blockType === "html") {
      return this.htmlService.newForm(block as HTMLBlock)
    }
    if (block.blockType === "logiform") {
      return this.logiformService.newForm(block as LogiformBlock)
    }
    if (block.blockType === "officeAgents") {
      return this.officeAgentsService.newForm(block as OfficeAgentsBlock)
    }
    if (block.blockType === "oneColText") {
      return this.oneColTextService.newForm(block as OneColTextBlock)
    }
    if (block.blockType === "password") {
      return this.passwordService.newForm(block as PasswordBlock)
    }
    if (block.blockType === "title") {
      return this.titleService.newForm(block as TitleBlock)
    }
    if (block.blockType === "unrestricted") {
      return this.unrestrictedService.newForm(block as UnrestrictedBlock)
    }
    if (block.blockType === "join") {
      return this.joinService.newForm(block as JoinBlock)
    }
  }

}
