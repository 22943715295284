import { Component } from "@angular/core"
import { SwStateMachineService } from "../../services/sw-state-machine.service"

@Component({
  selector: "aaa-smart-weather-error",
  templateUrl: "./error.component.html"
})
export class ErrorComponent {

  constructor(
    public swSms: SwStateMachineService,
  ) {
  }


  /**
   * Sample error messages from cloudFunction
   *
   *
   *
   * status: 500
   * error: "smartweather() error1:handePost() error: 1:writeContact() error: {"message":"unable to verify the first certificate","name":"Error","stack":"Error: unable to verify the first certificate\n    at TLSSocket.onConnectSecure (_tls_wrap.js:1088:34)\n    at TLSSocket.emit (events.js:198:13)\n    at TLSSocket.EventEmitter.emit (domain.js:466:23)\n    at TLSSocket._finishInit (_tls_wrap.js:666:8)","config":{"url":"https://api.everbridge.net/rest/recordTypes/431700047823033/","method":"get","headers":{"Accept":"application/json, text/plain, *\/*","Authorization":"EVBG-HMAC-SHA256 5f8626f32ea5dc43d0c51667:/JQUrUOd01s/Ww+OTcCoFiafmRHIAy8WuYx79m9tj4k=","Content-Type":"application/json","User-Agent":"axios/0.19.2"},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1},"code":"UNABLE_TO_VERIFY_LEAF_SIGNATURE"}"
   *
   *
   */
}
