<!--Load [page] container from either view module or admin module, depending on adminMode-->
<ng-container #blockContainer></ng-container>

<!--Load admin component if user has permissions-->
<ng-container #adminContainer></ng-container>

<!--
<div
  *ngIf="roleService.roles.administrator"
  [style]="'position: fixed; top: 28px; width: 100%'"
>
  <div
    [style]="'background-color: lightgray; display: flex; align-items: center; padding: 0; height: 29px; width: 100%'"
  >
    <a
      [style]="'padding: 5px; margin-left: auto; padding-right: 30px'"
      nz-tooltip
      [nzTooltipTitle]="'Edit Mode'"
      (click)="enableAdminMode()"
    >
      Public
    </a>
  </div>
</div>
-->


<!--icon for toggling adminMode on and off-->
<!--
<div
  *ngIf="roleService.roles.administrator && !blockService.adminMode"
  nz-tooltip
  [nzTooltipTitle]="'Admin Mode'"
  [nzTooltipPlacement]="'left'"
  [style]="'position: fixed; bottom: 50px; right: 50px; z-index: 999; text-align: center; width: 150px; font-weight: 500'"
  [style.cursor]="'pointer'"
  (click)="enableAdminMode()"
>
  <div>
    <div>
      Public
    </div>
    <div [style]="'display: flex; justify-content: center'">
      <i
        nz-icon nzType="experiment" nzTheme="fill"
        [style.font-size.px]="64"
      ></i>
    </div>
  </div>
</div>
-->

<!--
<div
  *ngIf="roleService.roles.administrator && !blockService.adminMode"
  [style]="'position: fixed; top: 45%; left: 10px; z-index: 999'"
  [style.cursor]="'pointer'"
  (click)="enableAdminMode()"
>
  <i nz-icon nzType="menu" nzTheme="outline"></i>
</div>
-->
