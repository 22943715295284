import { Injectable } from "@angular/core"
import { FormOptionsBase, BlockBase, BlockOption } from "@aaa-web/app/modules/multiblock/services/block.service"
import { BlockStateBase } from "@aaa-web/app/modules/multiblock/services/state.service"
import { FormArray, FormControl, FormGroup } from "@angular/forms"
import {
  MarginOption, PaddingOption, AlignmentOption, ThemeOption
} from "@aaa-web/app/modules/multiblock/services/style.service"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { PublishedOption } from "@aaa-web/app/modules/multiblock/blocks/settings.service"
import {
  Button, ButtonOptions, ButtonService,
} from "@aaa-web/app/modules/multiblock/elements/button/button.service"
import { Text, TextOptions, TextService } from "@aaa-web/app/modules/multiblock/elements/text/text.service"
import {
  BackgroundImage, BackgroundImageOptions
} from "@aaa-web/app/modules/multiblock/elements/background-image/background-image.service"
import {
  Javascript, JavascriptOptions
} from "@aaa-web/app/modules/multiblock/elements/javascript/javascript.service"

export interface Block extends BlockBase {
  fields: {
    titles: Text[]
    subtitles: Text[]
    buttons: Button[]
    images: BackgroundImage[]
    javascripts: Javascript[]
  }
}

export interface FormOptions extends FormOptionsBase {
  fields?: {
    titles: TextOptions
    subtitles: TextOptions
    buttons: ButtonOptions
    images: BackgroundImageOptions
    javascripts: JavascriptOptions
  }
}

export interface BlockState extends BlockStateBase {
  formOptions?: FormOptions
}

export type FormDisplayOption = boolean | "form"

export interface FormDisplayOptions {
  fields?: {
    titles?: FormDisplayOption
    subtitles?: FormDisplayOption
    buttons?: FormDisplayOption
    images?: FormDisplayOption
    javascripts?: FormDisplayOption
  }
  options?: {
    alignment?: boolean
    theme?: boolean
  }
}

@Injectable({
  providedIn: "root"
})
export class BannerService {
  window: MetaWindow
  newBlockId: BlockOption = "banner"

  constructor(
    private buttonService: ButtonService,
    private textService: TextService,
    private domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  get titleOptions(): TextOptions {
    const options: TextOptions = {
      blockOptions: {
        alignment: true,
        theme: true
      },
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newTitle,
      options: [],
      combinedForm: true,
      label: {
        plural: "Titles",
        singular: "Title"
      },
      placeholder: "title",
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: ["h1", "h2", "h3"],
        value: true
      })
    }
    return options
  }

  get subtitleOptions(): TextOptions {
    const options: TextOptions = {
      blockOptions: {
        alignment: true,
        theme: true
      },
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newSubtitle,
      options: [],
      combinedForm: true,
      label: {
        plural: "Additional Copies",
        singular: "Additional Copy"
      },
      placeholder: "additional copy",
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get buttonOptions(): ButtonOptions {
    const options: ButtonOptions = {
      newForm: this.newButtonForm(this.newButton),
      options: [],
      combinedForm: true,
      formArrayPath: ["buttons"],
      formGroupPath: ["fields"],
      label: {
        plural: "Buttons",
        singular: "Button"
      },
      placeholder: "",
      formArray: {
        min: 0,
        max: 3
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        height: { px: [] },
        width: { px: [] },
        color: ["darkblue", "red"],
        target: ["same", "new", "modal"],
        value: true
      })
    }
    return options
  }

  get imageOptions(): BackgroundImageOptions {
    const options: BackgroundImageOptions = {
      options: [],
      combinedForm: true,
      label: {
        plural: "Background Images",
        singular: "Background Image"
      },
      placeholder: "",
      formArray: {
        min: 3,
        max: 3
      }
    }
    options.options = [
      {
        description: "Desktop 1440x480",
        directory: "images/1440x480",
        minWidth: {
          px: 1440
        },
        maxWidth: {
          px: 1440
        },
        minHeight: {
          px: 480
        },
        maxHeight: {
          px: 480
        },
      },
      {
        description: "Tablet 768x320",
        directory: "images/768x320",
        minWidth: {
          px: 768
        },
        maxWidth: {
          px: 768
        },
        minHeight: {
          px: 320
        },
        maxHeight: {
          px: 320
        },
      },
      {
        description: "Mobile 544x350",
        directory: "images/544x350",
        minWidth: {
          px: 544
        },
        maxWidth: {
          px: 544
        },
        minHeight: {
          px: 350
        },
        maxHeight: {
          px: 350
        },
      }
    ]
    return options
  }

  get javascriptOptions(): JavascriptOptions {
    const options: JavascriptOptions = {
      newJavascriptForm: this.newJavascriptForm(this.newJavascript),
      javascriptOptions: [],
      combinedForm: true,
      formArrayPath: ["javascripts"],
      formGroupPath: ["fields"],
      label: {
        plural: "Logiforms",
        singular: "Logiform"
      },
      placeholder: "javascript code",
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.javascriptOptions.push({
        background: {
          color: false, // changing to true will also need a formControl
          opacity: true
        },
        position: {
          x: true,
          y: false // changing to true will also need a formControl
        },
        width: {
          px: true
        },
        value: true
      })
    }
    return options
  }

  get formDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        subtitles: "form",
        buttons: "form",
        images: "form",
        javascripts: "form"
      },
      options: {
        alignment: true,
        theme: true
      }
    }
  }

  get titlesFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        subtitles: "form",
        buttons: "form",
        images: "form",
        javascripts: "form"
      },
      options: {
        alignment: true,
        theme: true
      }
    }
  }

  get subtitlesFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        subtitles: "form",
        buttons: "form",
        images: "form",
        javascripts: "form"
      },
      options: {
        alignment: true,
        theme: true
      }
    }
  }

  get buttonsFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        subtitles: "form",
        buttons: "form",
        images: "form",
        javascripts: "form"
      },
      options: {
        alignment: true,
        theme: true
      }
    }
  }

  get javascriptsFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        subtitles: "form",
        buttons: "form",
        images: "form",
        javascripts: "form"
      },
      options: {
        alignment: true,
        theme: true
      }
    }
  }

  get imagesFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        subtitles: "form",
        buttons: "form",
        images: "form",
        javascripts: "form"
      },
      options: {
        alignment: true,
        theme: true
      }
    }
  }

  get themeOptions(): ThemeOption[] {
    return ["white", "black"]
  }

  get alignmentOptions(): AlignmentOption[] {
    return ["left", "center"]
  }

  get marginOptions(): MarginOption[] {
    return ["none", "thin", "large"]
  }

  get paddingOptions(): PaddingOption[] {
    return ["none", "thin", "large"]
  }

  get publishedOptions(): PublishedOption[] {
    return ["published", "unpublished"]
  }

  newTitle = (): Text => {
    return {
      element: "h1",
      value: "",
    }
  }

  newSubtitle = (): Text => {
    return this.textService.newText
  }

  get newButton(): Button {
    return this.buttonService.newButton
  }

  get newJavascript(): Javascript {
    return {
      background: {
        color: "white",
        opacity: 80
      },
      position: {
        x: 20,
      },
      value: "",
      width: 30
    }
  }

  get newBlock(): Block {
    return {
      blockType: this.newBlockId,
      id: this.newBlockId,
      fields: {
        titles: [this.newTitle()],
        subtitles: [],
        buttons: [],
        images: [
          {
            src: "",
          },
          {
            src: "",
          },
          {
            src: "",
          },
        ],
        javascripts: [this.newJavascript]
      },
      options: {
        alignment: "left",
        marginBottom: "none",
        marginTop: "none",
        paddingBottom: "thin",
        paddingTop: "thin",
        theme: "black",
      },
      pathnames: [this.window.location.pathname],
      status: {
        created: Timestamp.now(),
        published: "published",
        revised: Timestamp.now()
      }
    }
  }

  newTextForm = (item: Text): FormGroup => {
    return this.textService.newForm(item)
  }

  newButtonForm(item: Button): FormGroup {
    return new FormGroup({
      color: new FormControl(item.color),
      path: new FormControl(item.path),
      target: new FormControl(item.target),
      value: new FormControl(item.value),
    })
  }

  newImageForm(item: BackgroundImage): FormGroup {
    return new FormGroup({
      src: new FormControl(item.src),
      // path: new FormControl(image.path),
      // target: new FormControl(image.target),
      // value: new FormControl(image.value),
    })
  }

  newJavascriptForm(item: Javascript): FormGroup {
    return new FormGroup({
      background: new FormGroup({
        color: new FormControl(item.background.color),
        opacity: new FormControl(item.background.opacity)
      }),
      position: new FormGroup({
        x: new FormControl(item.position.x),
      }),
      value: new FormControl(item.value),
      width: new FormControl(item.width)
    })
  }

  newForm(block: Block): BlockState {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          titles: new FormArray(block.fields.titles.map(item => this.newTextForm(item))),
          subtitles: new FormArray(block.fields.subtitles.map(item => this.newTextForm(item))),
          buttons: new FormArray(block.fields.buttons.map(item => this.newButtonForm(item))),
          images: new FormArray(block.fields.images.map(item => this.newImageForm(item))),
          javascripts: new FormArray(block.fields.javascripts.map(item => this.newJavascriptForm(item)))
        }),
        options: new FormGroup({
          alignment: new FormControl(block.options.alignment),
          marginTop: new FormControl(block.options.marginTop),
          marginBottom: new FormControl(block.options.marginBottom),
          paddingTop: new FormControl(block.options.paddingTop),
          paddingBottom: new FormControl(block.options.paddingBottom),
          theme: new FormControl(block.options.theme),
        }),
        pathnames: new FormControl(block.pathnames),
        status: new FormGroup({
          created: new FormControl(block.status.created),
          published: new FormControl(block.status.published),
          revised: new FormControl(block.status.revised)
        })
      })
    }
  }

  get newFormOptions(): FormOptions {
    return {
      fields: {
        titles: this.titleOptions,
        subtitles: this.subtitleOptions,
        buttons: this.buttonOptions,
        images: this.imageOptions,
        javascripts: this.javascriptOptions,
      },
      options: {
        alignment: this.alignmentOptions,
        marginBottom: this.marginOptions,
        marginTop: this.marginOptions,
        paddingBottom: this.paddingOptions,
        paddingTop: this.paddingOptions,
        theme: this.themeOptions,
      },
      status: {
        published: this.publishedOptions
      }
    }
  }

}
