import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { GoogleChartsModule } from "angular-google-charts"

import { CoreModule } from "@aaa-web/app/core/core.module"
import { AccordionComponent } from "@aaa-web/app/core/components/accordion/accordion.component"
import { InfiniteScrollComponent } from "@aaa-web/app/core/components/infinite-scroll/infinite-scroll.component"

import { SimpleLoggerComponent } from "./simple-logger.component"
import { EmemberBatchComponent } from "./emember-batch/emember-batch.component"
import { EmemberBatchDialogComponent } from "./emember-batch/emember-batch-dialog/emember-batch-dialog.component"
import { EmemberHealthComponent } from "./emember-health/emember-health.component"
import { EmemberHealthDialogComponent } from "./emember-health/emember-health-dialog/emember-health-dialog.component"
import { EmemberHealthFlaggedComponent } from "./emember-health/emember-health-flagged/emember-health-flagged.component"
import { EmemberHealthErrorComponent } from "./emember-health/emember-health-error/emember-health-error.component"
import { MemberNumberComponent } from "./member-number/member-number.component"
import { V3BatchComponent } from "./v3-batch/v3-batch.component"
import { V3BatchTooltipDialogComponent } from "./v3-batch/v3-batch-tooltip-dialog.component"

import { NzButtonModule } from "ng-zorro-antd/button"
import { NzInputModule } from "ng-zorro-antd/input"

import { NzGridModule } from "ng-zorro-antd/grid"
import { NzMessageModule } from "ng-zorro-antd/message"
import { NzNotificationModule } from "ng-zorro-antd/notification"
import { NzModalModule } from "ng-zorro-antd/modal"

@NgModule({
  declarations: [
    SimpleLoggerComponent,
    MemberNumberComponent,
    AccordionComponent,
    InfiniteScrollComponent,
    V3BatchComponent,
    V3BatchTooltipDialogComponent,
    EmemberBatchComponent,
    EmemberBatchDialogComponent,
    EmemberHealthComponent,
    EmemberHealthDialogComponent,
    EmemberHealthFlaggedComponent,
    EmemberHealthErrorComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    GoogleChartsModule,
    NzButtonModule,
    NzInputModule,
    NzGridModule,
    NzMessageModule,
    NzNotificationModule,
    NzModalModule,
  ],
})
export class SimpleLoggerModule {
}
