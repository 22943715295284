import { Component } from "@angular/core"

@Component({
  selector: "aaa-app-home",
  template: `
    <aaa-multiblock></aaa-multiblock>
  `
})
export class AppHomeComponent {
}
