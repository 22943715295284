<style>
  img {
    width: 100%;
    height: auto;
  }
</style>
<div #container>
  <div
    *ngIf="containerWidth >= 760"
    [style]="'text-align: center; margin: 10px; font-size: 30px; font-weight: 400'"
  >
    {{ formValues.appMode === "TRAVEL" ? "Choose a Travel Agent" : "" }}
    {{ formValues.appMode === "INSURANCE" ? "Choose Insurance Agent" : "" }}
  </div>
  <div
    [style]="'display: grid; grid-template-columns: 350px auto; justify-content: center; justify-items: center'"
    [ngStyle]="{'grid-template-columns': containerWidth >= 800 ? '350px auto' : 'auto'}"
  >

    <div [style]="'width: 350px; display: grid; justify-items: center; align-content: flex-start; grid-gap: 10px'">
      <div [style]="'font-size: 22px; font-weight: 500;'">
        Select office below to list agents
      </div>
      <google-map [center]="center" [options]="mapOptions" height="350" width="350">
        <map-marker
          #markerElement="mapMarker"
          *ngFor="let marker of markers"
          (mapClick)="openInfo(markerElement, marker)"
          [options]="marker.options"
          [position]="marker.position"
          [title]="marker.title"
        >
        </map-marker>
        <map-info-window>
          <div [style]="'margin: 10px; text-align: center'">
            <div [style]="'font-size: 18px; font-weight: 500'">
              {{ infoData.title }}
            </div>
            <div>
              {{ infoData.addr }}
            </div>
            <div>
              ({{ infoData.agents }})
            </div>
            <button
              nz-button [nzType]="'primary'"
              (click)="filter(infoData.officeId)"
              [style]="'margin-top: 10px'"
            >
              Select
            </button>
          </div>
        </map-info-window>
      </google-map>
      <div>
        Showing agents from
        {{ showingAll ? "all offices" : filterName }}
      </div>
      <button
        nz-button [nzType]="'primary'"
        (click)='resetFilter()'
        [disabled]="showingAll"
      >
        Show all
      </button>
    </div>

    <div [style]="'display: flex; flex-wrap: wrap; max-width: 700px'">
      <nz-card
        *ngFor='let agent of filteredAgents'
        (click)="sendEvent(agent); $event.preventDefault()"
        [style]="'width: 210px; margin: 10px'"
        [nzBodyStyle]="{'display': 'grid', 'grid-gap': '10px', 'justify-content': 'center', 'padding': '5px 15px 15px'}"
      >
        <div [style]="'width: 180px'">
          <img
            [src]="agent.image"
            [alt]="'Travel Agent: ' + agent.fName + ' ' + agent.lName"
            [style]="'border-radius: 10px'"
          >
        </div>
        <div [style]="'text-align: center'">
          <div [style]="'font-weight: 500; font-size: 18px; text-align: center'">
            {{ agent.fName }}
          </div>
          <div [style]="'font-weight: 500; font-size: 18px; text-align: center'">
            {{ agent.lName }}
          </div>
          <div [style]="'font-weight: 200; font-size: 16px; text-align: center'" *ngIf="agent.officeIds[0].length">
            {{ agentOffice(agent.officeIds[0]) }}
          </div>
        </div>
        <div [style]="'display: flex; justify-content: center; margin: 10px'">
          <button nz-button [nzType]="'primary'">
            Select
          </button>
        </div>
      </nz-card>
    </div>
  </div>
</div>
