import { BrowserModule } from "@angular/platform-browser"
import { Injector, NgModule } from "@angular/core"
import { createCustomElement } from "@angular/elements"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { OverlayModule } from "@angular/cdk/overlay"

import { environment } from "../environments/environment"
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n"
import { APP_BASE_HREF, registerLocaleData } from "@angular/common"
import en from "@angular/common/locales/en"

import { AngularFireAuthModule } from "@angular/fire/auth"
import { AngularFireFunctionsModule } from "@angular/fire/functions"
import { AngularFireModule } from "@angular/fire"
import { AngularFireStorageModule } from "@angular/fire/storage"
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS, } from "@angular/fire/firestore"
import { ReactiveFormsModule } from "@angular/forms"

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"

import { GoogleChartsModule } from "angular-google-charts"

import { CoreModule } from "./core/core.module"

import { AnalyticsService } from "./core/services/analytics.service"
import { ErrorInterceptor } from "./core/services/error-interceptor.service"

import { HiddenTitleCustomElementComponent } from "./custom-elements/hidden-title/hidden-title.component"
import { HiddenTitleCustomElementModule } from "./custom-elements/hidden-title/hidden-title.module"

import { MultiblockCustomElementComponent } from "./custom-elements/multiblock/multiblock.component"
import { MultiblockCustomElementModule } from "./custom-elements/multiblock/multiblock.module"

import { OfficeAgentsCustomElementComponent } from "./custom-elements/office-agents/office-agents.component"
import { OfficeAgentsCustomElementModule } from "./custom-elements/office-agents/office-agents.module"



import { AgentSchedulerComponent } from "./modules/agent-scheduler/agent-scheduler.component"
import { AgentSchedulerModule } from "./modules/agent-scheduler/agent-scheduler.module"

import { BatteryQuoterComponent } from "./modules/battery-quoter/battery-quoter.component"
import { BatteryQuoterModule } from "./modules/battery-quoter/battery-quoter.module"

import { FileManagerComponent } from "./modules/file-manager/file-manager.component"
import { FileManagerModule } from "./modules/file-manager/file-manager.module"

import { AccountEmailSyncComponent } from "./modules/account-email-sync/account-email-sync.component"
import { AccountEmailSyncModule } from "./modules/account-email-sync/account-email-sync.module"

import { NotificationComponent } from "./modules/notification/notification.component"
import { NotificationModule } from "./modules/notification/notification.module"

import { SmartWeatherComponent } from "./modules/smart-weather/smart-weather.component"
import { SmartWeatherModule } from "./modules/smart-weather/smart-weather.module"

import { JoinRenewModule } from "./modules/join-renew/join-renew.module"
import { JoinRenewComponent } from "./modules/join-renew/join-renew.component"

import { SimpleLoggerModule } from "./modules/simple-logger/simple-logger.module"
import { SimpleLoggerComponent } from "./modules/simple-logger/simple-logger.component"

import { NzMessageService } from "ng-zorro-antd/message"
import { NzNotificationService } from "ng-zorro-antd/notification"
import { NzModalService } from "ng-zorro-antd/modal"
import { IconModule } from "./icon.module"

import { AgmCoreModule } from "@agm/core"
import { QuillModule } from "ngx-quill"
import { RouterModule, Routes } from "@angular/router"

registerLocaleData(en)

const routes: Routes = []

@NgModule({
  declarations: [],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    OverlayModule,
    IconModule,
    HttpClientModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCyuxSHgL1KJ5eLfBzJJ6ledX2fift-_y8"
    }),
    GoogleChartsModule,
    QuillModule.forRoot(),
    RouterModule.forRoot(routes, { initialNavigation: "enabled" }),
  ],
  providers: [
    HiddenTitleCustomElementModule,
    MultiblockCustomElementModule,
    OfficeAgentsCustomElementModule,

    AccountEmailSyncModule,
    AgentSchedulerModule,
    BatteryQuoterModule,
    FileManagerModule,
    NotificationModule,
    SimpleLoggerModule,
    SmartWeatherModule,
    JoinRenewModule,
    AnalyticsService,
    NzMessageService,
    NzModalService,
    NzNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useValue: window["location"]["pathname"]
    },
    {
      provide: Window,
      useValue: window
    },
    {
      provide: FIRESTORE_SETTINGS,
      useValue: environment.emulator ? {
        host: "localhost:8080",
        ssl: false
      } : undefined
    },
    {
      provide: NZ_I18N,
      useValue: en_US
    },
  ],
  bootstrap: [],
})
export class CustomElementModule {
  constructor(
    private injector: Injector,
  ) {
  }

  ngDoBootstrap() {

    /**
     * Create the custom element from an Angular component.
     */
    const hiddenTitleElement = createCustomElement(HiddenTitleCustomElementComponent, { injector: this.injector })
    const multiblockElement = createCustomElement(MultiblockCustomElementComponent, { injector: this.injector })
    const officeAgentsElement = createCustomElement(OfficeAgentsCustomElementComponent, { injector: this.injector })

    const accountEmailSyncElement = createCustomElement(AccountEmailSyncComponent, { injector: this.injector })
    const agentSchedulerElement = createCustomElement(AgentSchedulerComponent, { injector: this.injector })
    const batteryElement = createCustomElement(BatteryQuoterComponent, { injector: this.injector })
    const fileManagerElement = createCustomElement(FileManagerComponent, { injector: this.injector })
    const notificationElement = createCustomElement(NotificationComponent, { injector: this.injector })
    const simpleLoggerElement = createCustomElement(SimpleLoggerComponent, { injector: this.injector })
    const smartWeatherElement = createCustomElement(SmartWeatherComponent, { injector: this.injector })
    const joinRenewElement = createCustomElement(JoinRenewComponent, { injector: this.injector })

    /**
     * Define the custom element with a name.
     */
    defineCustomElement("aaa-hidden-title-custom-element", hiddenTitleElement)
    defineCustomElement("aaa-multiblock-custom-element", multiblockElement)
    defineCustomElement("aaa-office-agents-custom-element", officeAgentsElement)

    defineCustomElement("aaa-account-email-sync", accountEmailSyncElement)
    defineCustomElement("aaa-agent-scheduler", agentSchedulerElement)
    defineCustomElement("aaa-battery-quoter", batteryElement)
    defineCustomElement("aaa-file-manager", fileManagerElement)
    defineCustomElement("aaa-notification", notificationElement)
    defineCustomElement("aaa-simple-logger", simpleLoggerElement)
    defineCustomElement("aaa-smart-weather", smartWeatherElement)
    defineCustomElement("aaa-join-renew", joinRenewElement)

    function defineCustomElement(elementName, element) {
      if (customElements.get(elementName) === undefined) {
        customElements.define(elementName, element)
      }
    }

    /**
     * Add the custom element name to the init/index.js array of customElementTags in getCustomElements().
     * This will provide an analytics event for the custom element.
     * If the browser is unsupported, it will also provide a browser upgrade message.
     */
  }
}
