import { Component, ViewChild, ElementRef, AfterViewInit, HostListener } from "@angular/core"
import { BatteryQuoterService } from "../../battery-quoter.service"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

@Component({
  selector: "aaa-form",
  templateUrl: "./form.component.html"
})
export class FormComponent implements AfterViewInit {
  @ViewChild("container") container: ElementRef
  containerWidth: number
  gridTemplateColumns: { [key: string]: string } = {}
  gridButtonColumn: { [key: string]: string } = {}
  window: MetaWindow

  constructor(
    public bs: BatteryQuoterService,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container.nativeElement.offsetWidth
    this.gridTemplateColumns = { "grid-template-columns": "360px" }
    this.gridButtonColumn = { "grid-column": "1" }
    if (this.containerWidth >= 700) {
      this.gridTemplateColumns = { "grid-template-columns": "360px 360px" }
      this.gridButtonColumn = { "grid-column": "2" }
    }
    if (this.containerWidth >= 1080) {
      this.gridTemplateColumns = { "grid-template-columns": "360px 360px 360px" }
    }
  }

}
