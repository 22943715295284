<div style='padding: 20px;'>

  <p>
    emulator: {{ emu }}
  </p>
  <p>
    sessionId: {{ sessionId }}
  </p>

  <p>join-renew</p>

  <br />

  <button (click)="startOperation()">[1] start operation (join) 🤖</button>
  <br />
  <button (click)="paymentSubmitted()">[2] payment submitted 😎</button>
  <br />
  <button (click)="paymentResponse()">[3] payment response ☁️</button>


  <br />

  <pre>
    {{ sessionData }}
  </pre>

</div>

<aaa-cybersource></aaa-cybersource>
