import { Component, OnInit } from "@angular/core"
import { RoleService } from "@aaa-web/app/core/services/role.service"
import { NzMessageService } from "ng-zorro-antd/message"
import { NzNotificationService } from "ng-zorro-antd/notification"

@Component({
  selector: "aaa-simple-logger",
  templateUrl: "./simple-logger.component.html"
})
export class SimpleLoggerComponent implements OnInit {
  state = {
    memberNumber: false,
    v3Batch: false,
    ememberBatch: false,
    ememberHealth: false,
    createUser: false
  }
  value: string

  constructor(
    public roleService: RoleService,
    private message: NzMessageService,
    private notification: NzNotificationService,
  ) {
  }

  ngOnInit(): void {
    this.createBasicMessage()
  }

  /**
   * Toggles the state passed in as value
   * and disables all other states.
   *
   * @param value
   */
  toggleState(value: string): void {
    Object.keys(this.state).forEach(key => {
      if (key === value) {
        this.state[key] = !this.state[key]
      } else {
        this.state[key] = false
      }
    })
  }

  createBasicMessage(): void {
    this.message.info("This is a normal message")
  }

  createBasicNotification(): void {
    this.notification
      .blank(
        "Notification Title",
        "This is the content of the notification. This is the content of the notification. This is the content of the notification."
      )
      .onClick.subscribe(() => {
      console.log("notification clicked!")
    })
  }

}
