export enum InputState {
  INIT,
  WAITING,
  PROCESSING,
  READY
}

export enum InputEvent {
  INIT,
  MAKE,
  MODEL,
  YEAR,
  ENGINE,
  BUTTON
}

export enum ComponentState {
  INIT,
  FORM,
  RESULTS,
  NO_RESULTS
}
