import { Injectable } from "@angular/core"
import { FormOptionsBase, BlockBase } from "../../services/block.service"
import { BlockStateBase } from "@aaa-web/app/modules/multiblock/services/state.service"
import { FormArray, FormControl, FormGroup } from "@angular/forms"
import {
  MarginOption, PaddingOption, AlignmentOption, ElementStyles, StyleService, ThemeOption
} from "@aaa-web/app/modules/multiblock/services/style.service"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { PublishedOption } from "@aaa-web/app/modules/multiblock/blocks/settings.service"
import { ColorService, ColorTypes } from "@aaa-web/app/modules/multiblock/services/color.service"
import {
  Button, ButtonOptions, ButtonService, ButtonStyles,
} from "@aaa-web/app/modules/multiblock/elements/button/button.service"
import { Text, TextOptions } from "@aaa-web/app/modules/multiblock/elements/text/text.service"
import {
  QuillEditor, QuillEditorOptions
} from "@aaa-web/app/modules/multiblock/elements/quill/quill.service"
import { TokenService } from "@aaa-web/app/core/services/token.service"

export interface Block extends BlockBase {
  fields: {
    titles: Text[]
    bodies: QuillEditor[]
    buttons: Button[]
  }
}

export interface FormOptions extends FormOptionsBase {
  fields?: {
    titles: TextOptions
    bodies: QuillEditorOptions
    buttons: ButtonOptions
  }
}

export interface BlockState extends BlockStateBase {
  formOptions?: FormOptions
}

export interface FormDisplayOptions {
  fields?: {
    titles?: boolean | "form"
    bodies?: boolean | "form"
    buttons?: boolean | "form"
  },
  options?: {

  }
}

@Injectable({
  providedIn: "root"
})
export class OneColTextService {
  window: MetaWindow

  constructor(
    private buttonService: ButtonService,
    private colorService: ColorService,
    private styleService: StyleService,
    private tokenService: TokenService,
    private domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  get titleOptions(): TextOptions {
    const options: TextOptions = {
      newFormFunction: this.newTitleForm,
      newFormItemFunction: this.newTitle,
      options: [],
      combinedForm: true,
      label: {
        plural: "Titles",
        singular: "Title"
      },
      placeholder: "title",
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: ["h1", "h2", "h3"],
        value: true
      })
    }
    return options
  }

  titleStyles(block: Block, width: number): ElementStyles {
    return {
      color: block.options.theme === "blue" ? this.colorTypes["white"].rgba : this.colorTypes["black"].rgba,
      fontSize: {
        px: 60
      },
      fontWeight: 500,
      textAlign: this.styleService.alignmentTypes[block.options.alignment].textAlign
    }
  }

  get bodyOptions(): QuillEditorOptions {
    const options: QuillEditorOptions = {
      newQuillEditorForm: this.newBodyForm(this.newBody),
      combinedForm: true,
      formArray: {
        min: 0,
        max: 1
      },
      formArrayPath: ["bodies"],
      formGroupPath: ["fields"],
      label: {
        plural: "Additional Copies",
        singular: "Additional Copy"
      },
      placeholder: "add text here",
      quillEditor: [],
      quillEditorModules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ "list": "ordered" }, { "list": "bullet" }],
          [{ "script": "sub" }, { "script": "super" }],
          [{ "indent": "-1" }, { "indent": "+1" }],
          [{ "align": [] }],
        ]
      },
      quillEditorTheme: "snow",
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.quillEditor.push({
        alignment: [],
        element: ["h1", "h2", "h3"],
        fontSize: { px: [32] },
        fontWeight: [400],
        label: "test",
      })
    }
    return options
  }

  bodyStyles(block: Block, width: number): ElementStyles {
    return {
      color: block.options.theme === "blue" ? this.colorTypes["white"].rgba : this.colorTypes["black"].rgba,
      fontSize: {
        px: 60
      },
      fontWeight: 500,
      textAlign: this.styleService.alignmentTypes[block.options.alignment].textAlign
    }
  }

  get buttonOptions(): ButtonOptions {
    const options: ButtonOptions = {
      newForm: this.newButtonForm(this.newButton),
      options: [],
      combinedForm: true,
      formArrayPath: ["buttons"],
      formGroupPath: ["fields"],
      label: {
        plural: "Buttons",
        singular: "Button"
      },
      placeholder: "",
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        height: { px: [] },
        width: { px: [] },
        color: ["darkblue"],
        target: ["same", "new", "modal"],
        value: true
      })
    }
    return options
  }

  buttonStyles(block: Block): ButtonStyles {
    return {
      justifyContent: this.styleService.alignmentTypes[block.options.alignment].justifyContent
    }
  }

  get formDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        bodies: "form"
      },
    }
  }

  get titlesFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        bodies: "form"
      },
    }
  }

  get bodiesFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        bodies: "form"
      },
    }
  }

  get themeOptions(): ThemeOption[] {
    return ["white", "blue", "gray"]
  }

  get colorTypes(): ColorTypes {
    return this.colorService.colorTypes
  }

  get alignmentOptions(): AlignmentOption[] {
    return ["left", "center"]
  }

  get marginOptions(): MarginOption[] {
    return ["none", "thin", "large"]
  }

  get paddingOptions(): PaddingOption[] {
    return ["none", "thin", "large"]
  }

  get publishedOptions(): PublishedOption[] {
    return ["published", "unpublished"]
  }

  newTitle = (): Text => {
    return {
      element: "h1",
      value: "",
    }
  }

  get newBody(): QuillEditor {
    return {
      value: ""
    }
  }

  get newButton(): Button {
    return this.buttonService.newButton
  }

  get newBlock(): Block {
    return {
      blockType: "oneColText",
      id: "oneColText",
      fields: {
        titles: [this.newTitle()],
        bodies: [],
        buttons: [],
      },
      options: {
        alignment: "center",
        marginBottom: "none",
        marginTop: "none",
        paddingBottom: "thin",
        paddingTop: "thin",
        theme: "white",
      },
      pathnames: [this.window.location.pathname],
      status: {
        created: Timestamp.now(),
        published: "published",
        revised: Timestamp.now()
      }
    }
  }

  newTitleForm(text: Text): FormGroup {
    return new FormGroup({
      element: new FormControl(text.element),
      value: new FormControl(text.value),
    })
  }

  newBodyForm(body: QuillEditor): FormGroup {
    return new FormGroup({
      value: new FormControl(body.value),
    })
  }

  newButtonForm(button: Button): FormGroup {
    return new FormGroup({
      color: new FormControl(button.color),
      path: new FormControl(button.path),
      target: new FormControl(button.target),
      value: new FormControl(button.value),
    })
  }

  newForm(block: Block): BlockState {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          titles: new FormArray(block.fields.titles.map(title => this.newTitleForm(title))),
          bodies: new FormArray(block.fields.bodies.map(body => this.newBodyForm(body))),
          buttons: new FormArray(block.fields.buttons.map(button => this.newButtonForm(button))),
        }),
        options: new FormGroup({
          alignment: new FormControl(block.options.alignment),
          marginTop: new FormControl(block.options.marginTop),
          marginBottom: new FormControl(block.options.marginBottom),
          paddingTop: new FormControl(block.options.paddingTop),
          paddingBottom: new FormControl(block.options.paddingBottom),
          theme: new FormControl(block.options.theme),
        }),
        pathnames: new FormControl(block.pathnames),
        status: new FormGroup({
          created: new FormControl(block.status.created),
          published: new FormControl(block.status.published),
          revised: new FormControl(block.status.revised)
        })
      })
    }
  }

  get newFormOptions(): FormOptions {
    return {
      fields: {
        titles: this.titleOptions,
        bodies: this.bodyOptions,
        buttons: this.buttonOptions,
      },
      options: {
        alignment: this.alignmentOptions,
        marginBottom: this.marginOptions,
        marginTop: this.marginOptions,
        paddingBottom: this.paddingOptions,
        paddingTop: this.paddingOptions,
        theme: this.themeOptions,
      },
      status: {
        published: this.publishedOptions
      }
    }
  }

  tokenize(content: string): string {
    return this.tokenService.processTokens(this.tokenService.stripHTML(this.tokenService.preprocessTokens(content)))
  }

}
