// Smart Weather cloud function api
export enum SmartWeatherMethod {
  GET_CONTACT = "GET_CONTACT",
  WRITE_CONTACT = "WRITE_CONTACT",
  UPDATE_CONTACTS = "UPDATE_CONTACTS",
  DISABLE_CONTACT = "DISABLE_CONTACT" //todo - not implemented at all
}

// Request Parameters
export interface AbstractSmartWeatherParameters {
  clubId: string,
  method: SmartWeatherMethod,
  memberNumber?: string
}

export interface WriteContactParameters extends AbstractSmartWeatherParameters {
  contact: ContactRecord
}

export interface GetContactParameters extends AbstractSmartWeatherParameters {
}

export interface UpdateContactsParameters extends AbstractSmartWeatherParameters {
}

// Response
export interface AbstractSmartWeatherResponse {
}

export interface WriteContactResponse extends AbstractSmartWeatherResponse {
  memberNumber: string,
  deleteResponse: any, // mostly straight from evebridge api
  writeResponse: any // straight from evebridge api
}

export interface GetContactResponse extends AbstractSmartWeatherResponse {
  memberNumber: string,
  contact: ContactRecord
}

export interface UpdateContactsResponse extends AbstractSmartWeatherResponse {
  numUpdated: number,
  results: any[],
}

// General Structs
export interface ContactRecord {
  firstName: string,
  lastName: string,
  locations: NotificationLocation[]
}

export interface NotificationLocation {
  name: string
  // addr1: string
  // apt?: string
  // city: string
  // state: string
  // country: string
  postal: string
  gis: {
    lat: number
    lon: number
  }
  paths: {
    email: string[]
    sms: string[]
  }
  categories: Category[]
  updated?: boolean
}

export interface FormValue {
  name: string
  postal: string
  emails: string[]
  phones: string[]
  categories: string[]
}

export enum Category {
  TEMPERATURE = "TEMPERATURE",
  VISIBILITY = "VISIBILITY",
  LIGHTNING = "LIGHTNING",
  TROPICAL = "TROPICAL",
  TSUNAMI = "TSUNAMI",
  MARINE = "MARINE",
  SEVERE = "SEVERE",
  HAZARD = "HAZARD",
  WINTER = "WINTER",
  OTHER = "OTHER",
  FLOOD = "FLOOD",
  WIND = "WIND",
  HAIL = "HAIL",
  FIRE = "FIRE",
}

export type EventType =
  "CLOUD_SYNC_ERROR"
  | "CLOUD_SYNC_COMPLETE"
  | "EDIT"
  | "DELETE"
  | "SAVE"
  | "CANCEL"
  | "RETRY"
  | "RELOAD"
