import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Component, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import { lastValueFrom } from "rxjs"

import { environment } from "@aaa-web/environments/environment"

@Component({
  selector: "aaa-join-renew",
  templateUrl: "./join-renew.component.html"
})
export class JoinRenewComponent implements OnInit {

  sessionData: any = "..."
  emu = environment.emulator
  sessionId: string = String((new Date()).getMilliseconds())

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
  ) {
  }

  ngOnInit() {
    try {
      this.afs
        .collection('wss-aaa-web')
        .doc('102')
        .collection('apps')
        .doc('join-renew')
        .collection('sessions')
        .doc(this.sessionId)
        .valueChanges()
        .subscribe(documentData => {
          this.sessionData = JSON.stringify(documentData, null, 2);
        });

    } catch (e) {
      console.log('err')
      console.log(e)
    }

  }

  async startOperation(): Promise<void> {
    console.log("1 start operation")

    const headers = new HttpHeaders({})
    try {
      const reply = await lastValueFrom(this.http.request(
        "POST",
        environment.cloudFunctionsURL + "/joinRenew-eventCoordinatorReceiver",
        {
          headers: headers,
          body: {
            "sessionId": this.sessionId,
            "clubId": "102",
            // "timestamp": firestore.Timestamp.now(),
            "event": "OPERATION_START",
            "payload": {
              "success": true
            }
          }
        }
      ))

      console.log(reply)

    } catch (e) {
      console.log(e)
    }

  }

  async paymentSubmitted() {
    console.log("2 payment submitted")

    const headers = new HttpHeaders({})
    try {

      const reply = await lastValueFrom(this.http.request(
        "POST",
        environment.cloudFunctionsURL + "/joinRenew-eventCoordinatorReceiver",
        {
          headers: headers,
          body: {
            "sessionId": this.sessionId,
            "clubId": "102",
            // "timestamp": firestore.Timestamp.now(),
            "event": "PAYMENT_SUBMIT",
            "payload": {
              "success": true
            }
          }
        }
      ))

      console.log(reply)

    } catch (e) {
      console.log(e)
    }
  }

  async paymentResponse() {
    console.log("3 payment response")

    const headers = new HttpHeaders({})
    try {
      const reply = await lastValueFrom(this.http.request(
        "POST",
        environment.cloudFunctionsURL + "/joinRenew-paymentTester",
        {
          headers: headers,
          body: {
            "sessionId": this.sessionId,
            "clubId": "102",
            // "timestamp": firestore.Timestamp.now(),
            "payload": {
              "success": true
            }
          }
        }
      ))

      console.log(reply)

    } catch (e) {
      console.log(e)
    }

  }

  // async testDocRW() {
  //   const docRef = this.afs.firestore
  //     .collection('wss-aaa-web')
  //     .doc('102')
  //     .collection('apps')
  //     .doc('joinRenew')
  //     .collection('sessions')
  //     .doc(this.sessionId)

  //   try {
  //     // await docRef.set({ write: true, emu: "wss-4", deployed: false }, { merge: true });
  //     const doc = await docRef.get();
  //     console.log(doc.id);
  //     console.log(doc.data());
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

}
