import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { IconModule } from "@aaa-web/app/icon.module"
import { QuillModule } from "ngx-quill"
import { NzButtonModule } from "ng-zorro-antd/button"
import { NzFormModule } from "ng-zorro-antd/form"
import { NzInputModule } from "ng-zorro-antd/input"
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm"
import { NzRadioModule } from "ng-zorro-antd/radio"
import { NzToolTipModule } from "ng-zorro-antd/tooltip"

import { BannerViewComponent } from "./blocks/banner/banner-view.component"
import { ButtonViewComponent } from "./elements/button/button-view.component"
import { CardViewComponent } from "./blocks/card/card-view.component"
import { ComparisonChartViewComponent } from "./blocks/comparison-chart/comparison-chart-view.component"
import { ContainerViewComponent } from "./container-blocks/container/container-view.component"
import { CtaViewComponent } from "./blocks/cta/cta-view.component"
import { DivComponent } from "./elements/text/div/div.component"
import { FeatureViewComponent } from "./blocks/feature/feature-view.component"
import { FlagViewComponent } from "./elements/flag/flag-view.component"
import { H1Component } from "./elements/text/h1/h1.component"
import { H2Component } from "./elements/text/h2/h2.component"
import { H3Component } from "./elements/text/h3/h3.component"
import { H4Component } from "./elements/text/h4/h4.component"
import { H5Component } from "./elements/text/h5/h5.component"
import { HtmlViewComponent } from "./blocks/html/html-view.component"
import { HtmlAreaComponent } from "./elements/html-area/html-area.component"
import { InfoViewComponent } from "./elements/info/info-view.component"
import { ImageComponent } from "./elements/image/image.component"
import { JavascriptViewComponent } from "./elements/javascript/javascript-view.component"
import { JoinViewComponent } from "./blocks/join/join-view.component"
import { LogiformViewComponent } from "./blocks/logiform/logiform-view.component"
import { MarkupComponent } from "./elements/markup/markup.component"
import { MembershipViewComponent } from "./blocks/join/membership/membership-view.component"
import { OfficeAgentsViewComponent } from "./blocks/office-agents/office-agents-view.component"
import { OneColTextViewComponent } from "./blocks/one-col-text/one-col-text-view.component"
import { PasswordViewComponent } from "./blocks/password/password-view.component"
import { QuillViewComponent } from "./elements/quill/quill-view.component"
import { TextAreaComponent } from "./elements/text-area/text-area.component"
import { TextViewComponent } from "./elements/text/text-view.component"
import { TitleViewComponent } from "./blocks/title/title-view.component"
import { UnrestrictedViewComponent } from "./blocks/unrestricted/unrestricted-view.component"
import { NzDrawerModule } from "ng-zorro-antd/drawer"
import { CreateNewBlockComponent } from "./admin/modals/create-new-block/create-new-block.component"
import { TextFormTextInlineComponent } from "./elements/text/text-form-text-inline.component"

@NgModule({
  declarations: [
    BannerViewComponent,
    ButtonViewComponent,
    CardViewComponent,
    ComparisonChartViewComponent,
    ContainerViewComponent,
    CtaViewComponent,
    DivComponent,
    InfoViewComponent,
    FeatureViewComponent,
    FlagViewComponent,
    H1Component,
    H2Component,
    H3Component,
    H4Component,
    H5Component,
    HtmlAreaComponent,
    HtmlViewComponent,
    ImageComponent,
    JoinViewComponent,
    JavascriptViewComponent,
    LogiformViewComponent,
    MarkupComponent,
    MembershipViewComponent,
    OneColTextViewComponent,
    OfficeAgentsViewComponent,
    PasswordViewComponent,
    QuillViewComponent,
    TextAreaComponent,
    TextViewComponent,
    TitleViewComponent,
    UnrestrictedViewComponent,
    TextFormTextInlineComponent,
    CreateNewBlockComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconModule,
    QuillModule,
    NzButtonModule,
    NzFormModule,
    NzInputModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzToolTipModule,
    NzDrawerModule,
    FormsModule,
  ],
  exports: [
    ButtonViewComponent,
    CardViewComponent,
    ContainerViewComponent,
    CtaViewComponent,
    DivComponent,
    FeatureViewComponent,
    ImageComponent,
    JavascriptViewComponent,
    OneColTextViewComponent,
    TextViewComponent,
    TitleViewComponent,
    TextFormTextInlineComponent,
    CreateNewBlockComponent
  ],
  providers: []
})
export class MultiblockViewModule {
}
