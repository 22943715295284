import { HttpClient, HttpErrorResponse } from "@angular/common/http"
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core"
import { throwError, ObservableInput, Subscription, combineLatest, shareReplay, Observable } from "rxjs"
import { AnalyticsService } from "@aaa-web/app/core/services/analytics.service"
import { AngularFirestore } from "@angular/fire/firestore"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { map } from "rxjs/operators"
import {
  OfficeAgent, AgentOrderMode, OfficeAgents, OfficeAgentsService, ParamsObj, AgentTypeOfficeAgents
} from "@aaa-web/app/modules/office-agents/office-agents.service"
import { OfficeInterface } from "@aaa-web/app/core/interfaces/office.interface"
import { AgentInterface } from "@aaa-web/app/core/interfaces/agent.interface"
import { AgentTypeInterface } from "@aaa-web/app/core/interfaces/agent-type.interface"

@Component({
  selector: "aaa-office-agents",
  templateUrl: "./office-agents.component.html",
  styleUrls: ["./office-agents.component.scss"],
})
export class OfficeAgentsComponent implements OnInit, OnDestroy {
  id: string
  @Input() officeId: string
  @Input() params: string
  paramsObj: ParamsObj
  window: MetaWindow
  officeAgentsSubscription: Subscription
  officeAgents$: Observable<OfficeAgents>

  private static getSortFunction(mode: AgentOrderMode): ((a: OfficeAgent, b: OfficeAgent) => number) {
    switch (mode) {
      default:
      case AgentOrderMode.RANDOM:
      //TODO - set some weight prop (randomly) earlier and sort by it here
      case AgentOrderMode.ALPHA:
        return (a: OfficeAgent, b: OfficeAgent) => {
          const lastNameComp = a.lastNames[0].localeCompare(b.lastNames[0])
          if (lastNameComp !== 0) return lastNameComp
          return a.firstNames[0].localeCompare(b.firstNames[0])
        }
    }
  }

  constructor(
    private afs: AngularFirestore,
    private httpClient: HttpClient,
    private analyticsService: AnalyticsService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    public officeAgentsService: OfficeAgentsService,
    private officeInterface: OfficeInterface,
    private agentInterface: AgentInterface,
    private agentTypeInterface: AgentTypeInterface,
    private domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  ngOnInit(): void {
    this.id = this.afs.createId()

    if (this.params) {
      this.paramsObj = JSON.parse(this.params)
    }

    this.officeId = this.officeId || this.paramsObj?.options?.officeId

    if (this.officeId) {
      this.officeAgents$ = combineLatest([this.agentInterface.agents$, this.agentTypeInterface.agentTypes$])
        .pipe(
          shareReplay(),
          map(([agents, agentTypes]) => {
            agents = (agents as OfficeAgent[]).reduce((accumulator: OfficeAgent[], agent: OfficeAgent) => {
              if (agent.routePath && agent.status === "1" && agent.offices.find(office => office.nid === this.officeId)) {
                agent.types.forEach(type => {
                  agent.agentTypeId = type.tid
                  accumulator = [ agent, ...accumulator ]
                })
              }
              return accumulator
            }, [])

            return {
              agents: agents,
              agentTypes: agentTypes
            }
          })
        )

      this.officeAgentsSubscription = this.officeAgents$
        .subscribe(officeAgents => {
          const paramsAgentOrderMode = this.paramsObj?.options?.agentOrderMode || "RANDOM"
          const agentOrderMode: AgentOrderMode = AgentOrderMode[paramsAgentOrderMode]

          this.officeAgentsService.templateData[this.id] = officeAgents.agentTypes
            .map(agentType => {
              return {
                agentType: agentType,
                agents: officeAgents.agents
                  .filter(agent => agent.agentTypeId === agentType.id)
                  .sort(OfficeAgentsComponent.getSortFunction(agentOrderMode)),
              }
            })
            .sort((a, b) => {
              const weightDiff: number = parseInt(a.agentType.weight, 10) - parseInt(b.agentType.weight)

              if (weightDiff !== 0) {
                return weightDiff
              } else {
                return a.agentType.officeHeading?.localeCompare(b.agentType.officeHeading)
              }

            })
            .filter(c => c.agents?.length > 0) as AgentTypeOfficeAgents[]
        })



      // return

      // const domain = environment.ngServe || environment.emulator ? "https://www.colorado.aaa.com" : ""
      const url = "https://test.southjersey.aaa.com/data/office-agents/" + this.officeId
      // const url = this.window.metaData.origin || "" + "/data/office-agents/" + this.officeId

/*
      this.httpClient
        .get(url)
        .pipe(
          retry(3),
          catchError(this.handleError),
        )
        .subscribe((data: OfficeAgents) => {
          console.log(url)
          console.log(data)
          const paramsAgentOrderMode = this.paramsObj?.options?.agentOrderMode || "RANDOM"
          const agentOrderMode: AgentOrderMode = AgentOrderMode[paramsAgentOrderMode]

          this.officeAgentsService.templateData[this.id] = data.agentTypes
            .map(agentType => {
              return {
                agentType: agentType,
                agents: data.agents
                  .filter(agent => agent.agentTypeId === agentType.id)
                  .sort(OfficeAgentsComponent.getSortFunction(agentOrderMode)),
              }
            })
            .sort((a, b) => {
              const weightDiff: number = parseInt(a.agentType.weight, 10) - parseInt(b.agentType.weight)

              if (weightDiff !== 0) {
                return weightDiff
              } else {
                return a.agentType.officeHeading?.localeCompare(b.agentType.officeHeading)
              }

            })
            .filter(c => c.agents?.length > 0) as AgentTypeOfficeAgents[]

          // this.setProperty('showElements', '1')
        })
*/
    }


    /**
     * disabling properties overrides, retaining as comments for reference
     */
    /*
        this.backoffOnInit()

        this.propertiesDoc = {}
        this.propertiesRef = this.afs
          .collection('wss-aaa-web')
          .doc(this.metaData.clubId)
          .collection('apps')
          .doc('office-agents')
          .collection('local')
          .doc('properties')
        this.properties$ = this.propertiesRef
          .valueChanges()
        this.propertiesSubscription = this.properties$
          .subscribe(propertiesDoc => {
            /!**
             * Provide initial data to jsonEditorService.
             * Only resend subsequent data changes if a jsonDataReset() occurs.
             *!/
            if (!this.initialized) {
              this.initialized = true
              this.jsonEditorService.properties = propertiesDoc
            }
            /!**
             * Make propertiesDoc so that resize observer can process breakpoint data as needed.
             *!/
            this.propertiesDoc = propertiesDoc
            this.processUI()
          })

        if (this.metaData.user.id === '1') {
          this.enableEditMode()
        }
    */

    /**
     * Process content from params and the office-agents data view.
     */
    // this.officeAgentsSubject = new BehaviorSubject<OfficeAgents>(undefined)
    // this.officeAgents$ = this.officeAgentsSubject.asObservable()

    /**
     * Send Analytics event.
     *
    const eventCustomElement: AnalyticsEvent = {
      eventCategory: "Custom Element",
      eventAction: "Supported",
      eventLabel: "<aaa-office-agents>" + this.window.location.origin + this.window.location.pathname,
      eventValue: 1,
    }
    this.analyticsService.sendEvent(eventCustomElement)
    /**/

    /*
        this.resizeSubscription = this.jsonEditorService.resizeObservable$
          // .pipe(debounceTime(200))
          .subscribe( () => {
            this.processUI()
          })
    */
/*
    this.officeAgentsSubscription = this.officeAgents$
      .subscribe(params => {
        this.templateParams = params
        if (!params) return
        const agentOrderMode = params.agentOrderMode || AgentOrderMode.RANDOM
        const templateData = params.agentTypes
          .map(agentType => {
            return {
              agentType: agentType,
              agents: params.agents
                .filter(agent => agent.agentTypeId === agentType.id)
                .sort(OfficeAgentsComponent.getSortFunction(agentOrderMode)),
            }
          })
          .sort((a, b) => {
            const weightDiff: number = a.agentType.weight - b.agentType.weight

            if (weightDiff !== 0) {
              return weightDiff
            } else {
              return a.agentType.officeHeading?.localeCompare(b.agentType.officeHeading)
            }

          })
          .filter(c => c.agents?.length > 0)
      })
*/
  }

  ngOnDestroy(): void {
    this.officeAgentsSubscription?.unsubscribe()
  }

  handleError(error: HttpErrorResponse): ObservableInput<never> {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message)
    } else {
      console.log("An different error occurred:")
      console
        .error("Backend returned code " + error.status, "body was: " + error.error)
    }
    return throwError("Something bad happened; please try again later.")
  }

  /*
    backoffOnInit(x = 1, y = 10) {
      if (x > 60 * 1000) return // one minute kill switch
      setTimeout(() => {
        this.backoffOnInit(x * 2)
        this.processUI()
      }, x * y)
    }
  */

  /*
    enableEditMode() {
      this.propertiesEdit$ = this.jsonEditorService.properties$
      this.propertiesEditSubscription = this.propertiesEdit$
        .subscribe(propertiesDoc => {
          if (propertiesDoc) {
            let cleanData = true
            Object.keys(propertiesDoc).forEach(breakPoint => {
              if (!breakPoint) {
                cleanData = false
              }
              Object.keys(propertiesDoc[breakPoint]).forEach(elementId => {
                Object.keys(propertiesDoc[breakPoint][elementId]).forEach(type => {
                  Object.keys(propertiesDoc[breakPoint][elementId][type]).forEach(style => {
                    if (!style || !propertiesDoc[breakPoint][elementId][type][style]) {
                      cleanData = false
                    }
                  })
                })
              })
            })
            if (cleanData) {
              this.propertiesRef
                .set(propertiesDoc)
                .then()
                .catch()
            }
          }
        })
    }
  */

  /*
    processUI() {
      /!**
       * Loop over breakpoints and build a single properties definition for the template.
       *!/
      if (this.propertiesDoc) {
        Object.keys(this.propertiesDoc).sort().forEach(breakPoint => {
          if (parseInt(breakPoint, 10) < window.innerWidth) {
            Object.keys(this.propertiesDoc[breakPoint]).forEach(elementId => {
              Object.keys(this.propertiesDoc[breakPoint][elementId]).forEach(key => {
                this.properties = this.properties || {}
                this.properties[elementId] = this.properties[elementId] || {}
                this.properties[elementId][key] = this.propertiesDoc[breakPoint][elementId][key]
              })
            })
          }
        })
      }
      /!**
       * Assign this.ui json data values to the corresponding template elements.
       *!/
      if (this.properties) {
        if (this.properties.global) {
          Object.keys(this.properties.global).forEach(key => {
            const value = this.properties.global[key]
            this.elementRef.nativeElement.style.setProperty('--' + key, value)
          })
        }
        this.properties = Object.keys(this.properties).forEach(elementId => {
          if (elementId === 'globalProperties') return
          Object.keys(this.properties[elementId]).forEach(key => {
            const value = this.properties[elementId][key]
            this.elementRef.nativeElement.style.setProperty('--' + elementId + '-' + key, value)
          })
        })
        this.setProperty('showElements', '1')
        this.setProperty('showTools', '1')
      }
    }
  */

/*
  setProperty(property: string, value: string): void {
    this.elementRef.nativeElement.style.setProperty("--" + property, value)
  }
*/

  /*
    initializeJsonData() {
      this.initialized = false
      this.jsonEditorService.propertiesSubject.next({
        "0": {
          "global": {
            "transition-in": ".3s",
            "transition-out": ".6s"
          },
          "button": {
            "border-radius": "4px",
            "box-sizing": "border-box",
            "cursor: var": "pointer",
            "display: var": "inline-block",
            "font-family": "proxima_nova, sans-serif",
            "font-size": "16px",
            "font-weight": "400",
            "line-height": "36px",
            "min-width": "64px",
            "outline: var": "initial",
            "overflow: var": "visible",
            "padding: var": " 16px",
            "position: var": "relative",
            "text-align": "center",
            "text-decoration": "none",
            "text-transform": "uppercase",
            "user-select": "none",
            "vertical-align": "baseline",
            "white-space": "nowrap",
            "background-color": "#00a0df",
            "border": "solid 3px #00a0df",
            "color": "#fff",
            "focus-border-color": "#036ecd",
            "focus-color": "#fff",
          },
          "button-1": {
            "background-color": "#00a0df",
            "border": "solid 3px #00a0df",
            "color": "#fff",
            "focus-color": "#036ecd",
          },
          "button-2": {
            "background-color": "#fff",
            "border": "solid 3px #00a0df",
            "color": "#00a0df",
            "focus-color": "#036ecd",
          },
          "heading": {
            "font-family": "proxima_nova, sans-serif",
            "font-size": "1.75rem",
            "font-weight": "400",
          },
          "list": {
            "margin": "0 -20px 20px -20px",
            "display": "flex",
            "flex-direction": "row",
            "flex-wrap": "wrap",
            "justify-content": "start",
          },
          "agents": {
            "padding": "30px 0 0 ",
          },
          "agent": {
            "background": "#fff",
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "box-sizing": " border-box",
            "margin": "20px 20px 10px 10px",
            "padding": "10px",
            "width": "300px",
            "transition": "box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)",
            "box-shadow": "0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)",
          },
          "link": {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "width": "100%",
            "position": "relative",
          },
          "icon": {
            "fill": "#666",
            "top": "0px",
            "right": "0px",
            "size": "20px",
            "hover-size": "30px",
          },
          "photo": {
            "width": "120px",
            "height": "120px",
            "border":	"solid 3px #ccc",
            "border-radius": "60px",
            "background-repeat": "no-repeat",
            "background-position": "top",
            "background-size": "cover",
          },
          "name": {
            "font-family": "proxima_nova, sans-serif",
            "font-size": "18px",
            "font-weight": "500",
            "color": "#333",
            "margin-top": "10px",
          },
          "bookAppointment": {
            "margin": "10px 0px 0px",
            "order": "1",
            "width": "100%",
          },
          "travelQuote": {
            "margin": "10px 0px 0px",
            "order": "1",
            "width": "100%",
          },
          "insurance": {
            "margin": "10px 0px 0px",
            "order": "1",
            "width": "100%",
          },
          "phone": {
            "margin": "10px 0px 0px",
            "order": "1",
            "width": "100%",
          },
          "separator": {
            "width": "100%",
            "border-bottom": "solid 1px #ccc"
          },
        },
        "544": {
          "heading": {
            "font-size": "2rem",
          },
        },
        "768": {},
        "992": {},
        "1200": {},
      })
    }
  */
}
