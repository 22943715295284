import { NgModule } from "@angular/core"
import { NZ_ICONS, NzIconModule } from "ng-zorro-antd/icon"

import {
  AlignCenterOutline,
  AlignLeftOutline,
  AlignRightOutline,
  CaretDownOutline,
  CaretUpOutline,
  CheckOutline,
  CloseCircleOutline,
  CloseCircleTwoTone,
  CloseOutline,
  ColumnHeightOutline,
  CopyFill,
  CopyOutline,
  DeleteFill,
  DeleteOutline,
  DownOutline,
  DragOutline,
  EditOutline,
  ExperimentFill,
  InfoCircleFill,
  InfoCircleOutline,
  InfoCircleTwoTone,
  Loading3QuartersOutline,
  MenuOutline,
  MinusCircleOutline,
  PaperClipOutline,
  PlusCircleOutline,
  PlusOutline,
  SaveOutline,
  UndoOutline,
  UpOutline,
  UserOutline,
} from "@ant-design/icons-angular/icons"

const icons = [
  AlignCenterOutline,
  AlignLeftOutline,
  AlignRightOutline,
  CaretDownOutline,
  CaretUpOutline,
  CheckOutline,
  CloseCircleOutline,
  CloseCircleTwoTone,
  CloseOutline,
  ColumnHeightOutline,
  CopyFill,
  CopyOutline,
  DeleteFill,
  DeleteOutline,
  DownOutline,
  DragOutline,
  EditOutline,
  ExperimentFill,
  InfoCircleFill,
  InfoCircleOutline,
  InfoCircleTwoTone,
  Loading3QuartersOutline,
  MenuOutline,
  MinusCircleOutline,
  PaperClipOutline,
  PlusCircleOutline,
  PlusOutline,
  SaveOutline,
  UndoOutline,
  UpOutline,
  UserOutline,
]

@NgModule({
  imports: [
    NzIconModule
  ],
  exports: [
    NzIconModule
  ],
  providers: [
    {
      provide: NZ_ICONS,
      useValue: icons
    }
  ]
})
export class IconModule {
}
