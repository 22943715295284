import {
  Component, OnInit, ViewChild, AfterViewInit, Input, HostListener, ElementRef, ChangeDetectorRef
} from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { MapInfoWindow, GoogleMap, MapMarker } from "@angular/google-maps"
import { environment } from "@aaa-web/environments/environment"
import { FormService } from "../../../services/form.service"
import { FormControl } from "@angular/forms"
import { Agent, FormValues, Office } from "@aaa/interface/agent-scheduler"

interface Marker {
  position: google.maps.LatLngLiteral
  options: google.maps.MapOptions
  title: string
  info: {
    title: string
    addr: string
    agents: string
    officeId: string
  }
}

@Component({
  selector: "aaa-agents-list",
  templateUrl: "./agents-list.component.html"
})
export class AgentsListComponent implements OnInit, AfterViewInit {
  @Input() formId: string
  @ViewChild("container") container: ElementRef
  containerWidth: number

  agents: Agent[]
  agentOfficeIds: string[] = []
  filteredAgents: Agent[]
  showingAll: boolean = true
  filterName: string = ""

  @ViewChild(MapInfoWindow, { static: false }) mapInfoWindow: MapInfoWindow
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    zoom: 10,
  }
  defaultMarkerOptions: google.maps.MarkerOptions = {
    // animation: google.maps.Animation.DROP,
    draggable: false,
    icon: {
      path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
      fillColor: "#00a0df",
      fillOpacity: 1,
      strokeColor: "#000",
      strokeWeight: 2,
      scale: 1
    },
    zIndex: undefined
  }
  selectedMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
      fillColor: "red",
      fillOpacity: 1,
      strokeColor: "#000",
      strokeWeight: 2,
      scale: 1
    },
    zIndex: 999
  }
  markers: Marker[] = []
  center
  initialBounds: google.maps.LatLngBounds
  infoData = {
    title: "",
    addr: "",
    agents: "",
    officeId: ""
  }
  formValues: FormValues

  constructor(
    private sms: StateMachineService,
    public formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.formValues = this.formService.formValues[this.formId]
    const agentFlowEmail = this.formValues.overrides.agentEmail || false

    if (agentFlowEmail) {
      this.agent.setValue(this.formValues.agents.find(a => a.email.toLocaleLowerCase() === agentFlowEmail.toLocaleLowerCase()))
      this.agents = [this.agent.value]
    } else {
      this.agents = this.formValues.agents
        .filter(agent => !this.formValues.topics ? true : (agent.topicIds.some(id => this.formValues.topics.some(t => t.id === id))))
        .filter(agent => !this.formValues.regions ? true : (agent.regionIds.some(id => this.formValues.regions.some(r => r.id === id))))
        .filter(agent => !this.formValues.meetings ? true : (agent.meetingIds.some(id => this.formValues.meetings.some(m => m.id === id))))
    }
    this.filteredAgents = this.agents
    const agentsOffices = {}
    this.agents.forEach(agent => {
      agent.officeIds.forEach(agentOfficeId => {
        agentsOffices[agentOfficeId] = agentOfficeId
      })
    })
    this.agentOfficeIds = Object.values(agentsOffices)

    if (this.agentOfficeIds.length) {
      this.center = this.officeToLatLng(this.formValues.offices.find(office => office.id === this.agentOfficeIds[0]))
    }

    this.initialBounds = new google.maps.LatLngBounds()
    this.agentOfficeIds.forEach(agentOfficeId => {
      const agentOffice: Office = this.formValues.offices.find(office => office.id === agentOfficeId)
      const officePnt = this.officeToLatLng(agentOffice)
      const agentsCount: number = this.formValues.agents.reduce((counter: number, agent: Agent) => {
        if (agent.officeIds.find(officeId => officeId === agentOfficeId)) {
          return counter + 1
        }
        return counter
      }, 0)
      this.markers.push({
        position: officePnt,
        title: agentOffice.name,
        options: this.defaultMarkerOptions,
        info: {
          title: agentOffice.name,
          addr: agentOffice.addrLine1 + "\n" + agentOffice.addrLine2,
          agents: agentsCount + " agent" + (agentsCount !== 1 ? "s" : ""),
          officeId: agentOffice.id
        },
      })
      this.initialBounds.extend(officePnt)
    })
  }

  ngAfterViewInit(): void {
    this.recenterMap()
    this.onResize()

    if (environment.ngServe) {
      // this.sendEvent(this.formValues.agents.find(agent => agent.fName === "Kermit"))
    }
  }

  recenterMap():void {
    if (this.agentOfficeIds.length > 1) {
      this.map.fitBounds(this.initialBounds)
    } else {
      this.map.center = (this.center)
      this.map.zoom = 13
    }
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container.nativeElement.offsetWidth
    this.changeDetectorRef.detectChanges()
  }

  sendEvent(agent: Agent): void {
    this.agent.setValue(agent)
    this.sms.sendEvent("NEXT")
  }

  get agent(): FormControl {
    return this.formService.form[this.formId].get("agent") as FormControl
  }

  agentOffice(officeId: string): string {
    return this.formValues.offices.find(office => office.id === officeId)?.name
  }

  /*
    mapClick(event: Event): void {
      if (!event["noClick"]) {
        // disabled this close method, it was firing along with the open() method, thereby disabling the popup entirely
        // this.info.close();
      }
    }
  */

  openInfo(markerElement: MapMarker, marker: Marker): void {
    this.infoData = {
      title: marker.info.title,
      addr: marker.info.addr,
      agents: marker.info.agents,
      officeId: marker.info.officeId
    }
    this.mapInfoWindow.open(markerElement)
  }

  resetFilter(): void {
    this.showingAll = true
    this.filteredAgents = this.agents
    for (const marker of this.markers) {
      marker.options = this.defaultMarkerOptions
    }
    this.recenterMap()
  }

  filter(officeId: string): void {
    // console.log(officeId)
    // console.log(this.agentOffices)
    this.showingAll = false
    this.filterName = this.formValues.offices.find(office => office.id === officeId).name
    // this.mapMarkers.find(m => m.info.officeId === officeId).options = this.selectedMarkerOptions;
    for (const marker of this.markers) {
      if (marker.info.officeId === officeId) {
        marker.options = this.selectedMarkerOptions
      } else {
        marker.options = this.defaultMarkerOptions
      }
    }
    this.filteredAgents = this.agents.filter(agent => agent.officeIds.some(agentOfficeId => agentOfficeId === officeId))
    this.mapInfoWindow.close()
  }

  officeToLatLng(office: Office): { lat: number, lng: number } {
    return {
      lat: +office.geoLoc[0],
      lng: +office.geoLoc[1]
    }
  }

  /*
    getCircularReplacer = () => {
      const seen = new WeakSet()
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return
          }
          seen.add(value)
        }
        return value
      }
    }
  */

}
