import { Component, Input, OnInit } from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { FormService } from "../../../services/form.service"
import { FormValues } from "@aaa/interface/agent-scheduler"

@Component({
  selector: "aaa-confirmation",
  templateUrl: "./confirmation.component.html"
})
export class ConfirmationComponent implements OnInit {
  @Input() formId: string
  formValues: FormValues
  showConfirmation: boolean

  constructor(
    private sms: StateMachineService,
    private formService: FormService,
  ) {
  }

  ngOnInit(): void {
    this.formValues = this.formService.formValues[this.formId]
  }

  cancelButton(): void {
    if (this.formValues.overrides.appointmentId) {
      this.showConfirmation = true
    }
  }

  handleCancel(): void {
    this.showConfirmation = false
  }

  handleOk(): void {
    this.sms.sendEvent("RESTART")
    this.showConfirmation = false
  }
}
