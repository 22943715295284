import { Injectable } from "@angular/core"
import { ElementOption } from "@aaa-web/app/modules/multiblock/services/style.service"
import { FormControl, FormGroup } from "@angular/forms"

export interface Text {
  element: ElementOption
  value: string
}

export interface TextOption {
  element: ElementOption[]
  value: true
}

export interface TextOptions {
  blockOptions?: {
    alignment?: boolean
    theme?: boolean
  }
  newFormFunction: (item: Text) => FormGroup
  newFormItemFunction: () => Text
  options: TextOption[]
  combinedForm: boolean
  formArray: {
    min: number
    max: number
  }
  label: {
    plural: string,
    singular: string
  }
  placeholder: string
}

@Injectable({
  providedIn: "root"
})
export class TextService {

  get newText(): Text {
    return {
      element: undefined,
      value: "",
    }
  }

  newForm(item: Text): FormGroup {
    return new FormGroup({
      element: new FormControl(item.element),
      value: new FormControl(item.value),
    })
  }

}
