import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplace'
})
export class StringReplacePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {

    /**
     * Replace any number of strings that match any number of occurrences.
     * example format:
     * {{ value | stringReplace: ['find', 'replace'] : ['find', 'replace'] : ['find', 'replace'] }}
     */
    args.forEach(arg => {
      value = value.replace(new RegExp(arg[0], 'g'), arg[1])
    })

    return value
  }

}
