import { Injectable } from "@angular/core"
import { FormOptionsBase, BlockBase } from "../../services/block.service"
import { BlockStateBase } from "@aaa-web/app/modules/multiblock/services/state.service"
import { FormArray, FormControl, FormGroup } from "@angular/forms"
import {
  AlignmentOption, ElementStyles, MarginOption, PaddingOption, ThemeOption
} from "@aaa-web/app/modules/multiblock/services/style.service"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { PublishedOption } from "@aaa-web/app/modules/multiblock/blocks/settings.service"
import { Text, TextOptions } from "@aaa-web/app/modules/multiblock/elements/text/text.service"
import { ColorService, ColorTypes } from "@aaa-web/app/modules/multiblock/services/color.service"
import { TokenService } from "@aaa-web/app/core/services/token.service"

export interface Block extends BlockBase {
  fields: {
    titles: Text[]
    subtitles: Text[]
  }
}

export interface FormOptions extends FormOptionsBase {
  fields?: {
    titles: TextOptions
    subtitles: TextOptions
  }
}

export interface BlockState extends BlockStateBase {
  formOptions?: FormOptions
}

export interface FormDisplayOptions {
  fields?: {
    titles?: boolean | "form"
    subtitles?: boolean | "form"
  }
  options?: {
  }
}

@Injectable({
  providedIn: "root"
})
export class TitleService {
  window: MetaWindow

  constructor(
    private colorService: ColorService,
    private tokenService: TokenService,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  titleStyles(block: Block, width: number): ElementStyles {
    return {
      color: block.options.theme === 'blue' ? this.colorTypes['white'].rgba : this.colorTypes['black'].rgba,
      fontSize: {
        px: 32
      },
      fontWeight: 400,
    }
  }

  get titleOptions(): TextOptions {
    const options: TextOptions = {
      newFormFunction: this.newTitleForm,
      newFormItemFunction: this.newTitle,
      options: [],
      combinedForm: true,
      label: {
        plural: "Titles",
        singular: "Title"
      },
      placeholder: "Enter title here",
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: ["div", "h1", "h2", "h3"],
        value: true
      })
    }
    return options
  }

  subtitleStyles(block: Block, width: number): ElementStyles {
    return {
      color: block.options.theme === 'blue' ? this.colorTypes['white'].rgba : this.colorTypes['black'].rgba,
      fontSize: {
        px: width > 1199 ? 32 : width > 991 ? 32 : 32
      },
      fontWeight: 400,
    }
  }

  get subtitleOptions(): TextOptions {
    const options: TextOptions = {
      newFormFunction: this.newSubtitleForm,
      newFormItemFunction: this.newSubtitle,
      options: [],
      combinedForm: true,
      label: {
        plural: "Subtitles",
        singular: "Subtitle"
      },
      placeholder: "Enter subtitle here",
      formArray: {
        min: 0,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get formDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        subtitles: "form",
      },
      options: {
      }
    }
  }

  get titlesFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        subtitles: "form",
      },
      options: {
      }
    }
  }

  get subtitlesFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        titles: "form",
        subtitles: "form"
      },
      options: {
      }
    }
  }

  get themeOptions(): ThemeOption[] {
    return ["white", "gray"]
  }

  get alignmentOptions(): AlignmentOption[] {
    return ["left", "center"]
  }

  get marginOptions(): MarginOption[] {
    return ["none", "thin", "large"]
  }

  get paddingOptions(): PaddingOption[] {
    return ["none", "thin", "large"]
  }

  get publishedOptions(): PublishedOption[] {
    return ["published", "unpublished", "password"]
  }

  get colorTypes(): ColorTypes {
    return this.colorService.colorTypes
  }

  newTitle = (): Text => {
    return {
      element: "h1",
      value: "",
    }
  }

  newSubtitle = (): Text => {
    return {
      element: "h2",
      value: "",
    }
  }

  get newBlock(): Block {
    return {
      blockType: "title",
      id: "title",
      fields: {
        titles: [this.newTitle()],
        subtitles: [],
      },
      options: {
        alignment: "center",
        marginBottom: "none",
        marginTop: "none",
        paddingBottom: "thin",
        paddingTop: "thin",
        theme: "white",
      },
      pathnames: [this.window.location.pathname],
      status: {
        created: Timestamp.now(),
        published: "published",
        revised: Timestamp.now()

      }
    }
  }

  newTitleForm(text: Text): FormGroup {
    return new FormGroup({
      element: new FormControl(text.element),
      value: new FormControl(text.value),
    })
  }

  newSubtitleForm(text: Text): FormGroup {
    return new FormGroup({
      value: new FormControl(text.value),
    })
  }

  newForm(block: Block): BlockState {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          titles: new FormArray(block.fields.titles.map(title => this.newTitleForm(title))),
          subtitles: new FormArray(block.fields.subtitles.map(subtitle => this.newSubtitleForm(subtitle))),
        }),
        options: new FormGroup({
          alignment: new FormControl(block.options.alignment),
          marginTop: new FormControl(block.options.marginTop),
          marginBottom: new FormControl(block.options.marginBottom),
          paddingTop: new FormControl(block.options.paddingTop),
          paddingBottom: new FormControl(block.options.paddingBottom),
          theme: new FormControl(block.options.theme),
        }),
        pathnames: new FormControl(block.pathnames),
        status: new FormGroup({
          created: new FormControl(block.status.created),
          published: new FormControl(block.status.published),
          revised: new FormControl(block.status.revised)
        })
      })
    }
  }

  get newFormOptions(): FormOptions {
    return {
      fields: {
        titles: this.titleOptions,
        subtitles: this.subtitleOptions,
      },
      options: {
        alignment: this.alignmentOptions,
        marginBottom: this.marginOptions,
        marginTop: this.marginOptions,
        paddingBottom: this.paddingOptions,
        paddingTop: this.paddingOptions,
        theme: this.themeOptions,
      },
      status: {
        published: this.publishedOptions
      }
    }
  }

  tokenize(content: string): string {
    return this.tokenService.processTokens(this.tokenService.stripHTML(this.tokenService.preprocessTokens(content)))
  }

}
