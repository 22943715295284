import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'aaa-cybersource',
  templateUrl: './cybersource.component.html'
})
export class CybersourceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
