<aaa-accordion
  *ngIf="filteredMessages.flagged"
  [title]="'Flagged Members: ' + filteredMessages.flagged.length"
  [rows]="filteredMessages.flagged"
  [component]="component.flagged"
></aaa-accordion>

<google-chart
  *ngIf="roleService.roles.administrator && chart.data.length"
  [title]="chart.title"
  [type]="chart.type"
  [data]="chart.data"
  [columns]="chart.columns"
  [options]="chart.options"
  [style]="'width: 75%'"
  [dynamicResize]="true"
></google-chart>

<aaa-accordion
  *ngIf="filteredMessages.error"
  [title]="'Connection Errors: ' + filteredMessages.error.length"
  [rows]="filteredMessages.error"
  [component]="component.error"
></aaa-accordion>


