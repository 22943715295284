<div [style]="'max-height: calc(100vh - 200px); overflow: auto'">
  <aaa-infinite-scroll (scrolled)="onScroll()">
    <div *ngFor="let message of messagesArray; let messageIndex = index">
      <div
        *ngIf="messageIndex < items"
        [style]="'padding-top: 20px'"
      >
        <div
          (click)="showMessage[message.index] = !showMessage[message.index]"
          class="clickable"
        >
          {{ message.index }}.
          {{ message.id | date : 'medium' }}
          |
          {{ message.id }}
        </div>
        <nz-modal
          [(nzVisible)]="showMessage[messageIndex]"
          (nzOnCancel)="showMessage[message.index] = false"
          (nzOnOk)="showMessage[message.index] = false"
          [nzFooter]="null"
        >
          <aaa-v3-batch-tooltip-dialog *nzModalContent [data]="message.records" [index]="message.index"></aaa-v3-batch-tooltip-dialog>
        </nz-modal>
        <div
          *ngFor="let record of message.records; let recordIndex = index"
          [style]="'padding-left: 20px; padding-top: 10px'"
        >
          <div [style]="'display: flex'">
            <div [style]="'padding-right: 20px'">
              {{ recordIndex + 1 }}.
            </div>
            <div [style]="'width: 360px'">
              <div
                *ngIf="record.label || record.label === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 120px'">
                  Label:
                </div>
                <div>
                  {{ record.label }}
                </div>
              </div>
              <div
                *ngIf="record.function || record.function === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 120px'">
                  Function:
                </div>
                <div>
                  {{ record.function }}
                </div>
              </div>
              <div
                *ngIf="record.description || record.description === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 120px'">
                  description:
                </div>
                <div>
                  {{ record.description }}
                </div>
              </div>
              <div
                *ngIf="record.class || record.class === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 120px'">
                  class:
                </div>
                <div>
                  {{ record.class }}
                </div>
              </div>
            </div>
            <div *ngIf="record.data" [style]="'padding-left: 30px'">

              <!--oauth-->
              <div
                *ngIf="record.data.association || record.data.association === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  association:
                </div>
                <div>
                  {{ record.data.association }}
                </div>
              </div>
              <div
                *ngIf="record.data.client_id || record.data.client_id === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  client_id:
                </div>
                <div>
                  {{ record.data.client_id }}
                </div>
              </div>
              <div
                *ngIf="record.data.scope || record.data.scope === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  scope:
                </div>
                <div>
                  {{ record.data.scope }}
                </div>
              </div>
              <div
                *ngIf="record.data.response_type || record.data.response_type === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  response_type:
                </div>
                <div>
                  {{ record.data.response_type }}
                </div>
              </div>
              <div
                *ngIf="record.data.email || record.data.email === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  email:
                </div>
                <div>
                  {{ record.data.email }}
                </div>
              </div>
              <div
                *ngIf="record.data.membershipNumber || record.data.membershipNumber === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  membershipNumber:
                </div>
                <div>
                  {{ record.data.membershipNumber }}
                </div>
              </div>
              <div
                *ngIf="record.data.firstName || record.data.firstName === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  firstName:
                </div>
                <div>
                  {{ record.data.firstName | json }}
                </div>
              </div>
              <div
                *ngIf="record.data.lastName || record.data.lastName === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  lastName:
                </div>
                <div>
                  {{ record.data.lastName | json }}
                </div>
              </div>

              <!--user-->
              <div
                *ngIf="record.data.uid || record.data.uid === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  uid:
                </div>
                <div>
                  <a
                    [href]="'/account/' + record.data.uid + '/edit?destination=admin/aaa/manage/accounts'"
                    target="_blank"
                  >
                    {{ record.data.uid }}
                  </a>
                </div>
              </div>
              <div
                *ngIf="record.data.userId || record.data.userId === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  userId:
                </div>
                <div>
                  <a
                    [href]="'/account/' + record.data.userId + '/edit?destination=admin/aaa/manage/accounts'"
                    target="_blank"
                  >
                    {{ record.data.userId }}
                  </a>
                </div>
              </div>
              <div
                *ngIf="record.data.memberNumber || record.data.memberNumber === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  memberNumber:
                </div>
                <div>
                  {{ record.data.memberNumber | json }}
                </div>
              </div>
              <div
                *ngIf="record.data.fullmembernumber || record.data.fullmembernumber === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  fullmembernumber:
                </div>
                <div>
                  {{ record.data.fullmembernumber }}
                </div>
              </div>
              <div
                *ngIf="record.data.mail || record.data.mail === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  mail:
                </div>
                <div>
                  {{ record.data.mail }}
                </div>
              </div>

              <div
                *ngIf="record.data.household || record.data.household === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  household:
                </div>
                <div>
                  {{ record.data.household }}
                </div>
              </div>
              <div
                *ngIf="record.data.is_registered || record.data.is_registered === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  is_registered:
                </div>
                <div>
                  {{ record.data.is_registered }}
                </div>
              </div>
              <div
                *ngIf="record.data.is_ar || record.data.is_ar === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  is_ar:
                </div>
                <div>
                  {{ record.data.is_ar }}
                </div>
              </div>
              <div
                *ngIf="record.data.data_source || record.data.data_source === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  data_source:
                </div>
                <div>
                  {{ record.data.data_source | json }}
                </div>
              </div>
              <div
                *ngIf="record.data.zip || record.data.zip === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  zip:
                </div>
                <div>
                  {{ record.data.zip | json }}
                </div>
              </div>
              <div
                *ngIf="record.data.load_method || record.data.load_method === ''"
                [style]="'display: flex'"
              >
                <div [style]="'width: 240px'">
                  load_method:
                </div>
                <div>
                  {{ record.data.load_method }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </aaa-infinite-scroll>
</div>

<button
  nz-button
  [nzType]="'primary'"
  (click)="items = messagesArray.length"
  [style]="'position: fixed; top: 180px; right: 40px'"
>
  {{ items }} of {{ messagesArray.length }}
</button>
<button
  nz-button
  [nzType]="'primary'"
  (click)="reset()"
  [style]="'position: fixed; top: 130px; right: 40px'"
>
  reset
</button>
