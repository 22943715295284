<style>
  img {
    width: 100%;
    height: auto;
  }
</style>
<!--
<pre>
  {{ file | json }}
</pre>
-->

<div *ngIf="file" [style]="'display: flex; flex-wrap: wrap; align-items: center'">
  <img [src]="file.url" [alt]="file.name" [style]="'max-width: 250px; padding-right: 20px'"/>
  <div>
    {{ file.url }}
  </div>
</div>
