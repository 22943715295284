import { Injectable } from "@angular/core"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { Router } from "@angular/router"
import { AlignmentType, TargetOption } from "@aaa-web/app/modules/multiblock/services/style.service"
import { FormControl, FormGroup } from "@angular/forms"
import { ColorOption } from "@aaa-web/app/modules/multiblock/services/color.service"
import { FormArrayOptions } from "@aaa-web/app/modules/multiblock/services/form.service"

export interface Button {
  color: ColorOption
  path: string
  target: TargetOption
  value: string
}

export interface ButtonOption {
  height: { px: number[] }
  width: { px: number[] }
  color: ColorOption[]
  target: TargetOption[]
  value: true
}

export interface ButtonOptions {
  newForm: FormGroup
  options: ButtonOption[]
  blockOptions?: Record<string, unknown> | unknown
  combinedForm: boolean
  formArray: FormArrayOptions
  formArrayPath?: (string | number)[]
  formGroupPath?: (string | number)[]
  label: {
    plural: string
    singular: string
  }
  placeholder: string
}

export interface ButtonStyles {
  justifyContent: AlignmentType["justifyContent"]
  backgroundColorClass?: string
  padding?: number
  borderRadius?: number
  fontWeight?: number
}

@Injectable({
  providedIn: "root"
})
export class ButtonService {
  window: MetaWindow

  constructor(
    private router: Router,
    private domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  get newButton(): Button {
    return {
      color: undefined,
      path: "",
      target: undefined,
      value: "",
    }
  }

  newForm(button: Button): FormGroup {
    return new FormGroup({
      color: new FormControl(button.color),
      path: new FormControl(button.path),
      target: new FormControl(button.target),
      value: new FormControl(button.value),
    })
  }

}
