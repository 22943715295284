import { Injectable } from "@angular/core"
import { ColorOption, ColorType } from "@aaa-web/app/modules/multiblock/services/color.service"

interface ElementType {
  label: string
  description: string
  element: string
}

export type ElementOption = "div" | "h1" | "h2" | "h3" | "h4" | "h5"
export type ElementTypes = Record<ElementOption, ElementType>

export interface ElementStyles {
  color: ColorType["hex"] | ColorType["rgba"]
  fontSize: {
    px: number
  }
  fontWeight: number
  lineHeight?: number
  textAlign?: AlignmentType["textAlign"]
}

export interface AlignmentType {
  label: string
  alignContent: "flex-end" | "center" | "flex-start"
  justifyContent: "flex-start" | "center" | "flex-end"
  justifySelf: "flex-start" | "center" | "flex-end"
  textAlign: "left" | "center" | "right"
  icon: {
    theme: "outline"
    type: "align-left" | "align-center" | "align-right"
  }
}

export type AlignmentOption = "left" | "center" | "right"
export type AlignmentTypes = Record<AlignmentOption, AlignmentType>

interface TargetType {
  label: string
  value: string
}

export type TargetOption = "same" | "new" | "modal"
export type TargetTypes = Record<TargetOption, TargetType>

export interface MarginType {
  label: string
  top: {
    label: string
    px: number
  }
  bottom: {
    label: string
    px: number
  }
  icon: {
    theme: "outline"
    type: "column-height"
    fontSize: {
      px: number
    }
  }
}

export type MarginOption = "none" | "thin" | "large"
export type MarginTypes = Record<MarginOption, MarginType>

export interface PaddingType {
  label: string
  top: {
    label: string
    px: number
  }
  bottom: {
    label: string
    px: number
  }
  icon: {
    theme: "outline"
    type: "column-height"
    fontSize: {
      px: number
    }
  }
}

export type PaddingOption = "none" | "thin" | "large"
export type PaddingTypes = Record<PaddingOption, PaddingType>

export interface ThemeType {
  label: string
  description: string
  color: ColorOption
}

export type ThemeOption =
  | "black"
  | "blue"
  | "gray"
  | "white"
export type ThemeTypes = Record<ThemeOption, ThemeType>

export interface PublishedType {
  label: string
  value: boolean
}

export type OptionOption = "theme" | "marginTop" | "marginBottom" | "paddingTop" | "paddingBottom"
export type OptionTypes = {
  marginTop: MarginType
  marginBottom: MarginType
  paddingTop: PaddingType
  paddingBottom: PaddingType
  theme: ThemeType
}

@Injectable({
  providedIn: "root"
})
export class StyleService {

  get alignmentTypes(): AlignmentTypes {
    return {
      center: {
        label: "Center",
        alignContent: "center",
        justifyContent: "center",
        justifySelf: "center",
        textAlign: "center",
        icon: {
          theme: "outline",
          type: "align-center",
        }
      },
      left: {
        label: "Left",
        alignContent: "flex-start",
        justifyContent: "flex-start",
        justifySelf: "flex-start",
        textAlign: "left",
        icon: {
          theme: "outline",
          type: "align-left",
        }
      },
      right: {
        label: "Right",
        alignContent: "flex-end",
        justifyContent: "flex-end",
        justifySelf: "flex-end",
        textAlign: "right",
        icon: {
          theme: "outline",
          type: "align-right",
        }
      }
    }
  }

  get marginTypes(): MarginTypes {
    return {
      large: {
        bottom: {
          label: "",
          px: 32
        },
        label: "Large",
        top: {
          label: "",
          px: 32
        },
        icon: {
          theme: "outline",
          type: "column-height",
          fontSize: {
            px: 30,
          }
        }
      },
      none: {
        bottom: {
          label: "",
          px: 0
        },
        label: "None",
        top: {
          label: "",
          px: 0
        },
        icon: {
          theme: "outline",
          type: "column-height",
          fontSize: {
            px: 10,
          }
        }
      },
      thin: {
        bottom: {
          label: "",
          px: 16
        },
        label: "Thin",
        top: {
          label: "",
          px: 16
        },
        icon: {
          theme: "outline",
          type: "column-height",
          fontSize: {
            px: 20,
          }
        }
      }
    }
  }

  get paddingTypes(): PaddingTypes {
    return {
      large: {
        bottom: {
          label: "",
          px: 48
        },
        label: "Large",
        top: {
          label: "",
          px: 48
        },
        icon: {
          theme: "outline",
          type: "column-height",
          fontSize: {
            px: 30,
          }
        }
      },
      none: {
        bottom: {
          label: "",
          px: 8
        },
        label: "None",
        top: {
          label: "",
          px: 8
        },
        icon: {
          theme: "outline",
          type: "column-height",
          fontSize: {
            px: 10,
          }
        }
      },
      thin: {
        bottom: {
          label: "",
          px: 24
        },
        label: "Thin",
        top: {
          label: "",
          px: 24
        },
        icon: {
          theme: "outline",
          type: "column-height",
          fontSize: {
            px: 20,
          }
        }
      }
    }
  }

  get elementTypes(): ElementTypes {
    return {
      div: {
        description: "",
        element: "div",
        label: "<div>"
      },
      h1: {
        description: "",
        element: "h1",
        label: "<h1>"
      },
      h2: {
        description: "",
        element: "h2",
        label: "<h2>"
      },
      h3: {
        description: "",
        element: "h3",
        label: "<h3>"
      },
      h4: {
        description: "",
        element: "h4",
        label: "<h4>"
      },
      h5: {
        description: "",
        element: "h5",
        label: "<h5>"
      }
    }
  }

  get themeTypes(): ThemeTypes {
    return {
      black: {
        description: "",
        label: "Black",
        color: "black"
      },
      blue: {
        description: "",
        label: "Blue",
        color: "darkblue"
      },
      gray: {
        description: "",
        label: "Gray",
        color: "gray"
      },
      white: {
        description: "",
        label: "White",
        color: "white"
      }
    }
  }

  get targetTypes(): TargetTypes {
    return {
      same: {
        label: "Same Window",
        value: "_self"
      },
      new: {
        label: "New Window",
        value: "_blank"
      },
      modal: {
        label: "Modal Window",
        value: "modal"
      }
    }
  }

}
