import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from "@angular/core"
import { Multiblock } from "./block.service"
import { DataService } from "./data.service"
import { BlockService } from "@aaa-web/app/modules/multiblock/services/block.service"
import { SettingsService, SettingsMode } from "@aaa-web/app/modules/multiblock/blocks/settings.service"
import { StateService } from "@aaa-web/app/modules/multiblock/services/state.service"

@Injectable({
  providedIn: "root"
})
export class BuildService {
  // private blocksCreated: {
  //   admin: { [key: string]: boolean }
  //   public: { [key: string]: boolean }
  // } = { admin: {}, public: {} }

  constructor(
    private blockService: BlockService,
    private cfr: ComponentFactoryResolver,
    private settingsService: SettingsService,
    private stateService: StateService,
    private dataService: DataService,
  ) {
  }

  createBlockViews(blockContainerRefs: ViewContainerRef[], childBlocks: Multiblock[]): void {
    blockContainerRefs.forEach((blockContainerRef: ViewContainerRef, index: number) => {
      if (childBlocks[index]) {
        this.createBlockView(blockContainerRef, childBlocks[index])
      }
    })
  }

  createBlockView(blockContainerRef: ViewContainerRef, block: Multiblock, options?: { mode: SettingsMode }): void {
    // if (block && this.blocksCreated.admin && this.blocksCreated.admin[block.id]) {
    // return
    // }
    // if (block && !this.blocksCreated.admin && this.blocksCreated.public[block.id]) {
    // return
    // }

    if (block && (this.stateService.adminMode || this.settingsService.publishedTypes[block.status.published].value)) {
      const component = this.blockService.blockTypes[block.blockType].component
      const path = this.stateService.adminMode ? component.edit : component.view
      /**
       * The following "import" argument must have
       * the beginning "../../" and the ending ".component"
       * as text so that "import" will compile.
       */
      import("../../" + path + ".component").then(component => {
        blockContainerRef.clear()
        const componentRef = blockContainerRef.createComponent(this.cfr.resolveComponentFactory(Object.values(component)[0] as Type<unknown>))
        componentRef.instance["block"] = block
        if (options) {
          Object.keys(options).forEach(option => {
            componentRef.instance[option] = options[option]
          })
        }
        // this.blocksCreated.admin[block.id] = this.stateService.adminMode ? true : this.blocksCreated.admin[block.id]
        // this.blocksCreated.public[block.id] = !this.stateService.adminMode ? true : this.blocksCreated.public[block.id]
      })
    }
  }

  loadAdminComponent(blockContainerRef: ViewContainerRef): void {
    this.unloadAdminComponent(blockContainerRef)
    import("../admin/admin.component").then(component => {
      blockContainerRef.createComponent(this.cfr.resolveComponentFactory(Object.values(component)[0] as Type<unknown>))
    })
  }

  unloadAdminComponent(blockContainerRef: ViewContainerRef): void {
    blockContainerRef?.clear()
  }

}
