import { Injectable } from "@angular/core"
import { Agent } from "@aaa-web/app/core/interfaces/agent.interface"
import { AgentType } from "@aaa-web/app/core/interfaces/agent-type.interface"

export interface OfficeAgents {
  agents: OfficeAgent[]
  agentTypes: AgentType[]
  agentOrderMode?: AgentOrderMode
}

export interface AgentTypeOfficeAgents {
  agents: OfficeAgent[]
  agentType: AgentType
}

export interface OfficeAgent extends Agent {
  agentTypeId?: string
  // bookLink: string
  // ctaLink: string
  // insuranceLink: string
  // firstName: string
  // lastName: string
  // pageLink: string
  // phone: string
  // photoUrl: string
}

export interface ParamsObj {
  options: ParamsOptions
}

export interface ParamsOptions {
  agentOrderMode: string
  officeId: string
}

export enum AgentOrderMode {
  RANDOM = "RANDOM",
  ALPHA = "ALPHA"
}

@Injectable({
  providedIn: "root"
})
export class OfficeAgentsService {
  templateData: { [key: string]: AgentTypeOfficeAgents[] } = {}
}
