import { Injectable } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import {
  AgentTimeSlot,
  AppointmentAddParams, AppointmentAddResponse, AppointmentDeleteParams, AppointmentDeleteResponse,
  AvailabilityRefreshParams, AvailabilityRefreshResponse, BookingDetails,
  Context, DocumentPath, Method, Overrides, StatusAzureAppParams
} from "@aaa/interface/agent-scheduler"
import { environment } from "@aaa-web/environments/environment"

@Injectable({
  providedIn: "root"
})
export class AzureConnectorService {
  logLevel: number = 3 //10 = everything, 0 = nothing
  overrideAgentEmail: string

  constructor(
    public http: HttpClient,
  ) {
  }

  setupService(overrides: Overrides): void {
    this.overrideAgentEmail = overrides?.agentEmail
  }

  async availabilityRefresh(clubId: string, userId: string, tenantId: string, agentEmails: string[], ignoreRateLimit?: boolean): Promise<DocumentPath[]> {

    const context: Context = {
      id: "xstate",
      userId: userId,
      clubId: clubId,
    }

    const availabilityRefreshParams: AvailabilityRefreshParams = {
      method: Method.AVAILABILITY_REFRESH,
      clubId: clubId,
      tenantId: tenantId,
      context: context,
      agentEmails: agentEmails,
      async: false,
      overrideAgentEmail: this.overrideAgentEmail,
      ignoreRateLimit: ignoreRateLimit,
    }

    this.log(5, context, "availabilityRefresh().params", availabilityRefreshParams)

    const headers = new HttpHeaders({})

    const response = await this.http.request<AvailabilityRefreshResponse>(
      "POST",
      environment.cloudFunctionsURL + "/azureConnector",
      {
        body: availabilityRefreshParams,
        headers: headers
      }
    ).toPromise()

    this.log(5, context, "availabilityRefresh().response", response)
    return response.documentPaths
  }

  async appointmentAdd(
    agentEmail: string,
    clubId: string,
    tenantId: string,
    userId: string,
    details: BookingDetails
  ): Promise<AppointmentAddResponse> {

    const context: Context = {
      id: "xstate",
      userId: userId,
      clubId: clubId,
    }

    const apptAddParams: AppointmentAddParams = {
      method: Method.APPOINTMENT_ADD,
      clubId: clubId,
      tenantId: tenantId,
      context: context,
      agentEmail: agentEmail,
      details: details,
      overrideAgentEmail: this.overrideAgentEmail
    }

    this.log(5, context, "appointmentAdd().params", apptAddParams)

    const headers = new HttpHeaders({})

    const response: AppointmentAddResponse = await this.http.request<AppointmentAddResponse>(
      "POST",
      environment.cloudFunctionsURL + "/azureConnector",
      {
        body: apptAddParams,
        headers: headers
      }
    ).toPromise()

    this.log(5, context, "appointmentAdd().response", response)
    return response
  }

  async appointmentDelete(
    clubId: string,
    tenantId: string,
    userId: string,
    appointmentId: string
  ): Promise<AppointmentDeleteResponse> {

    const context: Context = {
      id: "xstate",
      userId: userId,
      clubId: clubId,
    }

    const apptDelParams: AppointmentDeleteParams = {
      method: Method.APPOINTMENT_DELETE,
      clubId: clubId,
      tenantId: tenantId,
      context: context,
      appointmentId: appointmentId,
      overrideAgentEmail: this.overrideAgentEmail
    }

    this.log(5, context, "appointmentDelete().params", apptDelParams)

    const headers = new HttpHeaders({})

    const response: AppointmentDeleteResponse = await this.http.request<AppointmentDeleteResponse>(
      "POST",
      environment.cloudFunctionsURL + "/azureConnector",
      {
        body: apptDelParams,
        headers: headers
      }
    ).toPromise()

    this.log(5, context, "appointmentDelete().response", response)
    return response
  }


  async statusAzureApp(clubId: string): Promise<string> {
    const statusAzureParams: StatusAzureAppParams = {
      method: Method.STATUS_AZURE_APP,
      clubId: clubId
    }

    console.log("statusAzureApp starting")

    const headers = new HttpHeaders({})
    const response = await this.http.request(
      "POST",
      environment.cloudFunctionsURL + "/azureConnector",
      {
        body: statusAzureParams,
        headers: headers,
        responseType: "text"
      }
    ).toPromise()

    console.log("statusAzureApp:" + response)

    // this.log(3, null, 'statusAzureApp().response', response);

    return response
  }

  agentTimeSlotToDate(agentTimeSlot: AgentTimeSlot): Date {
    if (!agentTimeSlot) return null
    return new Date(
      agentTimeSlot.date.year,
      agentTimeSlot.date.month - 1,
      agentTimeSlot.date.day,
      agentTimeSlot.time.hour,
      agentTimeSlot.time.minute,
      0,
      0
    )
  }

  private log(level: number, context: Context, m1: string, m2?: Response | AvailabilityRefreshParams | AppointmentDeleteResponse): string {

    if (level >= this.logLevel) return

    let logMessage: string = String(level) + ": azureConnector: "
    if (!!context && !!context.id) {
      //prepend context ID
      logMessage += context.id + ": "
    }

    if (typeof m1 !== "string") {
      //stringify m1
      logMessage += JSON.stringify(m1)
    } else {
      logMessage += m1
    }

    if (m2 !== null && m2 !== undefined) {
      //stringify and append m2
      logMessage += ": "
      if (typeof m2 !== "string") {
        logMessage += JSON.stringify(m2)
      } else {
        logMessage += m2
      }
    }

    //log and return
    // -> simpleLogger?
    console.log(logMessage)
    return logMessage
  }

}
