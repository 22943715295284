import { Injectable } from "@angular/core"
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore"
import { AgentAvailability, AgentTimeSlot } from "@aaa/interface/agent-scheduler"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { Observable } from "rxjs"

@Injectable({
  providedIn: "root"
})
export class FirestoreConnectorService {
  window: MetaWindow
  availabilityRef: AngularFirestoreCollection<AgentAvailability>
  availability$: Observable<AgentAvailability[]>

  constructor(
    private afs: AngularFirestore,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
    this.availabilityRef = this.afs
      .collection("wss-aaa-web")
      .doc(this.window.metaData.clubId)
      .collection("apps")
      .doc("agent-scheduler")
      .collection("data")
      .doc("gcf")
      .collection("availability")
    this.availability$ = this.availabilityRef.valueChanges()
  }

}
