import { Component } from "@angular/core"

@Component({
  selector: "aaa-multiblock-custom-element",
  template: `
    <aaa-multiblock></aaa-multiblock>
  `
})
export class MultiblockCustomElementComponent {
}
