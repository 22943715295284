import { Injectable } from '@angular/core';
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private window: MetaWindow
  private readonly preprocessTokenMap: { [key: string]: string } = {}
  private readonly processTokenMap: { [key: string]: string } = {}

  constructor(
    private domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
    this.preprocessTokenMap = {
      "<br>":                                  "[br]",
      "<b>":                                  "[b]",
      "</b>":                                  "[/b]",
    }
    this.processTokenMap = {
      "[current-user:field_member_firstname]": this.window.metaData.user.firstName,
      "[firstname]": this.window.metaData.user.firstName,
      "[current-user:field_member_lastname]":  this.window.metaData.user.lastName,
      "[current-user:mail]":                   this.window.metaData.user.email,
      "[br]":                                  "<br>",
      "[b]":                                  "<b>",
      "[/b]":                                  "</b>",
    }
  }

  tokenize(snippet: string): string {
    return this.processTokens(this.stripHTML(this.preprocessTokens(snippet)))
  }

  preprocessTokens(snippet: string): string {
    return snippet
      .replace(
        /(<[a-z-:_/]*>)/g,
        (match, $1) => this.preprocessTokenMap[$1] || ""
      )
  }

  stripHTML(string: string): string {
    const div = document.createElement("div")
    div.innerHTML = string
    return div.textContent || ""
  }

  processTokens(snippet: string): string {
    return snippet
      .replace(
        /(\[[a-z-:_/]*\])/g,
        (match, $1) => this.processTokenMap[$1] || ""
      )
      .replace(
        /\[phone:([\d-]*)\]/g,
        (match, $1) => "<a href='tel:" + $1 + "'>" + $1 + "</a>"
      )
  }

}
