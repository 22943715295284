import { Injectable } from "@angular/core"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

export interface AnalyticsEvent {
  eventCategory: string
  eventAction: string
  eventLabel: string
  eventValue: number
}

@Injectable({
  providedIn: "root"
})
export class AnalyticsService {
  window: MetaWindow

  constructor(
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  sendPage(page: string): void {
    // // @ts-ignore
    // this.window.gtag('event', 'page_view', {
    //   page_path: page,
    //   'send_to': this.window['metaData'].gaId
    // })

    if (this.window.ga) {
      this.window.ga("wssAaaTracker.set", "page", page)
      this.window.ga("wssAaaTracker.send", "pageview")
    }
  }

  sendEvent(event: AnalyticsEvent): void {
    if (this.window.ga) {
      this.window.ga("wssAaaTracker.send", "event", event)
    }

    // // @ts-ignore
    // this.window.gtag('event', eventAction, {
    //   'event_category': eventCategory + '-1',
    //   'event_label': eventLabel,
    //   'value': eventValue,
    //   'send_to': this.window['metaData'].gaId
    // })

    // // @ts-ignore
    // this.window.gtag('event', eventAction, {
    //   'event_category': eventCategory + '-2',
    //   'event_label': eventLabel,
    //   'value': eventValue
    // })


    // console.log('sending battery event')
  }

}
