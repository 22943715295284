import { Component, OnDestroy, OnInit } from "@angular/core"
import { Observable, Subscription } from "rxjs"
import { Message, SimpleLoggerGroupDoc, SimpleLoggerMessages, SimpleLoggerService } from "../simple-logger.service"
import { map } from "rxjs/operators"
import { DocumentChangeAction } from "@angular/fire/firestore"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

@Component({
  selector: "aaa-emember-batch",
  templateUrl: "./emember-batch.component.html"
})
export class EmemberBatchComponent implements OnInit, OnDestroy {
  docs$: Observable<SimpleLoggerGroupDoc[]>
  docsSubscription: Subscription
  messages: SimpleLoggerMessages = {}
  messagesDocs: SimpleLoggerGroupDoc[]
  messagesArray: Message[] = []
  prefilteredMessagesArray: Message[] = []
  items = 40
  itemsIncrement = 10
  filters: {
    [key: string]: boolean
  } = {}
  showDetails: boolean
  oldestMessagesDocLength: number
  window: MetaWindow
  showMessage: { [key: string]: boolean } = {}

  constructor(
    private simpleLoggerService: SimpleLoggerService,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  ngOnInit(): void {
    this.docs$ = this.simpleLoggerService.ememberBatchCollectionRef
      .snapshotChanges()
      .pipe(
        map((messagesDocs: DocumentChangeAction<SimpleLoggerGroupDoc>[]) => {
          return messagesDocs.map(result => {
            if (result) {
              const data = result["payload"].doc.data()
              const id = result["payload"].doc.id
              let timestamp = ""
              Object.keys(data.messages).some(messageKey => {
                timestamp = messageKey
                return !!messageKey
              })
              return { id, timestamp, ...data }
            }
          })
        })
      )

    this.docsSubscription = this.docs$
      .subscribe(messagesDocs => {
        /**
         * Save all message docs so we can delete them in the reset() function.
         * Sort them by first timestamp in their messages list so we can delete them one at a time in chronological order.
         */
        this.messagesDocs = messagesDocs
          .sort((a, b) => parseInt(a.timestamp, 10) - parseInt(b.timestamp, 10))
        /**
         * This depends on the above sort order so that we only count the doc with the oldest records.
         */
        this.messagesDocs.some(messageDoc => {
          this.oldestMessagesDocLength = Object.keys(messageDoc.messages).length
          return true // break out of the .some loop after first iteration
        })

        /**
         * It is possible to have the same messageId across multiple docs.
         * This happens when the message is too large for a single doc and gets split across several docs.
         * We also want to convert the messages objects into an array for the template.
         */
        const processedMessagesDocs = this.simpleLoggerService.processMessagesDocs(messagesDocs, this.messages, this.prefilteredMessagesArray)
        // console.log(processedMessagesDocs.messagesArray.length)
        this.prefilteredMessagesArray = this.applyLabels(processedMessagesDocs.messagesArray)
        this.filterMessagesArray()

        /**
         * We are done using this.messages for comparison.
         * Now we replace it with new messages for comparison on next .subscribe emit
         */
        this.messages = processedMessagesDocs.messages
      })
  }

  reset(): void {
    if (this.messagesArray.length) {
      if (confirm("Are you sure you want to delete all the data on this page?")) {
        this.messagesDocs.forEach(messagesDoc => {
          const docRef = this.simpleLoggerService.ememberBatchCollectionRef.doc(messagesDoc.id)
          docRef
            .delete()
            .catch(() => {
              //
            })
        })
      }
    } else {
      alert("This page has no data to delete, it is already reset.")
    }
  }

  deleteDocs(quantity: number): void {
    if (quantity && this.messagesDocs?.length) {
      if (confirm("Confirm to delete " + this.oldestMessagesDocLength + " messages.")) {
        for (let i = 0; i < quantity; i++) {
          const docRef = this.simpleLoggerService.ememberBatchCollectionRef.doc(this.messagesDocs[i].id)
          docRef
            .delete()
            .catch(() => {
              //
            })
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.docsSubscription?.unsubscribe()
  }

  onScroll(): void {
    // console.log('onScroll()')
    this.items = this.items + this.itemsIncrement
    if (this.items > this.messagesArray.length) {
      this.items = this.messagesArray.length
    }
  }

  async loadAllItems(increment: number = 1): Promise<void> {
    if (this.messagesArray.length === this.items) {
      return
    }
    if (this.messagesArray.length - this.items < increment) {
      increment = Math.ceil(increment / 10)
      await this.loadAllItems(increment)
      return
    }
    const delay = async (ms: number) => new Promise(res => setTimeout(res, ms))
    await delay(10)
    if (this.items < this.messagesArray.length) {
      this.items = this.items + increment
      await this.loadAllItems(increment)
    }
  }

  applyLabels(messages: Message[]): Message[] {
    messages = messages.map(message => {
      message.records.map(record => {
        message.labels = message.labels || []

        if (record.data.error?.faultcode === "HTTP") {
          const index = message.labels.findIndex(label => {
            return label === "http"
          })
          if (index === -1) {
            message.labels.push("http")
          }
        }

        if (record.data.result?.responseCode === "080") {
          const index = message.labels.findIndex(label => {
            return label === "void"
          })
          if (index === -1) {
            message.labels.push("void")
          }
        }

        return record
      })
      return message
    })
    return messages
  }

  filterMessagesArray(newFilter?: string): void {
    // console.log(this.prefilteredMessagesArray.length)
    let applyFilter = false
    if (newFilter) {
      this.filters[newFilter] = !this.filters[newFilter]
    }
    // console.log(this.filters)
    let filteredMessagesArray = []
    Object.keys(this.filters).forEach(filter => {
      // console.log(filter)
      // console.log(this.filters[filter])
      if (this.filters[filter]) {
        console.log("apply filter")
        applyFilter = true

        // if (filter === 'void') {
        this.prefilteredMessagesArray.forEach(message => {
          let scanning = true
          message.labels.forEach(label => {
            if (scanning && label === filter) {
              filteredMessagesArray.push(message)
              scanning = false
            }
          })
        })
        // this.prefilteredMessagesArray.forEach(message => {
        //   const index = message.labels.findIndex(label => label === 'void')
        //   if (index !== -1) {
        //     filteredMessagesArray.push(message)
        //   }
        // })
        // }

        // if (filter === 'http') {
        //   this.prefilteredMessagesArray.forEach(message => {
        //     let scanning = true
        //     message.labels.forEach(label => {
        //       if (scanning && label === 'HTTP') {
        //         filteredMessagesArray.push(message)
        //         scanning = false
        //       }
        //     })
        //   })
        // this.prefilteredMessagesArray.forEach(message => {
        //   let scanning = true
        //   message.records.forEach(record => {
        //     if (scanning && record.data.error?.faultcode === 'HTTP') {
        //       filteredMessagesArray.push(message)
        //       scanning = false
        //     }
        //   })
        // })
        // }

      }
    })
    if (applyFilter) {
      filteredMessagesArray = filteredMessagesArray.sort((a, b) => b.id - a.id)
      /**
       * rebuild the descending index values
       */
      filteredMessagesArray = filteredMessagesArray.map((message, messageArrayIndex) => {
        message.index = filteredMessagesArray.length - messageArrayIndex
        return message
      })
      this.messagesArray = filteredMessagesArray
    } else {
      this.messagesArray = this.prefilteredMessagesArray.map((message, messageIndex) => {
        message.index = this.prefilteredMessagesArray.length - messageIndex
        return message
      })
    }

    /**
     * set the minimum number of items to display while limiting to a maximum
     */
    if (this.items < 40) {
      this.items = 40
    }
    if (this.items > this.messagesArray.length) {
      this.items = this.messagesArray.length
    }

  }

}
