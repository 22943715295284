import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { FormGroup, FormControl } from "@angular/forms"
import {
  AppMode,
  FormValues,
  Office,
  AgentType,
  BookingDetails
} from "@aaa/interface/agent-scheduler"
import { FormService } from "../../../services/form.service"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { differenceInCalendarDays } from "date-fns"

@Component({
  selector: "aaa-user-info",
  templateUrl: "./user-info.component.html"
})
export class UserInfoComponent implements OnInit, AfterViewInit {
  @Input() formId: string
  formValues: FormValues
  @ViewChild("container") container: ElementRef
  containerWidth: number
  form: FormGroup
  JSON: JSON = JSON
  gridTemplateColumns: { [key: string]: string } = {}
  agentOffice: Office
  agentTypes: AgentType[]
  window: MetaWindow
  validating: boolean

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  ngOnInit(): void {
    this.formValues = this.formService.formValues[this.formId]
    this.agentOffice = this.formValues.offices.find(office => office.id === this.agent.value.officeIds[0])
    const agentTypes = {}
    this.agent.value.typeIds.forEach(typeId => {
      agentTypes[typeId] = this.formValues.types.find(type => type.id === typeId)
    })
    this.agentTypes = Object.values(agentTypes)
  }

  get timeSlot(): FormControl {
    return this.formService.form[this.formId].get("timeSlot") as FormControl
  }

  get agent(): FormControl {
    return this.formService.form[this.formId].get("agent") as FormControl
  }

  get firstName(): FormControl {
    return this.formService.form[this.formId].get("firstName") as FormControl
  }

  get lastName(): FormControl {
    return this.formService.form[this.formId].get("lastName") as FormControl
  }

  get email(): FormControl {
    return this.formService.form[this.formId].get("email") as FormControl
  }

  get phone(): FormControl {
    return this.formService.form[this.formId].get("phone") as FormControl
  }

  get comment(): FormControl {
    return this.formService.form[this.formId].get("comment") as FormControl
  }

  get departure(): FormControl {
    return this.formService.form[this.formId].get("departure") as FormControl
  }

  get agentTimezone(): FormControl {
    return this.formService.form[this.formId].get("agentTimezone") as FormControl
  }

  formSubmit(form: FormGroup): void {
    /**
     * Validate all fields.
     */
    this.validating = true
    this.formService.form[this.formId].markAllAsTouched()
    this.ngModelChangeFirstName(form.value.firstName, true)
    this.ngModelChangeLastName(form.value.lastName, true)
    this.ngModelChangeEmail(form.value.email, true)
    this.ngModelChangePhone(form.value.phone, true)

    if (form.valid) {
      const bookingDetails: BookingDetails = {
        timeSlot: this.timeSlot.value,
        timezone: this.agentTimezone.value,
        duration: 59, //one hour - static for now
        agentType: this.formValues.appMode,
        agentInfo: {
          firstName: this.agent.value.fName,
          lastName: this.agent.value.lName,
          phone: this.agent.value.phone,
        },
        topics: this.formValues.selectedTopicIds,
        region: this.formValues.regions.find(region => region.id === this.formValues.selectedRegionId).text,
        meeting: this.formValues.meetings.find(meeting => meeting.id === this.formValues.selectedMeetingId).text,
        meetingType: this.formValues.meetings.find(meeting => meeting.id === this.formValues.selectedMeetingId).type,
        userInfo: {
          firstName: this.firstName.value,
          lastName: this.lastName.value,
          email: this.email.value,
          memberNumber: this.formValues.metaUser.memberNumber,
          phone: this.phone.value,
          departure: this.formattedDepartureRange(),
          comment: this.comment.value
        },
        location: this.formValues.offices.find(office => office.id === this.agent.value.officeIds[0]),
        shareWith: [this.formValues.shareWith.find(shareWith => shareWith.id === this.agent.value.shareWithIds[0]).email],
        appURL: this.window.location.href.split("?")[0],
        promo: this.formValues.overrides.promo
      }

      this.sms.sendEvent("NEXT", bookingDetails)
    }
  }

  formattedDepartureRange(): string {
    if (this.departure.value?.length === 2) {
      return this.departure.value[0].toLocaleString().split(",")[0] +
        " - " + this.departure.value[1].toLocaleString().split(",")[0]
    } else if (this.formValues.appMode === AppMode.TRAVEL) {
      return "not specified"
    }
    return ""
  }

  ngAfterViewInit(): void {
    this.onResize()
  }

  @HostListener("window:resize")
  onResize(): void {
    this.containerWidth = this.container.nativeElement.offsetWidth

    this.gridTemplateColumns = { "grid-template-columns": "auto" }
    if (this.containerWidth >= 700) {
      this.gridTemplateColumns = { "grid-template-columns": "210px auto" }
    }
    if (this.containerWidth >= 1080) {
      this.gridTemplateColumns = { "grid-template-columns": "210px auto 300px" }
    }
  }

  disabledDate = (current: Date): boolean => {
    // Cannot select days before today
    return differenceInCalendarDays(current, new Date) < 0
  }

  ngModelChangeFirstName(firstName: string, validating: boolean): void {
    if (validating) {
      if (!firstName || firstName.length < 1) {
        this.firstName.setErrors({ "incorrect": true })
      }
      if (!this.formService.validateIsTextString(firstName)) {
        this.firstName.setErrors({ "incorrect": true })
      }
    } else {
      this.firstName.markAsPristine()
      this.firstName.updateValueAndValidity()
    }
  }

  ngModelChangeLastName(lastName: string, validating: boolean): void {
    if (validating) {
      if (!lastName || lastName.length < 1) {
        this.lastName.setErrors({ "incorrect": true })
      }
      if (!this.formService.validateIsTextString(lastName)) {
        this.lastName.setErrors({ "incorrect": true })
      }
    } else {
      this.lastName.markAsPristine()
      this.lastName.updateValueAndValidity()
    }
  }

  ngModelChangeEmail(email: string, validating: boolean): void {
    if (validating) {
      if (!this.formService.validateIsEmail(email)) {
        this.email.setErrors({ "incorrect": true })
      }
    } else {
      this.email.markAsPristine()
      this.email.updateValueAndValidity()
    }
  }

  ngModelChangePhone(phone: string, validating: boolean): void {
    if (validating) {
      if (!this.formService.validateIsPhone(phone) || phone?.length > 14) {
        this.phone.setErrors({ "incorrect": true })
      }
    } else {
      this.phone.markAsPristine()
      this.phone.updateValueAndValidity()
    }
  }

}
