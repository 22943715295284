import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CybersourceComponent } from './cybersource.component';

@NgModule({
  declarations: [
    CybersourceComponent
  ],
  exports: [
    CybersourceComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class CybersourceModule { }
