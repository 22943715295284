import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { StateMachineService } from "./services/state-machine.service"
import { FirestoreConnectorService } from "./services/firestore-connector.service"
import { AzureConnectorService } from "./services/azure-connector.service"
import { AppMode, CFMObj, Overrides } from "@aaa/interface/agent-scheduler"
import { JwtAuthService } from "@aaa-web/app/core/services/jwt-auth.service"
import { Buffer } from "buffer"
import { Subscription } from "rxjs"
import { AnalyticsService, AnalyticsEvent } from "@aaa-web/app/core/services/analytics.service"
import { environment } from "@aaa-web/environments/environment"
import { FormService } from "@aaa-web/app/modules/agent-scheduler/services/form.service"
import { FormArray } from "@angular/forms"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"

@Component({
  selector: "aaa-agent-scheduler",
  templateUrl: "./agent-scheduler.component.html"
})
export class AgentSchedulerComponent implements OnInit, OnDestroy {
  @Input() params: string
  @ViewChild("container") container: ElementRef
  agentCount: number
  uid: number
  JSON: JSON = JSON
  formId: string

  pageChangeSub: Subscription
  environment = environment
  window: MetaWindow

  constructor(
    public acs: AzureConnectorService,
    public fcs: FirestoreConnectorService,
    public jwt: JwtAuthService,
    public sms: StateMachineService,
    private analyticsService: AnalyticsService,
    public formService: FormService,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  ngOnInit(): void {
    this.formId = "1234" // todo: generate random string
    if (this.params) {
      const paramsObj = JSON.parse(this.params)
      const appMode: AppMode = paramsObj.meta?.appMode ? paramsObj.meta.appMode : AppMode.TRAVEL

      this.agentCount = paramsObj?.agents?.length
      this.uid = paramsObj?.meta?.userId

      const urlParams = new URLSearchParams(this.window.location.search)
      let cfmObj: CFMObj = { selectedTopics: [],region: "", meeting: "", timeSlot: "", appointmentId: "" }
      const cfm = !!urlParams.get("cfm")
      if (cfm) {
        cfmObj = JSON.parse(Buffer.from(urlParams.get("cfm"), "base64").toString())
      }

      const overrides: Overrides = {
        promo: urlParams.get("promo"),
        agentEmail: urlParams.get("agentEmail")?.toLowerCase(),
        cfm: cfm,
        appointmentId: cfmObj.appointmentId
      }

      this.formService.formSetup(this.formId, paramsObj, appMode, overrides, cfmObj)
      this.setupServices(paramsObj, overrides)

      this.pageChangeSub = this.sms.pageChangeSubject
        .subscribe(async (pageChange) => {
          if (pageChange?.event !== "NEXT") return
          // https://stackoverflow.com/a/56971002
          // makes this work on FF, not exactly sure why
          await new Promise(r => setTimeout(r, 0))
          this.container.nativeElement.scrollIntoView({ behavior: "smooth" })

          if (!overrides.cfm) {
            if (pageChange.page) {
              const page = window.location.pathname + (!!overrides.agentEmail ? "/agent_flow/" : "/default_flow/") + pageChange.page
              this.analyticsService.sendPage(page)
            }

            if (pageChange.page === "confirmation") {
              const event: AnalyticsEvent = {
                eventCategory: !!overrides.agentEmail ? "travel_agent_scheduler_agent" : "travel_agent_scheduler_default",
                eventAction: "appointment",
                eventLabel: appMode.toLocaleLowerCase(),
                eventValue: 1
              }
              this.analyticsService.sendEvent(event)
            }
          }
        })
    }

    const eventCustomElement: AnalyticsEvent = {
      eventCategory: "Custom Element",
      eventAction: "Supported",
      eventLabel: "<aaa-agent-scheduler>" + window.location.origin + window.location.pathname,
      eventValue: 1
    }
    this.analyticsService.sendEvent(eventCustomElement)
  }

  ngOnDestroy(): void {
    // this.pageChangeSub?.unsubscribe()
    delete this.formService.form[this.formId]
  }

  private setupServices(paramsObj, overrides) {
    this.acs.setupService(overrides)
    // this.fcs.setupService(paramsObj.meta?.clubId, paramsObj.meta?.sessionId, paramsObj.meta?.userId)
    this.sms.setupService(this.formId)
  }

  get topics(): FormArray {
    return this.formService.form[this.formId].get("topics") as FormArray
  }

}
