import { Component, Input, OnInit } from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { FormService } from "../../../services/form.service"
import { FormArray } from "@angular/forms"
import { environment } from "@aaa-web/environments/environment"
import { AppMode, FormValues } from "@aaa/interface/agent-scheduler"

@Component({
  selector: "aaa-meeting",
  templateUrl: "./meeting.component.html"
})
export class MeetingComponent implements OnInit {
  @Input() formId: string
  formValues: FormValues
  buttonLabel: string = "Next"

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {
  }

  ngOnInit(): void {
    this.formValues = this.formService.formValues[this.formId]
    this.formValues.selectedMeetingId = ""
    if (this.formValues.appMode === AppMode.TRAVEL) {
      this.buttonLabel = 'FIND A TRAVEL AGENT'
    }
    if (this.formValues.appMode === AppMode.INSURANCE) {
      this.buttonLabel = 'FIND INSURANCE AGENT'
    }

    /**
     * If selectedMeeting is not populated then select the first meeting.id with meeting.default:true
     */
    if (!this.formValues.selectedMeetingId) {
      this.formValues.selectedMeetingId = this.formValues.meetings.find(meeting => !!meeting.default)?.id
    }

    if (environment.ngServe) {
      // this.sms.sendEvent('NEXT')
    }
  }

  get meetings(): FormArray {
    return this.formService.form[this.formId].get("meetings") as FormArray
  }

}
