import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { OfficeAgentsCustomElementComponent } from "./office-agents.component"
import { OfficeAgentsModule } from "@aaa-web/app/modules/office-agents/office-agents.module"

@NgModule({
  exports: [
  ],
  declarations: [
    OfficeAgentsCustomElementComponent,
  ],
  imports: [
    CommonModule,
    OfficeAgentsModule,
  ]
})
export class OfficeAgentsCustomElementModule {
}
