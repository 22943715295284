<div
  [style]="'padding: 10px 30px; display: flex; user-select: none; font-size: 36px; font-weight: 400;'"
  [ngClass]="{'clickable': rows?.length}"
  (click)="showRows = !showRows; loadComponents(rows)"
>
  <div>
    <svg
      [@inOutRotate]="showRows ? 'opened' : 'closed'"
      xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none"/>
      </g>
      <g>
        <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/>
      </g>
    </svg>
  </div>
  <div>
    {{ title }}
  </div>
</div>

<div
  [style]="'position: relative; overflow: hidden'"
  [@showHideRow]="showRows ? 'showRow' : 'hideRow'"
>
  <div
    [style]="'padding: 0 30px'"
    *ngFor="let row of rows; let rowIndex = index; let lastRow = last"
    [ngClass]="{'padding-bottom-10': lastRow}"
  >
    <div [style]="'padding-left: 20px'">
      <div
        (click)="toggleComponent(row, rowIndex)"
        [style]="'padding-top: 2px; padding-bottom: 2px; display: flex'"
        class="clickable"
      >
        <div>
          <svg
            [@inOutRotate]="showRow[rowIndex] ? 'opened' : 'closed'"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="18px" width="18px" fill="#000000"
          >
            <g>
              <path d="M0,0h24v24H0V0z" fill="none"/>
            </g>
            <g>
              <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/>
            </g>
          </svg>
        </div>
        <div [style]="'display: flex'">
          {{ row.index }}.
          {{ row.timestamp['seconds'] * 1000 | date: 'medium' }}
          [
          <div [style]="'padding: 0 5px'" *ngFor="let label of row.flags | keyvalue">
            {{ label.key }}
          </div>
          ]
        </div>
      </div>
      <div
        [style]="'padding-left: 30px; position: relative'"
        [@showHideRow]="showRow[rowIndex] ? 'showRow' : 'hideRow'"
      >
        <ng-container #accordionRowContainer></ng-container>
        <ng-template #accordionRow></ng-template>
      </div>

    </div>
  </div>
</div>
