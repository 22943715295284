<div #container>
  <ng-container *ngFor="let location of locations; first as first; last as last">
    <nz-divider [style]="'margin: 10px 0'" *ngIf="first"></nz-divider>
    <div
      [style]="'display: grid; align-items: center'"
      [ngStyle]="locationRowStyles"
    >
      <div [style]="'text-align: left'">
        {{ location.name }}
      </div>
      <div
        [style]="'display: grid; column-gap: 20px; grid-template-columns: 1fr 1fr'"
        [ngStyle]="buttonWrapperStyles"
      >
        <button
          nz-button nzType="primary"
          (click)="swSms.sendEvent('EDIT', location.index)"
        >
          Edit
        </button>
        <button
          nz-button nzType="primary"
          (click)="swSms.sendEvent('DELETE', location.index)"
        >
          Delete
        </button>
      </div>
    </div>
    <nz-divider [style]="'margin: 10px 0'" *ngIf="!last || true"></nz-divider>
  </ng-container>

  <div
    [style]="'display: grid'"
    [ngStyle]="addAlertRowStyles"
  >
    <div></div>
    <button
      nz-button nzType="primary"
      *ngIf="state.value !== 'cloudSyncError' && locations?.length < 5 && state.value !== 'cloudSync'"
      (click)="swSms.sendEvent('EDIT', locations?.length)"
    >
      Add New Alert
    </button>
  </div>
</div>

<nz-modal
  *ngIf="swSms.mapsLoaded"
  [(nzVisible)]="isVisible"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  [nzFooter]="null"
  nzWidth="90%"
>
  <aaa-smart-weather-location-form
    *nzModalContent
    [eventIndex]="state.event.index"
    [title]="formTitle"
  ></aaa-smart-weather-location-form>
</nz-modal>
