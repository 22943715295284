import { Injectable } from "@angular/core"
import { GetContactResponse, NotificationLocation, WriteContactResponse } from "@aaa/interface/smart-weather"
import { GcpConnectorService } from "./gcp-connector.service"
import { BehaviorSubject, Observable } from "rxjs"
import { Machine, interpret, AnyEventObject, Interpreter, State } from "xstate"
import { StateSyncService } from "./state-sync.service"
import { MapsAPILoader } from "@agm/core"

export interface InitialState {
  memberNumber: string,
  firstName: string,
  lastName: string,
}

@Injectable({
  providedIn: "root"
})
export class SwStateMachineService {
  mapsLoaded: boolean
  private stateSubject = new BehaviorSubject<any>(undefined)
  state$: Observable<any>
  editorLocation: NotificationLocation
  editorType: "edit" | "new"
  private stateMachineService: Interpreter<Record<string, unknown>, never, AnyEventObject, {
    value: never
    context: Record<string, unknown>
  }>
  private resumeFlag: boolean = false

  constructor(
    public gcpConnector: GcpConnectorService,
    public stateSync: StateSyncService,
    private mapsAPILoader: MapsAPILoader,
  ) {
    this.mapsAPILoader.load().then(() => {
      this.mapsLoaded = true
    })
    this.state$ = this.stateSubject.asObservable()
  }

  setupService(initial: InitialState): void {
    const machine = this.createMachine(initial)

    this.stateMachineService = interpret(machine)
      .onTransition(this.stateMachineTransitionFunction())
      .start() as never

    this.stateSync.state$
      .subscribe((state) => {
        // console.log(state)
        if (state) {
          const previousState = State.create(state as any)
          const resolvedState = machine.resolveState(previousState as never)

          if (resolvedState) {
            // console.log('resuming state...')
            this.resumeFlag = true
            this.stateMachineService = interpret(machine)
              .onTransition(this.stateMachineTransitionFunction())
              .start(resolvedState) as never
          }
        }
      })
  }

  sendEvent(
    event:
      "CLOUD_SYNC_ERROR" | "CLOUD_SYNC_COMPLETE" |
      "EDIT" | "DELETE" |
      "SAVE" | "CANCEL" |
      "RETRY" | "RELOAD",
    index?: number
  ): void {
    if (!this.stateMachineService) {
      console.error("Cannot sendEvent when machine is not set up. call setupService() first")
      return
    }
    const eventData = { type: event, index: index }
    // console.log('sendEvent: ' + event);
    // console.log(event)
    // console.log(eventData)
    // if (event === 'DELETE' && this.stateValue === 'location') {
    //   this.stateMachineService.send({type: 'CANCEL'})
    // }
    if (event) {
      this.stateMachineService.send(eventData)
    }
  }

  private stateMachineTransitionFunction(): (any) => void {
    return ((state) => {
      // console.log(state)
      // console.log('state: ' + JSON.stringify(state))
      this.stateSubject.next(state)
      if (this.resumeFlag) {
        this.resumeFlag = false
      } else {
        this.stateSync.pushState(state as never)
      }
    })
  }

  private createMachine = (initial: InitialState) => {
    return Machine({
      id: "sw",
      initial: "cloudSync",
      context: {
        memberNumber: initial.memberNumber,
        firstName: initial.firstName,
        lastName: initial.lastName,
        locations: undefined, // NotificationLocation[]
        newLocations: undefined, //  NotificationLocation[]
      },
      states: {
        cloudSync: {
          entry: ["cloudSync"],
          on: {
            CLOUD_SYNC_ERROR: "cloudSyncError",
            CLOUD_SYNC_COMPLETE: "list"
          }
        },
        list: {
          on: {
            EDIT: {
              actions: ["setup_editorLocation"],
              target: "location"
            },
            DELETE: {
              actions: ["delete_location"],
              target: "cloudSync"
            }
          }
        },
        location: {
          on: {
            SAVE: {
              actions: ["edit_location"],
              target: "cloudSync",
            },
            CANCEL: "list"
          }
        },
        cloudSyncError: {
          on: {
            RETRY: "cloudSync",
            RELOAD: "list"
          }
        }
      }
    }, {
      actions: {
        setup_editorLocation: (context, event) => {
          if (!context.newLocations) {
            context.newLocations = JSON.parse(JSON.stringify(context.locations ? context.locations : []))
          }
          if (context.locations !== undefined) {
            const location = context.locations[event["index"]]
            if (location) {
              this.editorType = "edit"
              this.editorLocation = JSON.parse(JSON.stringify(location))
              if (!this.editorLocation.paths.sms) {
                this.editorLocation.paths.sms = []
              }
              if (!this.editorLocation.paths.email) {
                this.editorLocation.paths.email = []
              }
              if (!this.editorLocation.categories) {
                this.editorLocation.categories = []
              }
            } else {
              this.editorType = "new"
              this.editorLocation = {
                categories: [],
                name: "",
                postal: "",
                gis: {
                  lat: undefined,
                  lon: undefined
                },
                paths: {
                  email: [],
                  sms: [],
                },
                updated: false
              }
            }
          }

        },
        delete_location: (context, event) => {
          if (!context.newLocations) {
            context.newLocations = JSON.parse(JSON.stringify(context.locations))
          }
          const newNewLocations = []
          for (let j = 0; j < context.newLocations.length; j++) {
            if (j === event["index"]) continue
            newNewLocations.push(context.newLocations[j])
          }
          context.newLocations = newNewLocations

        },
        edit_location: (context, event) => {
          this.editorLocation.updated = true
          if (context.locations?.length >= event["index"]) {
            // console.log(this.editorLocation.paths.sms)
            // console.log(this.editorLocation.paths.email)
            context.newLocations[event["index"]] = this.editorLocation
          } else {
            context.newLocations.push(this.editorLocation)
          }
        },
        cloudSync: async (context, event) => {
          /**
           * This snippet is for testing RETRY.
           * Edit a location and click SAVE.
           * Then modify the form and click RETRY.
           * add a / on the next line to enable the snippet.
           *
           if (event.type === 'SAVE') {
            this.sendEvent('CLOUD_SYNC_ERROR')
            return
          }
           /**
           * End of RETRY testing snippet
           */

          if (event.type === "RETRY") {
            // console.log(this.editorLocation)
            context.newLocations[event.index] = this.editorLocation
          }
          // console.log('cloudSync: context')
          // console.log(context)
          if (context.newLocations) {
            try {
              const writeResponse: WriteContactResponse = await this.gcpConnector
                .writeContact({
                  firstName: context.firstName,
                  lastName: context.lastName,
                  locations: context.newLocations
                }, context.memberNumber) as WriteContactResponse
              context.newLocations = undefined
              // console.log('WriteContactResponse: ' + JSON.stringify(writeResponse, null, 2))
            } catch (e) {
              // console.log(e)
              this.sendEvent("CLOUD_SYNC_ERROR")
              return
            }
          }

          try {
            const getResponse: GetContactResponse = await this.gcpConnector.getContact(context.memberNumber)
            // console.log('GetContactResponse: ' + JSON.stringify(getResponse, null, 2))
            context.locations = getResponse.contact ? getResponse.contact.locations : []
          } catch (e) {
            console.log(e)
            this.sendEvent("CLOUD_SYNC_ERROR")
            return
          }

          this.sendEvent("CLOUD_SYNC_COMPLETE")
          return
        }
      }
    })
  }
}
