import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { MultiblockComponent } from "./multiblock.component"
import { MultiblockViewModule } from "./multiblock-view.module"
import { NzToolTipModule } from "ng-zorro-antd/tooltip"
import { IconModule } from "@aaa-web/app/icon.module"
import { QuillModule } from "ngx-quill";
import { NzFormModule } from "ng-zorro-antd/form"
import { NzInputModule } from "ng-zorro-antd/input"
import { ReactiveFormsModule } from "@angular/forms"
import { NzRadioModule } from "ng-zorro-antd/radio"
import { NzButtonModule } from "ng-zorro-antd/button"
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";

@NgModule({
  declarations: [
    MultiblockComponent,
  ],
  imports: [
    CommonModule,
    MultiblockViewModule,
    NzToolTipModule,
    IconModule,
    QuillModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzRadioModule,
    NzButtonModule,
    NzPopconfirmModule,
  ],
  exports: [
    MultiblockComponent,
  ]
})
export class MultiblockModule {
}
