import { Injectable, ElementRef } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { BatteryQuoterInput } from "@aaa/interface/batteryquoter"
import { InputState, InputEvent, ComponentState } from "./interfaces/battery"
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { environment } from "@aaa-web/environments/environment"

@Injectable({
  providedIn: "root"
})
export class BatteryQuoterService {
  testMode: boolean = false
  // googleAnalyticsTrackingId: string

  // Params
  params: BatteryQuoterInput
  postUrl: string
  postOptions: {
    headers: HttpHeaders
  }

  // States
  ComponentState = ComponentState
  componentState: ComponentState
  componentStateNext: ComponentState
  componentStatePrevious: ComponentState
  InputEvent = InputEvent
  InputState = InputState
  inputButtonState: InputState

  selections: string[] = []
  steps: string[] = ["make", "model", "year", "engine"]
  labels: string[] = ["Make", "Model", "Year", "Engine"]
  options: string[][] = []
  inputStates: InputState[] = []

  buttonClicked: boolean
  dataProcessed: boolean

  // Results
  vehicle: string
  engineSize: string
  partNumber: string
  priceNonMember: string
  priceMember: string
  requestButtonUrl: string
  window: MetaWindow

  constructor(
    private http: HttpClient,
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
    if (environment.ngServe) {
      this.testMode = true
    }
    this.inputStates[0] = this.InputState.WAITING
    this.inputStates[1] = this.InputState.WAITING
    this.inputStates[2] = this.InputState.WAITING
    this.inputStates[3] = this.InputState.WAITING
    this.inputButtonState = this.InputState.WAITING
    this.componentState = this.ComponentState.FORM
    this.postUrl = "https://us-central1-avagate-wss-1.cloudfunctions.net/batteryQuoter"
    // this.postUrl = 'http://localhost:8081'
    this.postOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }
  }

  resetMake(): void {
    this.selections[0] = this.labels[0]
    this.options[0] = [this.selections[0]]
    this.resetModel()
    this.resetYear()
    this.resetEngine()
  }

  private resetModel() {
    this.selections[1] = this.labels[1]
    this.options[1] = [this.selections[1]]
    this.inputStates[1] = this.InputState.WAITING
  }

  private resetYear() {
    this.selections[2] = this.labels[2]
    this.options[2] = [this.selections[2]]
    this.inputStates[2] = this.InputState.WAITING
  }

  private resetEngine() {
    this.selections[3] = this.labels[3]
    this.options[3] = [this.selections[3]]
    this.inputStates[3] = this.InputState.WAITING
  }

  private resetButtonState() {
    this.buttonClicked = false
    this.dataProcessed = false
    this.componentStateNext = undefined
    this.componentStatePrevious = undefined
    this.inputButtonState = this.InputState.WAITING
  }

  getMakes(): void {
    this.resetModel()
    this.resetYear()
    this.resetEngine()
    this.resetButtonState()
    this.setInputState(this.InputEvent.MAKE, this.InputState.PROCESSING)
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      "url": this.params.endpoint + "/GetMakes",
      "userId": this.params.userId,
      "args": ""
    }
    this.http
      .post(
        this.postUrl,
        dataObject,
        this.postOptions
      )
      .subscribe((data) => {
          /**
           * Remove leading and trailing quotes, then split the list into an array.
           */
          data["results"].replace(/['"]+/g, "").split(",")
            .forEach(result => {
              this.options[0].push(result)
            })
          this.setInputState(this.InputEvent.MAKE, this.InputState.READY)
          // this.setFocus(this.makeElement)
        },
        error => {
          console.log(error)
        })

  }

  processSelection(step: string, args: string[]): void {
    if (step === "make") {
      this.getModels(args)
    }
    if (step === "model") {
      this.getYears(args)
    }
    if (step === "year") {
      this.getEngines(args)
    }
    if (step === "engine") {
      this.getBatteries(args)
    }
  }

  getModels(options: string[]): void {
    const [make] = options
    this.resetModel()
    this.resetYear()
    this.resetEngine()
    this.resetButtonState()
    this.setInputState(this.InputEvent.MODEL, this.InputState.PROCESSING)
    this.selections[0] = make
    this.resetResults()
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      "url": this.params.endpoint + "/GetModels",
      "userId": this.params.userId,
      "args": "" +
        "&" + "make=" + encodeURI(make)
    }
    this.http
      .post(
        this.postUrl,
        dataObject,
        this.postOptions
      )
      .subscribe(data => {
        data["results"].replace(/['"]+/g, "").split(",")
          .forEach(result => {
            this.options[1].push(result)
            this.setInputState(this.InputEvent.MODEL, this.InputState.READY)
          })
      })
  }

  getYears(options: string[]): void {
    const [make, model] = options
    this.resetYear()
    this.resetEngine()
    this.resetResults()
    this.resetButtonState()
    this.setInputState(this.InputEvent.YEAR, this.InputState.PROCESSING)
    this.selections[1] = model
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      "url": this.params.endpoint + "/GetYears",
      "userId": this.params.userId,
      "args": "" +
        "&" + "make=" + encodeURI(make) +
        "&" + "model=" + encodeURI(model)
    }
    this.http
      .post(
        this.postUrl,
        dataObject,
        this.postOptions
      )
      .subscribe(data => {
        data["results"].replace(/['"]+/g, "").split(",")
          .forEach(result => {
            this.options[2].push(result)
            this.setInputState(this.InputEvent.YEAR, this.InputState.READY)
          })
      })
  }

  getEngines(options: string[]): void {
    const [make, model, year] = options
    this.resetResults()
    this.resetEngine()
    this.resetButtonState()
    this.setInputState(this.InputEvent.ENGINE, this.InputState.PROCESSING)
    this.selections[2] = year
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      "url": this.params.endpoint + "/GetEngines",
      "userId": this.params.userId,
      "args": "" +
        "&" + "make=" + encodeURI(make) +
        "&" + "model=" + encodeURI(model) +
        "&" + "year=" + encodeURI(year)
    }
    this.http
      .post(
        this.postUrl,
        dataObject,
        this.postOptions
      )
      .subscribe(data => {
        data["results"].replace(/['"]+/g, "").split(",")
          .forEach(result => {
            this.options[3].push(result)
            this.setInputState(this.InputEvent.ENGINE, this.InputState.READY)
          })
      })
  }

  getBatteries(options: string[]): void {
    const [make, model, year, engine] = options
    this.resetButtonState()
    this.setInputState(this.InputEvent.BUTTON, this.InputState.READY)
    this.selections[3] = engine
    /**
     * Using encrypted userId for the userId to the battery quote service.
     */
    const dataObject = {
      "url": this.params.endpoint + "/GetBatteries_Standard",
      "userId": this.params.userId,
      "args": "" +
        "&" + "clubId=" + this.window.metaData.clubId +
        "&" + "zipCode=" + "0" +
        "&" + "make=" + encodeURI(make) +
        "&" + "model=" + encodeURI(model) +
        "&" + "year=" + encodeURI(year) +
        "&" + "engine=" + encodeURI(engine)
    }
    this.http
      .post(
        this.postUrl,
        dataObject,
        this.postOptions
      )
      .subscribe((data) => {
        this.processResults(data["results"])
        this.setInputState(this.InputEvent.BUTTON, this.InputState.READY)
      })
    // if (error.statusText === 'No fitments found.') {}
  }

  processResults(results: string): void {
    if (results === "") {
      this.setComponentState("dataProcessed", ComponentState.NO_RESULTS)
      return
    }
    results = JSON.parse(results)
    if (results["PartName"] === null) {
      this.setComponentState("dataProcessed", ComponentState.NO_RESULTS)
      return
    }
    this.vehicle = results["YearId"] + " " + results["MakeName"] + " " + results["ModelName"]
    this.engineSize = results["Engine"]
    this.partNumber = results["PartName"] + " " + results["CCA"] + " cold cranking amps"
    this.priceNonMember = results["NonMemberPrice"]
    this.priceMember = results["MemberPrice"]
    this.requestButtonUrl = this.params.buttonUrl +
      "?" + "club=" + this.window["metaData"].clubId +
      "&" + "year=" + results["YearId"] +
      "&" + "make=" + results["MakeName"] +
      "&" + "model=" + results["ModelName"] +
      "&" + "engine=" + results["Engine"] +
      "&" + "batterytype=" + results["PartName"] +
      "&" + "memprice=" + results["MemberPrice"] +
      "&" + "nonmemprice=" + results["NonMemberPrice"]
    this.setComponentState("dataProcessed", ComponentState.RESULTS)
  }

  resetResults(): void {
    this.vehicle = ""
    this.engineSize = ""
    this.partNumber = ""
    this.priceNonMember = ""
    this.priceMember = ""
    this.requestButtonUrl = ""
  }

  setComponentState(event: string, nextState: ComponentState, domEvent?: Event): void {
    if (domEvent) {
      domEvent.preventDefault()
    }
    if (this.componentStateNext) {
    }
    if (nextState) {
      this.componentStateNext = nextState
    }
    if (event === "buttonClicked") {
      this.buttonClicked = true
    }
    if (event === "dataProcessed") {
      this.dataProcessed = true
    }
    if (event === "buttonClicked" && !nextState && this.inputButtonState !== this.InputState.WAITING) {
      this.setInputState(this.InputEvent.BUTTON, this.InputState.PROCESSING)
    }
    if (this.buttonClicked && this.dataProcessed) {
      this.componentStatePrevious = this.componentState
      this.componentState = this.componentStateNext
      this.inputButtonState = this.InputState.INIT
    }
  }

  private setInputState(event: InputEvent, nextState: InputState) {
    if (event === this.InputEvent.MAKE) {
      this.inputStates[0] = nextState
    }
    if (event === this.InputEvent.MODEL) {
      this.inputStates[1] = nextState
    }
    if (event === this.InputEvent.YEAR) {
      this.inputStates[2] = nextState
    }
    if (event === this.InputEvent.ENGINE) {
      this.inputStates[3] = nextState
    }
    if (event === this.InputEvent.BUTTON) {
      this.inputButtonState = nextState
    }
  }

  private setFocus(element: ElementRef) {
    setTimeout(() => element.nativeElement?.focus(), 0)
  }

  /*
    scrollIntoView() {
      this.window.document.getElementById('battery-quoter-component').scrollIntoView({ behavior: 'smooth' });
    }
  */

}
