<ng-content></ng-content>
<div #anchor></div>

<!--
example usage for this component

<app-infinite-scroll (scrolled)="onScroll()">
  <div *ngFor="let content of data; let index = index">
    <div *ngIf="index < items">
      {{ content | json }}
    </div>
  </div>
</app-infinite-scroll>

  onScroll() {
    // console.log('onScroll()')
    this.items = this.items + 1
  }

-->
