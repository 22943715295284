<div [style]="'text-align: center; margin: 10px; font-size: 30px; font-weight: 400'">
  Where would you like to travel?
</div>
<div [style]="'display: grid; justify-content: center'">
  <nz-form-label
    nzFor="region"
    [style]="'visibility: hidden'"
  >
    Select Region
  </nz-form-label>
  <nz-select
    nzId="region"
    [(ngModel)]="formValues.selectedRegionId"
    [style]="'min-width: 250px'"
  >
    <nz-option
      *ngFor="let region of regions.controls; let index = index"
      [nzValue]="formValues.regions[index].id"
      [nzLabel]="formValues.regions[index].text"
    ></nz-option>
  </nz-select>
</div>
<div [style]="'display: flex; justify-content: center; margin: 10px'">
  <button
    nz-button [nzType]="'primary'"
    (click)="sms.sendEvent('NEXT'); $event.preventDefault()"
  >
    Next
  </button>
</div>
