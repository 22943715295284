<div [style]="'display: grid; justify-items: center; grid-gap: 20px'">
  <div
    [style]="'font-size: 30px; display: grid; grid-gap: 5px; align-items: center; grid-template-columns: auto auto;'"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 460"
      [style]="'height: 24px; fill: #5cb85c'"
    >
      <title>checkmark</title>
      <path d="M432 64l-240 240-112-112-80 80 192 192 320-320z"></path>
    </svg>
    <div>
      Appointment confirmed!
    </div>
  </div>

  <aaa-appointment-card [formId]="formId"></aaa-appointment-card>

  <button
    nz-button [nzType]="'primary'"
    (click)="cancelButton(); $event.preventDefault()"
  >
    Cancel Appointment
  </button>
</div>

<nz-modal
  [(nzVisible)]="showConfirmation"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
  [nzFooter]="null"
>
  <ng-template #bannerTitle>
    Cancel Appointment
  </ng-template>
  <nz-card
    *nzModalContent
    [nzTitle]="bannerTitle"
    class="blue-banner"
    [nzBodyStyle]="{'display': 'grid', 'grid-gap': '20px', 'justify-content': 'center', 'padding': '0 24px'}"
  >
    <div>
      Are you sure you want to cancel your appointment?
    </div>
    <div [style]="'display: grid; grid-template-columns: auto auto; justify-content: center; grid-gap: 20px;'">
      <button
        nz-button [nzType]="'primary'"
        [style]="'width: 120px;'"
        (click)="handleCancel()"
      >
        Back
      </button>
      <button
        nz-button [nzType]="'primary'"
        [style]="'width: 120px;'"
        (click)="handleOk()"
      >
        Confirm
      </button>
    </div>
  </nz-card>
</nz-modal>
