<div [style]="'padding-bottom: 10px'">
  <div [style]="'font-weight: 400; color: #d43f3a'">
    ! Our records indicate you have two email addresses on file.
  </div>
  <div>
    Please select the email address for your membership account, website login and email communications.
  </div>
</div>

<form [formGroup]="form">
  <div
    [style]="'font-weight: 400; padding-bottom: 5px; display: grid; gap: 8px; grid-template-columns: min-content auto; align-items: center'"
    *ngFor="let option of options.options"
  >
    <input
      type="radio" name="email" formControlName="email"
      [id]="option.value"
      [value]="option.token"
    >
    <label [for]="option.value">
      {{option.value}}
    </label>
  </div>
  <div>
    <button
      nz-button [nzType]="'primary'"
      type="submit"
      [disabled]="form.pristine"
      [nzLoading]="submitted"
      (click)="onSubmit(form.value.email); $event.preventDefault()"
    >
      Submit
    </button>
  </div>
</form>
