import { ElementRef, Injectable } from "@angular/core"
import { DataService } from "@aaa-web/app/modules/multiblock/services/data.service"
import { FormGroup } from "@angular/forms"
import { FormOptionsBase, Multiblock } from "@aaa-web/app/modules/multiblock/services/block.service"
import { Observable, BehaviorSubject } from "rxjs"
import { Block as ContainerBlock } from "@aaa-web/app/modules/multiblock/container-blocks/container/container.service"

export interface StateMachine {
  [key: string]: BlockStateBase | Record<string, never>
}

export interface BlockStateBase {
  draggable?: boolean
  draggedOverBlockId?: string
  element?: { [key: string]: { width: number } }
  elementRef?: ElementRef
  form?: FormGroup
  formOptions?: FormOptionsBase
  modal?: { [key: string]: boolean[] } // deprecated
  modalActive?: boolean
  modified?: boolean
  passwordAuth?: boolean
  selected?: boolean
  selectedField?: string
  showSettingsSidebar?: boolean
  width?: number
}

export interface State {
  [key: string]: BlockStateBase | Record<string, never>
}

@Injectable({
  providedIn: "root"
})
export class StateService {
  state: State = {}
  private stateBehaviorSubject: BehaviorSubject<State>
  state$: Observable<State>

  private adminModeBehaviorSubject: BehaviorSubject<boolean>
  adminMode$: Observable<boolean>
  adminMode: boolean
  private previewModeBehaviorSubject: BehaviorSubject<boolean>
  previewMode$: Observable<boolean>
  previewMode: boolean

  constructor(
    private dataService: DataService,
  ) {
    this.stateBehaviorSubject = new BehaviorSubject<State>({})
    this.state$ = this.stateBehaviorSubject.asObservable()

    this.dataService.blocks$
      .subscribe(blocks => {
        this.initializeState(blocks.active)
        blocks.active?.forEach(block => {
          if (!this.state[block.id]) {
          }
        })
      })
    this.state$.subscribe(state => {
      this.state = state
    })

    this.adminModeBehaviorSubject = new BehaviorSubject<boolean>(false)
    this.adminMode$ = this.adminModeBehaviorSubject.asObservable()
    this.adminMode$.subscribe(adminMode => this.adminMode = adminMode)

    this.previewModeBehaviorSubject = new BehaviorSubject<boolean>(false)
    this.previewMode$ = this.previewModeBehaviorSubject.asObservable()
    this.previewMode$.subscribe(previewMode => this.previewMode = previewMode)

  }

  initializeState(blocks: Multiblock[]): void {
    blocks?.forEach(block => {
      this.newState(block.id)
      if (block.blockType === "container") {
        (block as ContainerBlock).childBlockIds.forEach(childBlockId => this.newState(childBlockId))
      }
    })
  }

  /**
   * Add a Block to the State Object, if it does not already exist.
   */
  newState(blockId: string): void {
    this.state[blockId] = this.state[blockId] || {}
    this.stateBehaviorSubject.next(this.state)
  }

  /**
   * Sets a state[key] value for a blockId Block
   */
  updateState(blockId: string, state: BlockStateBase): void {
    Object.keys(state).forEach(key => {
      this.state[blockId] = this.state[blockId] || {}
      this.state[blockId][key] = state[key]
    })
    this.stateBehaviorSubject.next(this.state)
  }

  resetState(blockId: string, stateKey?: string): void {
    /**
     * Remove a block from the State object.
     */
    if (!stateKey) {
      delete this.state[blockId]
    }
    /**
     * Remove a key from a State Block object.
     */
    if (stateKey) {
      delete this.state[blockId][stateKey]
    }
    this.stateBehaviorSubject.next(this.state)
  }

  /**
   * Acts on all State Block objects.
   * Sets or Removes a key from all State Block Objects.
   */
  resetStateKey(stateKey: string, stateKeyValue?: number | string | boolean): void {
    /**
     * Set a key to a value for all State Block objects.
     */
    if (stateKey && stateKeyValue !== undefined) {
      Object.keys(this.state).forEach(stateBlockId => this.updateState(stateBlockId, { [stateKey]: stateKeyValue }))
    }
    /**
     * Remove a key from all State Block objects.
     */
    if (stateKey && stateKeyValue === undefined) {
      Object.keys(this.state).forEach(stateBlockId => this.resetState(stateBlockId, stateKey))
    }
  }

/*
  resetSelectedField(blockId?: string): void {
    if (!blockId) {
      Object.keys(this.state).forEach(stateBlockId => this.resetSelectedField(stateBlockId))
    }
    if (blockId && this.state[blockId].selectedField) {
      const selectedField = {}
      Object.keys(this.state[blockId].selectedField).forEach(selectedFieldKey => {
        selectedField[selectedFieldKey] = []
      })
      this.updateState(blockId, { selectedField: selectedField })
    }
  }
*/

  ngModelChange(block: Multiblock): void {
    /**
     * TODO: add a delay, maybe 1500ms.
     */
    const blockCopy = JSON.parse(JSON.stringify(block))
    delete blockCopy.id
    this.updateState(block.id, { modified: !this.deepEqual(blockCopy, this.state[block.id].form.value) })
  }

  public deepEqual(object1: unknown, object2: unknown): boolean {
    if (Object.keys(object1).length !== Object.keys(object2).length) {
      return false
    }
    for (const key of Object.keys(object1)) {
      const val1 = object1[key]
      const val2 = object2[key]
      const areObjects = (val1 != null) && (typeof val1 === "object") && (val2 != null) && (typeof val2 === "object")
      if ((areObjects && !this.deepEqual(val1, val2)) || (!areObjects && (val1 !== val2))) {
        return false
      }
    }
    return true
  }

  enableAdminMode(): void {
    this.adminModeBehaviorSubject.next(true)
  }

  disableAdminMode(): void {
    this.adminModeBehaviorSubject.next(false)
  }

  toggleAdminMode(): void {
    this.adminModeBehaviorSubject.next(!this.adminMode)
  }

  togglePreviewMode(): void {
    this.previewModeBehaviorSubject.next(!this.previewMode)
  }

  enablePreviewMode(): void {
    this.previewModeBehaviorSubject.next(true)
  }

  disablePreviewMode(): void {
    this.previewModeBehaviorSubject.next(false)
  }

}
/**
 * Start with this.block, then narrow down to the value of the "path" part of the block.
 */
/*
let blockValue = block
path.forEach(pathPart => blockValue = blockValue?.[pathPart])
*/
