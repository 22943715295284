import { Injectable } from "@angular/core"
import { FormOptionsBase, BlockBase } from "../../services/block.service"
import { BlockStateBase } from "@aaa-web/app/modules/multiblock/services/state.service"
import { FormArray, FormControl, FormGroup } from "@angular/forms"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { PublishedOption } from "@aaa-web/app/modules/multiblock/blocks/settings.service"
import {
  Javascript, JavascriptOptions, JavascriptStyles
} from "@aaa-web/app/modules/multiblock/elements/javascript/javascript.service"

export interface Block extends BlockBase {
  fields: {
    javascripts: Javascript[]
  }
}

export interface FormOptions extends FormOptionsBase {
  fields?: {
    javascripts: JavascriptOptions
  }
}

export interface BlockState extends BlockStateBase {
  formOptions?: FormOptions
}

export interface FormDisplayOptions {
  fields?: {
    javascripts?: boolean | "form"
  }
}

@Injectable({
  providedIn: "root"
})
export class LogiformService {
  window: MetaWindow

  constructor(
    public domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  get javascriptOptions(): JavascriptOptions {
    const options: JavascriptOptions = {
      newJavascriptForm: this.newJavascriptForm(this.newJavascript),
      javascriptOptions: [],
      combinedForm: true,
      formArrayPath: ["javascripts"],
      formGroupPath: ["fields"],
      label: {
        plural: "Javascripts",
        singular: "Javascript"
      },
      placeholder: "javascript code",
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.javascriptOptions.push({
        background: {
          color: false,
          opacity: false
        },
        position: {
          x: false,
          y: false
        },
        width: {
          px: false
        },
        value: true
      })
    }
    return options
  }

  get formDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        javascripts: "form"
      },
    }
  }

  get javascriptsFormDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        javascripts: "form"
      },
    }
  }

  javascriptStyles(block: Block, width: number): JavascriptStyles {
    return {
      backgroundColor: "rgba(255, 255, 255, 1)"
    }
  }

  get publishedOptions(): PublishedOption[] {
    return ["published", "unpublished", "password"]
  }

  get newJavascript(): Javascript {
    return {
      value: ""
    }
  }

  get newBlock(): Block {
    return {
      blockType: "logiform",
      id: "logiform",
      fields: {
        javascripts: [this.newJavascript]
      },
      options: {
        alignment: "center",
        marginBottom: "none",
        marginTop: "none",
        paddingBottom: "none",
        paddingTop: "none",
        theme: "white",
      },
      pathnames: [this.window.location.pathname],
      status: {
        created: Timestamp.now(),
        published: "published",
        revised: Timestamp.now()
      }
    }
  }

  newJavascriptForm(javascript: Javascript): FormGroup {
    return new FormGroup({
      value: new FormControl(javascript.value),
    })
  }

  newForm(block: Block): BlockState {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          javascripts: new FormArray(block.fields.javascripts.map(javascript => this.newJavascriptForm(javascript)))
        }),
        pathnames: new FormControl(block.pathnames),
        status: new FormGroup({
          created: new FormControl(block.status.created),
          published: new FormControl(block.status.published),
          revised: new FormControl(block.status.revised)
        })
      })
    }
  }

  get newFormOptions(): FormOptions {
    return {
      fields: {
        javascripts: this.javascriptOptions
      },
      status: {
        published: this.publishedOptions
      }
    }
  }

}
