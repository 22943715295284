import { Component, Input, OnInit } from "@angular/core"
import { StateMachineService } from "../../../services/state-machine.service"
import { FormService } from "../../../services/form.service"
import { FormArray } from "@angular/forms"
import { environment } from "@aaa-web/environments/environment"
import { FormValues } from "@aaa/interface/agent-scheduler"

@Component({
  selector: "aaa-region",
  templateUrl: "./region.component.html"
})
export class RegionComponent implements OnInit {
  @Input() formId: string
  formValues: FormValues

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {
  }

  ngOnInit(): void {
    this.formValues = this.formService.formValues[this.formId]
    this.formValues.selectedRegionId = ""
    /**
     * If selectedRegion is not populated then select the first region with region.default:true
     */
    if (!this.formValues.selectedRegionId) {
      this.formValues.selectedRegionId = this.formValues.regions.find(region => !!region.default)?.id
    }

    if (environment.ngServe) {
      // this.sms.sendEvent('NEXT')
    }
  }

  get regions(): FormArray {
    return this.formService.form[this.formId].get("regions") as FormArray
  }

}
