import { Injectable } from "@angular/core"
import { FormOptionsBase, BlockBase, BlockOption } from "@aaa-web/app/modules/multiblock/services/block.service"
import { BlockStateBase } from "@aaa-web/app/modules/multiblock/services/state.service"
import { FormArray, FormControl, FormGroup } from "@angular/forms"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import { MetaWindow } from "@aaa-web/app/core/interfaces/window.interface"
import { PublishedOption } from "@aaa-web/app/modules/multiblock/blocks/settings.service"
import { Text, TextOptions, TextService } from "@aaa-web/app/modules/multiblock/elements/text/text.service"
import {
  BackgroundImage, BackgroundImageOptions
} from "@aaa-web/app/modules/multiblock/elements/background-image/background-image.service"
import {
  Membership, MembershipOptions, MembershipService
} from "@aaa-web/app/modules/multiblock/blocks/join/membership/membership.service"

export interface Block extends BlockBase {
  fields: {
    images: BackgroundImage[]
    desktopTitles: Text[]
    mobileTitles: Text[]
    subtitles: Text[]
    quantityLabels: Text[]
    memberships: Membership[]
  }
}

export interface FormOptions extends FormOptionsBase {
  fields?: {
    images: BackgroundImageOptions
    desktopTitles: TextOptions
    mobileTitles: TextOptions
    subtitles: TextOptions
    quantityLabels: TextOptions
    memberships: MembershipOptions
  }
}

export interface BlockState extends BlockStateBase {
  formOptions?: FormOptions
}

export interface FormDisplayOptions {
  fields?: {
    images?: boolean | "form"
    desktopTitles?: boolean | "form"
    mobileTitles?: boolean | "form"
    subtitles?: boolean | "form"
    quantityLabels?: boolean | "form"
    memberships?: boolean | "form"
  }

}

@Injectable({
  providedIn: "root"
})
export class JoinService {
  window: MetaWindow
  newBlockId: BlockOption = "join"

  constructor(
    private membershipService: MembershipService,
    private textService: TextService,
    private domWindow: Window,
  ) {
    this.window = domWindow as unknown as MetaWindow
  }

  get imageOptions(): BackgroundImageOptions {
    const options: BackgroundImageOptions = {
      options: [],
      combinedForm: true,
      label: {
        plural: "Background Images",
        singular: "Background Image"
      },
      placeholder: "",
      formArray: {
        min: 1,
        max: 1
      }
    }
    options.options = [
      {
        description: "Desktop 1440x600",
        directory: "images/1440x600",
        minWidth: {
          px: 1440
        },
        maxWidth: {
          px: 1440
        },
        minHeight: {
          px: 600
        },
        maxHeight: {
          px: 600
        },
      }
    ]
    return options
  }

  get desktopTitleOptions(): TextOptions {
    const options: TextOptions = {
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newDesktopTitle,
      options: [],
      combinedForm: true,
      label: {
        plural: "Desktop Titles",
        singular: "Desktop Title"
      },
      placeholder: "title",
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: ["h1"],
        value: true
      })
    }
    return options
  }

  get mobileTitleOptions(): TextOptions {
    const options: TextOptions = {
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newMobileTitle,
      options: [],
      combinedForm: true,
      label: {
        plural: "Mobile Titles",
        singular: "Mobile Title"
      },
      placeholder: "title",
      formArray: {
        min: 1,
        max: 1
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: ["h1"],
        value: true
      })
    }
    return options
  }

  get subtitleOptions(): TextOptions {
    const options: TextOptions = {
      blockOptions: {
        alignment: true,
        theme: true
      },
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newText,
      options: [],
      combinedForm: true,
      label: {
        plural: "Subtitles",
        singular: "Subtitle"
      },
      placeholder: "subtitle",
      formArray: {
        min: 0,
        max: 5
      }
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get quantityLabelOptions(): TextOptions {
    const options: TextOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        plural: "Member Quantity Selectors",
        singular: "Member Quantity Selector"
      },
      newFormFunction: this.newTextForm,
      newFormItemFunction: this.newText,
      placeholder: "text"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get membershipOptions(): MembershipOptions {
    const options: MembershipOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 3
      },
      options: [],
      label: {
        plural: "Membership Levels",
        singular: "Membership Level"
      },
      newForm: this.membershipService.newForm(this.membershipService.newField),
      placeholder: ""
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        flags: this.membershipFlagOptions,
        subtitles: this.membershipSubtitleOptions,
        titles: this.membershipTitleOptions
      })
    }
    return options
  }

  get membershipFlagOptions(): TextOptions {
    const options: TextOptions = {
      combinedForm: true,
      formArray: {
        min: 0,
        max: 1
      },
      options: [],
      label: {
        plural: "Flags",
        singular: "Flag"
      },
      newFormFunction: this.membershipService.newTextForm,
      newFormItemFunction: this.membershipService.newText,
      placeholder: "text"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get membershipSubtitleOptions(): TextOptions {
    const options: TextOptions = {
      combinedForm: true,
      formArray: {
        min: 1,
        max: 1
      },
      options: [],
      label: {
        plural: "Subtitles",
        singular: "Subtitle"
      },
      newFormFunction: this.membershipService.newTextForm,
      newFormItemFunction: this.membershipService.newText,
      placeholder: "text"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get membershipTitleOptions(): TextOptions {
    const options: TextOptions = {
      combinedForm: true,
      formArray: {
        min: 1,
        max: 1
      },
      options: [],
      label: {
        plural: "Titles",
        singular: "Title"
      },
      newFormFunction: this.membershipService.newTextForm,
      newFormItemFunction: this.membershipService.newText,
      placeholder: "text"
    }
    for (let i = 0; i < options.formArray.max; i++) {
      options.options.push({
        element: [],
        value: true
      })
    }
    return options
  }

  get formDisplayOptions(): FormDisplayOptions {
    return {
      fields: {
        images: "form",
        desktopTitles: "form",
        mobileTitles: "form",
        subtitles: "form",
        quantityLabels: "form",
        memberships: "form",
      },
    }
  }

  get publishedOptions(): PublishedOption[] {
    return ["published", "unpublished"]
  }

  newDesktopTitle = (): Text => {
    return {
      element: "h1",
      value: "",
    }
  }

  newMobileTitle = (): Text => {
    return {
      element: "h1",
      value: "",
    }
  }

  newText = (): Text => {
    return this.textService.newText
  }

  get newBlock(): Block {
    return {
      blockType: this.newBlockId,
      id: this.newBlockId,
      fields: {
        images: [{ src: "" }],
        desktopTitles: [this.newDesktopTitle()],
        mobileTitles: [this.newMobileTitle()],
        subtitles: [],
        quantityLabels: [],
        memberships: [],
      },
      options: {
        alignment: "left",
        marginBottom: "none",
        marginTop: "none",
        paddingBottom: "thin",
        paddingTop: "thin",
        theme: "black",
      },
      pathnames: [this.window.location.pathname],
      status: {
        created: Timestamp.now(),
        published: "published",
        revised: Timestamp.now()
      }
    }
  }

  newTextForm = (item: Text): FormGroup => {
    return this.textService.newForm(item)
  }

  newImageForm(item: BackgroundImage): FormGroup {
    return new FormGroup({
      src: new FormControl(item.src),
      // path: new FormControl(image.path),
      // target: new FormControl(image.target),
      // value: new FormControl(image.value),
    })
  }

  newForm(block: Block): BlockState {
    return {
      form: new FormGroup({
        blockType: new FormControl(block.blockType),
        fields: new FormGroup({
          images: new FormArray(block.fields.images.map(item => this.newImageForm(item))),
          desktopTitles: new FormArray(block.fields.desktopTitles.map(item => this.newTextForm(item))),
          mobileTitles: new FormArray(block.fields.mobileTitles.map(item => this.newTextForm(item))),
          subtitles: new FormArray(block.fields.subtitles.map(item => this.newTextForm(item))),
          quantityLabels: new FormArray(block.fields.quantityLabels.map(item => this.newTextForm(item))),
          memberships: new FormArray(block.fields.memberships.map(item => this.membershipService.newForm(item))),
        }),
        pathnames: new FormControl(block.pathnames),
        status: new FormGroup({
          created: new FormControl(block.status.created),
          published: new FormControl(block.status.published),
          revised: new FormControl(block.status.revised)
        })
      })
    }
  }

  get newFormOptions(): FormOptions {
    return {
      fields: {
        images: this.imageOptions,
        desktopTitles: this.desktopTitleOptions,
        mobileTitles: this.mobileTitleOptions,
        subtitles: this.subtitleOptions,
        quantityLabels: this.quantityLabelOptions,
        memberships: this.membershipOptions,
      },
      status: {
        published: this.publishedOptions,
      }
    }
  }

}
