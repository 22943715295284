import { Injectable } from "@angular/core"
import { PublishedType } from "@aaa-web/app/modules/multiblock/services/style.service"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp

export type SettingsMode = "createModal" | "sideDrawer"

export type PublishedOption = "published" | "unpublished" | "password"
export type PublishedTypes = Record<PublishedOption, PublishedType>

export type StatusOption = "created" | "published" | "revised"
export type StatusTypes = {
  created: Timestamp
  published: PublishedType
  revised: Timestamp
}

export interface VisibilityType {
  label: string
  value: boolean
}

export type VisibilityOption = "visible" | "hidden"
export type VisibilityTypes = Record<VisibilityOption, VisibilityType>


@Injectable({
  providedIn: "root"
})
export class SettingsService {

  constructor(
  ) {
  }

  get publishedTypes(): PublishedTypes {
    return {
      password: {
        label: "Protected",
        value: true
      },
      published: {
        label: "Published",
        value: true
      },
      unpublished: {
        label: "Unpublished",
        value: false
      }
    }
  }

  get visibilityTypes(): VisibilityTypes {
    return {
      visible: {
        label: "Visible",
        value: true
      },
      hidden: {
        label: "Hidden",
        value: false
      }
    }
  }

}
