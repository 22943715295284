import { Injectable } from "@angular/core"
import { JwtAuthService } from "@aaa-web/app/core/services/jwt-auth.service"
import { Roles } from "@aaa/interface/user"
import { BehaviorSubject, Observable } from "rxjs"

@Injectable({
  providedIn: "root"
})
export class RoleService {
  private rolesSubject: BehaviorSubject<Roles>
  roles$: Observable<Roles>
  roles: Roles = {}

  /**
   * Todo: map groups of roles to new logical role names, this way all the roles and permissions mapping are done here.
   *
   * examples:
   * - map all the admin roles to an admin_menu boolean so that our global toolbar only needs to observe this admin_menu boolean.
   * - map administrator, Site Admin, Webmaster, and Magazine Admin to an admin_magazine boolean for the magazine edit widget.
   */

  /**
   * Role id mappings, the Role Label can be different between clubs.
   * https://www.colorado.aaa.com/admin/people/permissions/roles.
   *
   * We decided not to use role ids because the role name to role id mapping varies between clubs.
   *

   roleId,Role Label

   1,anonymous user
   2,authenticated user
   3,Travel Admin
   4,Content Editor
   5,Webmaster
   6,administrator
   7,Discounts Admin
   8,Guest
   9,AAA Classic
   10,AAA Plus
   11,AAA Plus Motorcycle
   12,AAA Plus RV
   13,AAA Premier
   14,Automotive Admin
   15,Car Buying Admin
   16,Office Admin
   17,News Admin
   18,User Admin
   19,Agent Admin
   20,Content Admin
   21,Site Admin
   22,Event Admin
   23,Store Admin
   24,Magazine Admin
   25,AAA Premier RV
   26,Content Creator
   27,View Only
   28,AAA Cancelled
   */

  constructor(
    private jwtAuthService: JwtAuthService,
  ) {
    this.rolesSubject = new BehaviorSubject({})
    this.roles$ = this.rolesSubject.asObservable()
    this.jwtAuthService.roles$
      .subscribe(rolesArray => {
        const roles = {}
        if (rolesArray) {
          rolesArray.forEach(role => {
            role = role.replace(new RegExp(" ", "g"), "-").toLowerCase()
            roles[role] = true
          })
        }
        this.roles = roles
        this.rolesSubject.next(roles)
      })
  }
}
